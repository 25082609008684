LS.form = {
	/**
	 * Resize input to fit content
	 *
	 * @param {$} el
	 * @param {String} [namespace]
	 * @param {Function} [update]
	 */
	resize: function(el, namespace, update) {
		$(el).each(function(el) {
			var $el = $(el),
				current,
				previous,
				updated;

			$el.on('input', function(e, el) {
				current = el.scrollHeight;

				el.style.height = updated = 'auto';

				if (el.value) {
					updated = Math.max(current, Math.floor(current + 2));
				}

				el.style.height = updated + 'px';

				if (updated !== previous) {
					previous = updated;

					$.exec(update);
				}
			}, {
				init: true,
				namespace: namespace
			});

			requestAnimationFrame(function() {
				$el.trigger('focus');
			});
		});
	},

	/**
	 * Validate form based on inline and custom rules
	 *
	 * @param {$} el
	 * @param {Object} [data]
	 * @param {(Boolean|Object)} [rules]
	 * @param {Object} [options]
	 * @returns {Boolean}
	 */
	validate: function(el, data, rules, options) {
		var $form = $(el),
			conf = $.extend({
				labels: []
			}, options),
			context = conf.context || $form,
			$inputs = $(':invalid:not(fieldset)', context),
			attributes = [
				'data-case',
				'max',
				'maxlength',
				'min',
				'minlength',
				'pattern',
				'required'
			];

		data = data || $form.serialize(true, false);

		rules = $.isObject(rules) ?
			rules : {};

		$inputs.add($('input[type="email"], input[type="tel"], input[type="url"], input[required], textarea[required], [data-case]', context))
			.filter(function(index, el) {
				return ! el.disabled &&
					! $.closest(el, '.-is-disabled', $form).length;
			})
			.each(function(el) {
				var label = el.dataset.label,
					name = el.name || el.id,
					type = el.getAttribute('type'),
					values = [];

				if (! name) {
					return;
				}

				attributes.forEach(function(attribute) {
					var attr = el.getAttribute(attribute);

					if (attr !== null) {
						values.push(attribute + (
							attr ? ':' + attr : ''
						));
					}
				});

				if (
					type === 'email' ||
					type === 'numeric' ||
					type === 'tel' ||
					type === 'url'
				) {
					values.push(type);
				}

				if (values.length) {
					rules[name] = rules[name] ?
						rules[name].concat(values) : values;
				}

				if (! label) {
					label = el.getAttribute('aria-label');

					if (label) {
						label = label.toLowerCase();
					} else {
						var assoc = $('label[for="' + el.id + '"]')[0];

						if (assoc) {
							label = assoc.firstChild.nodeValue;
						}
					}

					if (label) {
						label = label.charAt(0).toLowerCase() + label.slice(1);
					}
				}

				if (label) {
					conf.labels[name] = label;
				}
			});

		$('input[type="checkbox"]', $form)
			.filter(function(index, el) {
				return ! el.checked;
			})
			.each(function(el) {
				if (el.name && el.name.slice(-2) !== '[]') {
					data[el.name] = '0';
				}
			});

		var validator = LS.validate.run(data, rules, conf);

		if (validator === true) {
			return true;
		}

		LS.validate.highlight($('[name="' + validator[0] + '"]', $form), true);

		return false;
	}
};

$.chain('resize', function(namespace, update) {
	LS.form.resize(this, namespace, update);

	return this;
});