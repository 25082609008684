(function(L, U) {
	'use strict';

	var filters = {
			num: function(seg) {
				return /^\d+$/.test(seg);
			}
		},
		any = [],
		settings = {},
		routes,
		segs,
		uri,

		/**
		 * Recursively process routes
		 *
		 * @private
		 * @param {Object} route - route to evaluate
		 * @param {Number} i - current index in iteration
		 * @param {Number} total - total number of routes
		 * @param {String} [event='load'] - lifecycle event
		 * @param {Array} [parent] - parent route values
		 */
		_process = function(route, i, total, event, parent) {
			var seg = segs[i],
				keys = Object.keys(route),
				count = keys.length,
				x = 0;
			i++;

			// Match against patterns
			for (; x < count; x++) {
				var key = keys[x],
					child = route[key],
					opts = key.split('||'),
					k = 0,
					y = i,
					match = false,
					ran = false,
					object = L.$isObject(child);

				for (; k < opts.length; k++) {
					var opt = opts[k],
						parts = opt.split(':'),
						history = event !== U,
						negate = false,
						push = false,
						eq = false;

					// Ensure event type matches route type
					if ((! history && (
						parts.indexOf('unload') > -1 ||
						parts.indexOf('pop') > -1
					)) ||
					(history && (
						! object &&
						parts.indexOf(event) < 0
					))) {
						continue;
					}

					// Set option to rule root
					if (parts.length > 1) {
						opt = parts[0];

						if (! opt && parent) {
							opt = parent[0];
							seg = parent[1];
							y--;
						}
					}

					// Negate routes prefixed by !
					if (opt[0] === '!') {
						opt = opt.slice(1);
						negate = true;
					}

					// Move the segment pointer back one level
					if (parts.indexOf('eval') > -1) {
						y--;
					}

					if (opt === seg) {
						eq = true;
					} else if (opt[0] === '$') {
						opt = opt.slice(1);

						if (opt === 'any') {
							eq = true;

							if (parts.indexOf('fire') > -1) {
								ran = true;
							} else if (! object) {
								push = true;
							}
						} else if (opt === 'root') {
							if (! seg) {
								eq = true;
								ran = true;
							}
						} else if (opt[0] === '/') {
							var split = opt.split('/');

							if (new RegExp(split[1], split[2] || U).test(seg)) {
								eq = true;
							}
						} else {
							var filter = filters[opt];

							if (filter) {
								if (filter(seg, child, y) === true) {
									eq = true;
									push = true;
								}
							} else if (seg && seg.trim() !== '') {
								eq = true;
							}
						}

						opt = '$' + opt;
					}

					// Invert the equality if the route is negated
					if (negate) {
						eq = ! eq;
					}

					if (eq) {
						// If ran is true then execute the route immediately
						if (ran && ! object) {
							L.$exec(child, {
								args: seg
							});
						}

						// If push is true then push the route to "any" queue
						if (push) {
							ran = true;
							any.push([child, seg]);
						}

						// Remove the route if set to once
						if (parts.indexOf('once') > -1) {
							delete route[key];
						}

						// Set match to true and break on match
						match = true;

						break;
					}
				}

				// If matched then process recursively or execute if applicable
				if (match) {
					if (object) {
						_process(child, y, total, event, [opt, seg]);
					} else if (! ran && y === total) {
						L.$exec(child, {
							args: seg
						});
					}
				}
			}
		};

	L.routes = {
		/**
		 * Set routing options
		 *
		 * @param {(Object|String)} a - name or settings object
		 * @param {*} [b] - value
		 */
		config: function(a, b) {
			L._extend(settings, a, b);
		},

		/**
		 * Set current URI values
		 *
		 * @param {(Object|String)} [value]
		 * @param {String} [value.full]
		 * @param {String} [value.hash]
		 * @param {Boolean} [value.history]
		 * @param {String} [value.path]
		 * @param {Object} [value.query]
		 * @param {String} [value.raw]
		 * @param {Array} [value.segments]
		 * @param {String} [value.url]
		 * @returns {Object} data
		 */
		set: function(value) {
			if (L.$isObject(value)) {
				return L.$extend(this.uri(), value);
			}

			uri = this.parse(value);

			return uri;
		},

		/**
		 * Get current URI values
		 *
		 * @returns {Object} data
		 */
		uri: function() {
			if (uri) {
				var hash = L._loc.hash;

				uri.hash = hash.slice(1);
				uri.raw = uri.full + hash;

				return uri;
			}

			return this.parse();
		},

		/**
		 * Parse a URL string into parts
		 *
		 * @param {String} [uri]
		 * @returns {Object}
		 */
		parse: function(uri) {
			var a = L._doc.createElement('a');

			a.href = uri || L._loc.href;

			var search = a.search,
				path = a.pathname.replace(/^\/|\/$/g, ''),
				full = '/' + path + search;

			return {
				full: '/' + path + search,
				hostname: a.hostname,
				hash: a.hash.slice(1),
				history: false,
				path: '/' + path,
				query: search ?
					L.$unserialize(search) : {},
				raw: full + a.hash,
				segments: path.split('/'),
				url: a.href
			};
		},

		/**
		 * Get all segments or segment at index
		 *
		 * @param {Number} [index]
		 * @returns {(Array|String)} segments
		 */
		segments: function(index) {
			var segs = this.uri().segments;

			return index !== U ?
				(segs[index] || '') : segs;
		},

		/**
		 * Retrieve or add route endpoints
		 *
		 * @param {Object} obj - routes
		 * @param {Boolean} [init=false] - immediately evaluate routes
		 * @returns {Object} routes
		 */
		map: function(obj, init) {
			var curr = routes || {};

			if (obj) {
				routes = L.$extend(true, curr, obj);

				if (init) {
					this.run({
						routes: routes
					});
				}
			}

			return curr;
		},

		/**
		 * Add conditional route filter
		 *
		 * @param {(Object|String)} a - name or filter object
		 * @param {Function} [b]
		 */
		addFilter: function(a, b) {
			L._extend(filters, a, b);
		},

		/**
		 * Evaluate routes against URI
		 *
		 * @param {Object} [options]
		 * @param {String} [options.event='load']
		 * @param {String} [options.path]
		 * @param {Object} [options.routes]
		 */
		run: function(options) {
			var conf = options || {},
				rules = conf.routes || routes;

			if (settings.before) {
				var value = settings.before(conf);

				if (value === false) {
					return;
				}
			}

			if (rules) {
				segs = conf.path ?
					conf.path.replace(/^\/|\/$/g, '')
						.split(/[#?]/)[0]
						.split('/') :
					this.segments();

				_process(rules, 0, segs.length, conf.event);

				// Execute queued init functions on last iteration
				if (any.length) {
					for (var i = 0; i < any.length; i++) {
						var rule = any[i];

						L.$exec(rule[0], {
							args: rule[1]
						});
					}

					// Clear array for next iteration
					any = [];
				}
			}
		}
	};
})(Apt, undefined);