Apt.fn.extend('index', {
	/**
	 * Submit controls
	 */
	submit: function() {
		$(this.conf.controls).trigger('submit');
	}
}, {
	/**
	 * Initialize application controls
	 *
	 * @param {Object} conf
	 */
	initControls: function(conf) {
		if (! conf.controls) {
			return;
		}

		var pub = this.$public,
			$form = $(conf.controls);

		if (conf.validate) {
			$form[0].noValidate = true;
		}

		$form.on('submit', function() {
			if (conf.validate && ! LS.form.validate($form)) {
				return;
			}

			var filters = $form.serialize(true);

			filters.page = 1;

			pub.filter(filters);
		}, {
			cancel: true,
			namespace: pub.uid
		});
	}
});