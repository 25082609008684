Apt.fn.make('properties', {
	/**
	 * Initialize module
	 */
	init: function() {
		var scope = this,
			priv = scope.$private;

		priv.section = 'properties';
		priv.customMap = null;

		if (priv.index) {
			if (Apt.panel.opened()) {
				Apt.panel.hide({
					init: true
				});

				return;
			}

			priv.refresh();

			return;
		}

		priv.filters = Apt.fn.filters()
			.init();

		priv.initApp();

		priv.initMobile(function() {
			priv.loadMap();
		});

		priv.meta = Apt.fn.meta()
			.init();

		scope.prefetch();
	},

	/**
	 * Prefetch property assets
	 */
	prefetch: function() {
		var path = '/assets/modules/property@' + $.get('versions.property') + '/';

		LS.util.prefetch([
			path + 'style.min.css',
			path + 'script.min.js'
		]);
	},

	/**
	 * Evaluate preload logic
	 */
	preload: function() {
		var pending = $.history.pending();

		if (! pending || ! pending.action) {
			return;
		}

		var section = this.$private.section || 'properties';

		if (! Apt[section].$private.index) {
			pending.action = true;

			return;
		}

		if (! pending.run) {
			return;
		}

		var segments = pending.parsed.segments;

		if (! LS.filters.isProperty(segments[0])) {
			return;
		}

		if (LS.filters.isEntry(segments[2] || null)) {
			if (! Apt.panel.opened()) {
				pending.abort();

				Apt.panel.trigger(null, pending.path);
			}

			return;
		}

		if (segments[0] !== section) {
			var prev = $.history.get(0);

			if (prev && (
				prev.segments[1] === 'saves' ||
				segments[1] === 'saves'
			)) {
				return;
			}
		}

		pending.action = false;
	},

	/**
	 * Unload module
	 *
	 * @param {Boolean} [force=false]
	 */
	unload: function(force) {
		var scope = this,
			priv = scope.$private;

		if (! priv.section) {
			return;
		}

		if (priv.section !== 'properties') {
			Apt[priv.section].unload();

			return;
		}

		if (force !== true) {
			if (Apt.panel.opened()) {
				return;
			}

			var history = $.history.get(true);

			if (history && (LS.filters.isProperty(history.segments[0]))) {
				return;
			}
		}

		priv.section = null;

		LS.util.reset('properties', priv, [
			'fallback',
			'filters',
			'index',
			'meta',
			'sync'
		], true);

		if (priv.map) {
			priv.map.minimize();

			if (! Apt.panel.opened()) {
				LS.util.destroy(priv, 'map');
			}
		}

		scope.showList();
	}
}, {
	/**
	 * Get ad targeting
	 *
	 * @returns {Object}
	 */
	getTarget: function() {
		var filters = LS.filters.get(),
			loc = $.context('location'),
			target = {
				section: 'properties'
			},
			tag = [],
			value;

		if (filters.format) {
			target.format = filters.format;
		}

		[
			'tag',
			'type'
		].forEach(function(key) {
			value = filters[key];

			if (value) {
				tag = tag.concat(value);
			}
		});

		if (tag.length) {
			target.tag = tag;
		}

		[
			'price',
			'size'
		].forEach(function(key) {
			value = filters[key];

			if (value) {
				value = value.max || value.min;

				if (value) {
					value = LS.util.closest(value, key === 'price' ?
						[0, 50000, 100000, 250000, 500000, 1000000, 2500000, 5000000, 10000000] :
						[0, 5, 10, 20, 50, 100, 250, 500, 1000]
					);

					if (value) {
						target[key] = value;
					}
				}
			}
		});

		if (loc) {
			target[loc.type] = loc.slug;

			if (loc.ancestors) {
				loc.ancestors.forEach(function(ancestor) {
					target[ancestor.type] = ancestor.slug;
				});
			}
		}

		return target;
	}
});