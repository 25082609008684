(function(L, U) {
	'use strict';

	/**
	 * Get the selected options from a select
	 *
	 * @private
	 * @param {HTMLElement} select
	 * @returns {Array} selected
	 */
	var _getSelected = function(select) {
			var arr = [];

			[].slice.call(select.options).map(function(el) {
				if (el.selected) {
					arr.push(el.value);
				}
			});

			return arr;
		},

		/**
		 * Get class value of element
		 *
		 * @private
		 * @param {HTMLElement} el
		 * @returns {String}
		 */
		_getClass = function(el) {
			return el instanceof SVGElement ?
				el.getAttribute('class') :
				el.className;
		},

		/**
		 * Set class value of element
		 *
		 * @private
		 * @param {HTMLElement} el
		 * @param {String} className
		 */
		_setClass = function(el, className) {
			el instanceof SVGElement ?
				el.setAttribute('class', className) :
				el.className = className;
		},

		/**
		 * Return either direct previous or next sibling
		 *
		 * @private
		 * @param {($|HTMLElement|String)} target
		 * @param {Number} dir
		 * @param {String} [filter]
		 * @param {Object} [options]
		 * @returns {HTMLElement}
		 */
		_getSibling = function(target, dir, filter, options) {
			var match;

			L.$each(target, function(el) {
				var index = L.$index(el) + dir;

				L.$children(L.$parent(el)).forEach(function(el, i) {
					if (i === index &&
						(! filter || filter && L.$is(el, filter, options))) {
						match = el;
					}
				});
			});

			return match;
		},

		/**
		 * Convert dash-separated string to camel-case
		 *
		 * @private
		 * @param {String} name
		 * @returns {String}
		 */
		_toCamel = function(name) {
			return name.toLowerCase()
				.replace(/-(.)/g, function(match, val) {
					return val.toUpperCase();
				});
		},

		/**
		 * Convert camel-cased string to dash-separated
		 *
		 * @private
		 * @param {String} name
		 * @returns {String}
		 */
		_toDashed = function(name) {
			return name.replace(/[A-Z]/g, function(match) {
				return '-' + match[0].toLowerCase();
			});
		};

	L.fn.extend({
		/**
		 * Add classes to each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {(Array|String)} value
		 */
		$addClass: function(target, value) {
			L.$each(target, function(el) {
				var cn = _getClass(el),
					names = cn.split(' '),
					upd = (Array.isArray(value) ? value : value.split(' ')).filter(function(val) {
						return names.indexOf(val) < 0;
					});

				upd.unshift(cn);

				_setClass(el, upd.join(' '));
			});
		},

		/**
		 * Insert selection or markup after each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {($|HTMLElement|String)} source
		 * @param {Boolean} [remove=false]
		 */
		$after: function(target, source, remove) {
			L.$each(target, function(el, i) {
				if (typeof source === 'string') {
					source = L.$parseHTML(source);
				}

				L.$each(source, function(cel) {
					if (i > 0) {
						cel = L.$clone(cel)[0];
					}

					el.parentNode.insertBefore(cel, el.nextSibling);
				}, {
					reverse: true
				});

				if (remove) {
					L.$remove(el);
				}
			});
		},

		/**
		 * Append selection or markup after each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {($|HTMLElement|String)} source
		 */
		$append: function(target, source) {
			L.$each(target, function(el) {
				if (typeof source === 'string') {
					source = L.$parseHTML(source);
				}

				L.$each(source, function(cel) {
					el.appendChild(cel);
				});
			});
		},

		/**
		 * Get attribute of first matching selection or set attribute
		 * of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param a
		 * @param b
		 * @returns {(String|Undefined)}
		 */
		$attr: function(target, a, b) {
			var obj = L.$isObject(a);

			if (b !== U || obj) {
				L.$each(target, function(el) {
					obj ?
						L._extend(el, a) :
						el.setAttribute(a, b);
				});
			} else {
				return L.$(target)[0].getAttribute(a);
			}
		},

		/**
		 * Insert selection or markup before each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {($|HTMLElement|String)} source
		 * @param {Boolean} [remove=false]
		 */
		$before: function(target, source, remove) {
			L.$each(target, function(el, i) {
				if (typeof source === 'string') {
					source = L.$parseHTML(source);
				}

				L.$each(source, function(cel) {
					if (i > 0) {
						cel = L.$clone(cel)[0];
					}

					el.parentNode.insertBefore(cel, el);
				}, {
					reverse: true
				});

				if (remove) {
					L.$remove(el);
				}
			});
		},

		/**
		 * Get unique direct children of each matching selection
		 *
		 * @param {($|HTMLElement|String)} parent
		 * @param {String} [filter]
		 * @returns {Array}
		 */
		$children: function(parent, filter) {
			var arr = [];

			L.$each(parent, function(el) {
				var children = [].slice.call(el.children);

				arr = arr.concat(
					filter ?
						L.$filter(children, filter) :
						children
				);
			});

			return L.$unique(arr);
		},

		/**
		 * Clone each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @returns {Array}
		 */
		$clone: function(target) {
			return L.$map(target, function(el) {
				return el.cloneNode(true);
			});
		},

		/**
		 * Get unique closest ancestors of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {String} filter
		 * @param {($|HTMLElement|String)} [context]
		 * @returns {HTMLElement}
		 */
		$closest: function(target, filter, context) {
			return L.$unique(L.$map(target, function(el) {
				if (L.$is(el, filter)) {
					return el;
				}

				while (el !== null) {
					el = el.parentNode;

					if (el === L._html) {
						return false;
					}

					if (L.$is(el, filter)) {
						return el;
					}
				}
			}, {
				context: context
			}));
		},

		/**
		 * Determine if any matching parent contains descendant selection
		 *
		 * @param {($|HTMLElement|String)} parent
		 * @param {($|HTMLElement|String)} descendant
		 * @returns {Boolean}
		 */
		$contains: function(parent, descendant) {
			var b = false;

			L.$each(parent, function(el) {
				if (L.$(descendant, el).length) {
					b = true;

					return false;
				}
			});

			return b;
		},

		/**
		 * Get CSS value of first matching selection or set value
		 * of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {(Object|String)} a
		 * @param {String} [b]
		 * @returns {(String|Undefined)}
		 */
		$css: function(target, a, b) {
			var obj = L.$isObject(a);

			if (b !== U || obj) {
				L.$each(target, function(el) {
					obj ?
						L._extend(el.style, a) :
						el.style[a] = b;
				});
			} else {
				return getComputedStyle(L.$(target)[0], null)[a];
			}
		},

		/**
		 * Get data of first matching selection or set data
		 * of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param a
		 * @param [b]
		 * @returns {(Object|String|Undefined)}
		 */
		$data: function(target, a, b) {
			if (a === U) {
				var arr = {};

				[].slice.call(L.$(target)[0].attributes).forEach(function(attr) {
					if (attr.name.slice(0, 5) === 'data-') {
						arr[_toCamel(attr.name.slice(5))] =
							L._castString(attr.value);
					}
				});

				return arr;
			}

			if (L.$isObject(a)) {
				var obj = {};

				Object.keys(a).forEach(function(key) {
					obj['data-' + _toDashed(key)] = a[key];
				});

				a = obj;
			} else {
				a = 'data-' + _toDashed(a);
			}

			if (L.$isObject(b)) {
				b = JSON.stringify(b);
			}

			return L._castString(L.$attr(target, a, b));
		},

		/**
		 * Remove child nodes from each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 */
		$empty: function(target) {
			L.$each(target, function(el) {
				if ('replaceChildren' in el) {
					el.replaceChildren();
				} else {
					while (el.firstChild) {
						el.removeChild(el.firstChild);
					}
				}
			});
		},

		/**
		 * Get indexed node of matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {Number} index
		 * @param {($|HTMLElement|String)} [context=document]
		 * @returns {HTMLElement}
		 */
		$eq: function(target, index, context) {
			var el = L.$(target, context);

			return el[index < 0 ? el.length + index : index];
		},

		/**
		 * Return a filtered subset of elements from a matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {String} filter
		 * @param {Object} [options]
		 * @returns {Array} elements
		 */
		$filter: function(target, filter, options) {
			return L.$map(target, function(el) {
				return L.$is(el, filter, options) ?
					el : false;
			});
		},

		/**
		 * Get unique filtered descendants from each matching selection
		 *
		 * @param {($|HTMLElement|String)} parent
		 * @param {String} filter
		 * @returns {Array} elements
		 */
		$find: function(parent, filter) {
			var arr = [];

			L.$each(parent, function(el) {
				arr = arr.concat(L.$(filter, el));
			});

			return L.$unique(arr);
		},

		/**
		 * Get the first element of a matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {($|HTMLElement|String)} [context=document]
		 * @returns {HTMLElement}
		 */
		$first: function(target, context) {
			return L.$(target, context)[0];
		},

		/**
		 * Place focus on input element
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {Boolean} [end=false] - place cursor at the end of the content
		 */
		$focus: function(target, end) {
			var el = L.$(target)[0];

			el.focus();

			if (end) {
				try {
					var length = el.value.length * 2;

					el.setSelectionRange(length, length);
				} catch (e) {
					//
				}
			}
		},

		/**
		 * Remove focus from an element
		 *
		 * @param {($|HTMLElement|String)} target
		 */
		$blur: function(target) {
			L.$(target)[0].blur();
		},

		/**
		 * Determine if the matching selection has a class
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {String} className
		 * @returns {Boolean}
		 */
		$hasClass: function(target, className) {
			return L.$(target).some(function(el) {
				return new RegExp('(^| )' + className + '($| )', 'gim')
					.test(_getClass(el));
			});
		},

		/**
		 * Get or set the height of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {(Boolean|Number|String)} value
		 * @returns {Number}
		 */
		$height: function(target, value) {
			if (value === U || value === true) {
				var el = L.$(target)[0],
					height;

				if (el === L._win) {
					return el.innerHeight;
				}

				if (el === L._doc) {
					return el.documentElement.scrollHeight;
				}

				height = el.offsetHeight;

				if (value === true) {
					var style = getComputedStyle(el);

					height += parseFloat(style.marginTop) +
						parseFloat(style.marginBottom);
				}

				return height;
			}

			L.$each(target, function(el) {
				if (typeof value === 'number') {
					value += 'px';
				}

				L.$css(el, 'height', value);
			});
		},

		/**
		 * Hide each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 */
		$hide: function(target) {
			L.$prop(target, 'hidden', true);
		},

		/**
		 * Get inner HTML of first selection or set each matching selection HTML
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {String} value
		 * @returns {(String|Undefined)}
		 */
		$html: function(target, value) {
			if (value === U) {
				return L.$(target)[0].innerHTML;
			}

			L.$each(target, function(el) {
				if (value !== false && value !== U) {
					if (el.nodeName === 'SELECT' && ! L._win.atob) {
						el.outerHTML = el.outerHTML.replace(
							el.innerHTML + '</s', value + '</s'
						);
					} else {
						el.innerHTML = value;
					}
				}
			});
		},

		/**
		 * Get the zero-based index of a matching selection relative
		 * to it's siblings
		 *
		 * @param {($|HTMLElement|String)} target
		 * @returns {Number}
		 */
		$index: function(target) {
			var el = L.$(target)[0],
				i = 0,
				children;

			if (! el) {
				return -1;
			}

			children = [].slice.call(el.parentNode.children);

			for (; i < children.length; i++) {
				if (children[i] === el) {
					return i;
				}
			}
		},

		/**
		 * Insert each matching source selection element after
		 * each matching target selection
		 *
		 * @param {($|HTMLElement|String)} source
		 * @param {($|HTMLElement|String)} target
		 */
		$insertAfter: function(source, target) {
			L.$each(target, function(el, i) {
				L.$each(source, function(cel) {
					if (i > 0) {
						cel = L.$clone(cel)[0];
					}

					el.parentNode.insertBefore(cel, el.nextSibling);
				});
			});
		},

		/**
		 * Insert each matching source selection element before
		 * each matching target selection
		 *
		 * @param {($|HTMLElement|String)} source
		 * @param {($|HTMLElement|String)} target
		 */
		$insertBefore: function(source, target) {
			L.$each(target, function(el) {
				L.$each(source, function(cel) {
					el.parentNode.insertBefore(cel, el);
				});
			});
		},

		/**
		 * Determine if at least one matching selection matches
		 * a specified criteria
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {(Array|Function|String)} filter
		 * @returns {Boolean}
		 */
		$is: function(target, filter) {
			return L.$map(target, function(el, i) {
				if (typeof filter === 'string') {
					filter = filter.replace(/\$/g, '.\\$');
				}

				if (L.$isObject(filter)) {
					for (var key in filter) {
						if (filter[key] === el) {
							return true;
						}
					}

					return false;
				}

				if (Array.isArray(filter)) {
					return filter.indexOf(el) > -1;
				}

				if (typeof filter === 'function') {
					return L.$exec(filter, {
						args: [i, el],
						scope: el
					});
				}

				return (el.matches || el.msMatchesSelector).call(el, filter);
			}).length > 0;
		},

		/**
		 * Get the last element of a matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {($|HTMLElement|String)} [context=document]
		 * @returns {HTMLElement}
		 */
		$last: function(target, context) {
			return L.$eq(target, -1, context);
		},

		/**
		 * Get the unique next sibling of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {String} filter
		 * @param {Object} [options]
		 * @returns {Array} elements
		 */
		$next: function(target, filter, options) {
			return L.$unique(L.$map(target, function(el) {
				return _getSibling(el, 1, filter, options);
			}));
		},

		/**
		 * Get the position of a matching selection relative to the document
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {Number} value
		 * @returns {{top: number, left: number}}
		 */
		$offset: function(target, value) {
			if (value) {
				L.$each(target, function(el) {
					if (typeof value.top === 'number') {
						value.top = value.top + 'px';
					}

					if (typeof value.left === 'number') {
						value.left = value.left + 'px';
					}

					L.$css(el, value);
				});
			} else {
				var rect = L.$(target)[0].getBoundingClientRect();

				return {
					top: rect.top + L._win.pageYOffset,
					left: rect.left + L._win.pageXOffset
				};
			}
		},

		/**
		 * Get unique parent from each matching selection
		 *
		 * @param {($|HTMLElement|String)} child
		 * @param {String} filter
		 * @returns {Array} elements
		 */
		$parent: function(child, filter) {
			return L.$unique(L.$map(child, function(el) {
				var parent = el.parentNode;

				return ! filter || L.$is(parent, filter) ?
					parent : false;
			}));
		},

		/**
		 * Prepend selection or markup before each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {($|HTMLElement|String)} source
		 */
		$prepend: function(target, source) {
			L.$each(target, function(el) {
				if (typeof source === 'string') {
					source = L.$parseHTML(source);
				}

				L.$each(source, function(cel) {
					el.insertBefore(cel, el.firstChild);
				});
			});
		},

		/**
		 * Get the unique previous sibling of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {String} filter
		 * @param {Object} [options]
		 * @returns {Array} elements
		 */
		$prev: function(target, filter, options) {
			return L.$unique(L.$map(target, function(el) {
				return _getSibling(el, -1, filter, options);
			}));
		},

		/**
		 * Get property of first matching selection or set property of
		 * each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param a
		 * @param b
		 * @returns {*}
		 */
		$prop: function(target, a, b) {
			var obj = L.$isObject(a);

			if (b !== U || obj) {
				L.$each(target, function(el) {
					obj ?
						L._extend(el, a) :
						el[a] = b;
				});
			} else {
				return L.$(target)[0][a];
			}
		},

		/**
		 * Remove each matching selection from the document
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {($|HTMLElement|String)} [context=document]
		 */
		$remove: function(target, context) {
			L.$each(target, function(el) {
				el.parentNode.removeChild(el);
			}, {
				context: context
			});
		},

		/**
		 * Remove specified attribute of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {String} name
		 */
		$removeAttr: function(target, name) {
			L.$each(target, function(el) {
				name.split(/\s+/).forEach(function(value) {
					el.removeAttribute(value);
				});
			});
		},

		/**
		 * Remove classes from each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {(Array|String)} value
		 */
		$removeClass: function(target, value) {
			L.$each(target, function(el) {
				value = Array.isArray(value) ?
					value : value.split(' ');

				_setClass(el, _getClass(el).split(' ')
					.filter(function(val) {
						return value.indexOf(val) < 0;
					})
					.join(' '));
			});
		},

		/**
		 * Replace each matching selection with selection or markup
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {($|HTMLElement|String)} source
		 */
		$replaceWith: function(target, source) {
			L.$after(target, source, true);
		},

		/**
		 * Get the applicable scroll element
		 *
		 * @returns {HTMLElement}
		 */
		$scrollElement: function() {
			var el = L._doc.scrollingElement;

			if (! el) {
				el = L._html.scrollHeight >= L._body.scrollHeight &&
					L._doc.compatMode.indexOf('CSS1') === 0 ?
					L._html : L._body;
			}

			return el;
		},

		/**
		 * Get or set the Y scroll position of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {Number} value
		 * @param {Boolean} [smooth=false]
		 * @returns {Number}
		 */
		$scrollTop: function(target, value, smooth) {
			if (value === U) {
				var el = target ?
					L.$(target)[0] : L._win;

				return el === L._win ?
					el.pageYOffset :
					el.scrollTop;
			}

			var native = 'scrollBehavior' in L._html.style;

			L.$each(target, function(el) {
				if (native) {
					el.scrollTo({
						top: value,
						behavior: smooth ?
							'smooth' : 'auto'
					});
				} else {
					el.scrollTop = value;
				}
			});
		},

		/**
		 * Serialize input values from first matching form selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {Boolean} json
		 * @param {Boolean} [nest=true]
		 * @returns {String}
		 */
		$serializeForm: function(target, json, nest) {
			var el = L.$(target)[0],
				obj = {},
				i = 0;

			if (! el || el.nodeName !== 'FORM') {
				return '';
			}

			for (; i < el.elements.length; i++) {
				var child = el.elements[i],
					name = child.name,
					type = child.type;

				if (! name || child.disabled || type === 'file' || type === 'reset') {
					continue;
				}

				var arr = name.slice(-2) === '[]',
					value = null;

				if (arr) {
					name = name.slice(0, -2);
				}

				if (type === 'select-multiple') {
					value = _getSelected(child);
				} else {
					var toggle = type === 'checkbox' ||
						type === 'radio';

					if (
						type !== 'button' &&
						type !== 'submit' && (
							! toggle || (
								toggle &&
								child.checked
							)
						)
					) {
						if (arr || (type === 'checkbox' && obj[name])) {
							value = L.$toArray(obj[name]);

							value.push(child.value);
						} else {
							value = child.value;
						}
					}
				}

				if (value !== null) {
					obj[name] = value;
				}
			}

			if (! json) {
				return L.$serialize(obj);
			}

			if (nest !== false) {
				var nested = {},
					seg,
					sub;

				for (var key in obj) {
					seg = key.replace(/]/g, '').split('[');
					sub = nested;

					if (seg.length < 1) {
						continue;
					}

					for (i = 0; i < seg.length - 1; ++i) {
						var node = seg[i];

						sub = sub[node] === undefined ?
							(sub[node] = {}) : sub[node];
					}

					sub[seg[seg.length - 1]] = obj[key];
				}

				return nested;
			}

			return obj;
		},

		/**
		 * Show each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 */
		$show: function(target) {
			L.$prop(target, 'hidden', false);
		},

		/**
		 * Get unique siblings of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {String} [filter]
		 * @returns {Array} elements
		 */
		$siblings: function(target, filter) {
			var arr = [];

			L.$each(target, function(el) {
				var siblings = [].slice.call(el.parentNode.children)
					.filter(function(a) {
						return a !== el;
					});

				arr = arr.concat(
					filter ?
						L.$filter(siblings, filter) :
						siblings
				);
			});

			return L.$unique(arr);
		},

		/**
		 * Get inner text of first selection or set each matching selection text
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {String} value
		 * @returns {String}
		 */
		$text: function(target, value) {
			if (value === U) {
				return L.$map(target, function(el) {
					return el.textContent.trim();
				}).join('');
			}

			L.$each(target, function(el) {
				el.textContent = value;
			});
		},

		/**
		 * Toggle the display of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {Boolean} [state]
		 */
		$toggle: function(target, state) {
			L.$each(target, function(el) {
				state === false || (state === U && ! L.$prop(el, 'hidden')) ?
					L.$hide(el) :
					L.$show(el);
			});
		},

		/**
		 * Toggle adding and removing class(es) from the specified element
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {(Array|String)} value
		 * @param {Boolean} [state]
		 */
		$toggleClass: function(target, value, state) {
			L.$each(target, function(el) {
				(Array.isArray(value) ? value : value.split(' ')).forEach(function(value) {
					state === false || (state === U && L.$hasClass(el, value)) ?
						L.$removeClass(el, value) :
						L.$addClass(el, value);
				});
			});
		},

		/**
		 * Get value of first matching selection or set match values
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {String} value
		 * @returns {(Array|String)}
		 */
		$val: function(target, value) {
			if (value === U) {
				var el = L.$(target)[0];

				if (el.type === 'select-multiple') {
					return _getSelected(el);
				}

				return el.value;
			}

			L.$each(target, function(el) {
				if (el.type === 'select-multiple') {
					value = L.$toArray(value);

					[].slice.call(el.options).forEach(function(a) {
						if (value.indexOf(a.value) > -1) {
							a.selected = true;
						}
					});
				} else {
					el.value = value;
				}
			});
		},

		/**
		 * Get or set the width of each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @param {(Boolean|Number|String)} value
		 * @returns {Number}
		 */
		$width: function(target, value) {
			if (value === U || value === true) {
				var el = L.$(target)[0],
					width;

				if (el === L._win) {
					return el.innerWidth;
				}

				if (el === L._doc) {
					return el.documentElement.scrollWidth;
				}

				width = el.offsetWidth;

				if (value === true) {
					var style = getComputedStyle(el);

					width += parseFloat(style.marginLeft) +
						parseFloat(style.marginRight);
				}

				return width;
			}

			L.$each(target, function(el) {
				if (typeof value === 'number') {
					value += 'px';
				}

				L.$css(el, 'width', value);
			});
		}
	});
})(Apt, undefined);