LS.modal = {
	/**
	 * Open a new modal
	 *
	 * @param {Object} options
	 */
	open: function(options) {
		var scope = this;

		scope.visible = true;

		LS.util.crop();

		scope.options = $.extend({
			width: 420
		}, options);

		// Create modal structure if not available
		if (! scope.$modal) {
			scope.$modal = $('<div class="modal $modal" role="dialog" aria-modal="true"/>');
			scope.$inner = $('<div class="modal__inner"/>');
			scope.$mask = $('<div class="modal-mask"/>');

			scope.$modal
				.append(scope.$inner);

			$(document.body)
				.append(scope.$modal)
				.append(scope.$mask);
		} else if (scope.visible) {
			scope.$modal[0].classList.add('-is-loading');
		}

		if (options.modifier) {
			scope.$modal[0].classList.add(options.modifier);
		}

		scope.$mask.on('click', function() {
			if ($.screen.size() > 1) {
				scope.close();
			}
		}, {
			namespace: 'modal'
		});

		scope.$mask.addClass('-is-loading -is-visible');

		if (options.model !== null) {
			scope.load(options);
		}

		$(document).on('keydown', function(e) {
			if (e.keyCode === 27) {
				scope.close();
			}
		}, {
			namespace: 'modal'
		});
	},

	/**
	 * Load new content into an existing modal
	 *
	 * @param {Object} options
	 */
	load: function(options) {
		var scope = this,
			width = options.width,
			height = options.height;

		if (options.url) {
			$.fetch.request({
				url: options.url,
				post: options.post || false,
				headers: {
					'X-Requested-With': 'XMLHttpRequest'
				},
				success: function(data) {
					scope.show(data, width, height);

					if (options.success) {
						options.success();
					}
				},
				error: function() {
					LS.util.warn('There was an error with the request');

					scope.hide(true);
				}
			});
		} else {
			if (options.model) {
				options.content = $.view.render('modal.view', options.model);
			}

			scope.show(options.content, width, height);

			if (options.success) {
				options.success();
			}
		}
	},

	/**
	 * Display a modal on the page
	 *
	 * @param {*} content
	 * @param {Number} width
	 * @param {Number} height
	 */
	show: function(content, width, height) {
		var scope = LS.modal; // eslint-disable-line

		scope.visible = true;

		scope.$mask.removeClass('-is-loading')
			.addClass('-is-visible');

		scope.$inner.empty()
			.height(height || '');

		scope.$inner.append(content);

		scope.$close = $$('modalClose');

		scope.$close.on('click', function() {
			scope.close();
		}, {
			namespace: 'modal'
		});

		$.exec(scope.options.onShow);

		scope.$modal.addClass('-is-visible')
			.removeClass('-is-loading');

		$(window).on('resize', function() {
			scope.resize();
		}, {
			init: true,
			namespace: 'modal'
		});

		requestAnimationFrame(function() {
			scope.$modal[0].classList.add('-is-loaded');
		});

		var input = $('input[autofocus]', scope.$inner);

		scope.$inner[0].scrollTop = 0;

		if (input.length) {
			input.first()
				.focus(true);
		}
	},

	/**
	 * Resize modal container based on content height
	 */
	resize: function() {
		var scope = this;

		if (! scope.visible) {
			return;
		}

		var mobile = $.screen.size() < 3;

		scope.$inner.width(
			! mobile && scope.options.width ?
				scope.options.width : ''
		);

		scope.$modal[0].style.top = mobile ?
			0 : Math.max(
				((window.innerHeight - scope.$modal[0].offsetHeight) / 3), 0
			) + 'px';
	},

	/**
	 * Scroll modal content
	 *
	 * @param {Number} value
	 */
	scroll: function(value) {
		this.$modal.scrollTop(value, true);
	},

	/**
	 * Show confirm prompt
	 *
	 * @param {String} message
	 * @param {Function} fn
	 */
	confirm: function(message, fn) {
		var scope = this;

		scope.open({
			content: $.view.render('modal.confirm', {
				message: message
			}),
			width: 420
		});

		$(window).on('keydown', function(e) {
			if (e.keyCode === 13) {
				e.preventDefault();

				scope.close();

				fn();
			}
		}, {
			namespace: 'modal'
		});

		$$('modalConfirm').on('click', function() {
			scope.close();

			fn();
		}, {
			namespace: 'modal'
		});

		$$('modalDeny').on('click', function() {
			scope.close();
		}, {
			namespace: 'modal'
		});
	},

	/**
	 * Close the displayed modal
	 */
	close: function() {
		var scope = this;

		if (! scope.visible) {
			return;
		}

		$.events.reset('modal');

		scope.hide();
	},

	/**
	 * Hide the displayed modal
	 *
	 * @param {Boolean} [force=false]
	 */
	hide: function(force) {
		var scope = this;

		if (! scope.visible && ! force) {
			return;
		}

		scope.visible = false;

		scope.$mask.removeClass('-is-visible')
			.on('transitionend', function() {
				if (scope.visible) {
					return;
				}

				scope.$mask.removeClass('-is-visible');
			}, {
				namespace: 'modal',
				once: true
			});

		scope.$modal.add(scope.$close)
			.removeClass('-is-loaded -is-visible ' + (scope.options.modifier || ''));

		LS.util.uncrop();

		$.exec(scope.options.onHide);
	},

	/**
	 * Check model open state
	 *
	 * @returns {Boolean}
	 */
	opened: function() {
		return this.visible === true;
	}
};