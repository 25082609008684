$.view.addHelper({
	/**
	 * Format seconds to HH:MM:SS
	 *
	 * @returns {String}
	 */
	timeFormat: function() {
		var val = this.val,
			hours = Math.floor(val / 3600),
			minutes = Math.floor((val - (hours * 3600)) / 60),
			seconds = val - ((hours * 3600) + (minutes * 60));

		return (hours > 0 ? (hours < 10 ? '0' + hours : hours) + ':' : '') +
			(minutes < 10 ? '0' + minutes : minutes) + ':' +
			(seconds < 10 ? '0' + seconds : seconds);
	},

	/**
	 * Format relative age
	 *
	 * @returns {String}
	 */
	dayFormat: function() {
		return LS.util.dayFormat(this.val);
	},

	/**
	 * Get date different in days
	 *
	 * @returns {Number}
	 */
	dayCount: function() {
		var num = this.val;

		if (isNaN(num)) {
			num = Math.ceil(
				(new Date().getTime() - new Date(num).getTime()) / 86400000
			);
		}

		return num;
	},

	/**
	 * Output ISO date in human-friendly format
	 *
	 * @param {Boolean} [time=false]
	 * @param {Boolean} [year=true]
	 * @returns {String}
	 */
	dateFormat: function(time, year) {
		var months = [
			'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
			'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
		],
			val = new Date(this.val),
			formatted = months[val.getUTCMonth()] +
				' ' + val.getUTCDate();

		if (year !== false) {
			formatted += ', ' + val.getUTCFullYear();
		}

		if (time) {
			var hours = val.getUTCHours(),
				minutes = val.getUTCMinutes(),
				period = hours < 12 ?
					'am' : 'pm';

			formatted += ' ' + (
				period === 'am' ?
					(hours || 12) : (
						hours === 12 ?
							12 : hours - 12
					)
				) + ':' + (
					minutes < 10 ?
						'0' + minutes : minutes
				) + ' ' + period;
		}

		return formatted;
	}
});