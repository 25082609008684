Apt.fn.extend('properties.events', {
	/**
	 * Bind property list events
	 *
	 * @param {Object} options
	 * @returns {Object}
	 */
	init: function(options) {
		var scope = this,
			priv = this.$private;

		priv.conf = options;
		priv.uid = LS.util.uid();
		priv.previews = [];

		priv.initLazy();

		priv.bindGallery();

		priv.state = Apt.fn['properties.state']()
			.init({
				app: options.app,
				delegate: options.delegate,
				events: scope
			});

		priv.bindSpecs();
		priv.bindMarker();

		if (options.map) {
			priv.bindPoint();
		}

		return scope;
	},

	/**
	 * Get preview app
	 *
	 * @param {Number} id
	 * @returns {*}
	 */
	get: function(id) {
		return this.$private.previews[id];
	},

	/**
	 * Execute function
	 *
	 * @param {$} el
	 * @param {Function|Number} fn
	 */
	exec: function(el, fn) {
		var priv = this.$private,
			$el = $(el).closest('$property'),
			id = $el.data('id'),
			app = priv.previews[id],
			model;

		if (app) {
			fn(app, id, $el);

			return;
		}

		model = $.app[priv.conf.app].$get(['*', {
			'results.id': id
		}]);

		if (! model) {
			return;
		}

		delete model.images[0].lazy;

		priv.previews[id] = app = $.app.make('preview-' + priv.uid + '-' + id, {
			view: 'properties._listing',
			target: $el,
			model: model,
			_destruct: function() {
				if (app.map) {
					app.map.$destroy();
				}
			}
		});

		fn(app, id, $el);
	},

	/**
	 * Refresh events
	 */
	update: function() {
		var priv = this.$private;

		priv.initLazy();

		priv.state.update();
	},

	/**
	 * Unload events
	 */
	unload: function() {
		var priv = this.$private;

		LS.util.destroy(priv, 'lazy');

		priv.previews.forEach(function(preview) {
			LS.util.destroy(preview, 'gallery');

			preview.$destroy();
		});

		priv.previews = [];
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		var scope = this,
			priv = scope.$private;

		LS.util.reset(priv.uid, priv, 'state');

		scope.unload();
	}
}, {
	/**
	 * Bind gallery events
	 */
	bindGallery: function() {
		var scope = this,
			options = {
				delegate: scope.conf.delegate,
				namespace: scope.uid
			},
			active;

		$.events.on('$next, $prev', 'mousedown', function(e, el) {
			scope.initGallery(el, el.dataset.action || 'prev');
		}, options);

		if ($.supported('touch')) {
			return;
		}

		$.events.on('$next, $prev', {
			mouseover: function(e, el) {
				if (active || $.supported('touch')) {
					return;
				}

				active = true;

				scope.initGallery(el, el.dataset.action || 'prev', true);
			},
			mouseout: function() {
				active = false;
			}
		}, options);
	},

	/**
	 * Initialize lazy loading
	 */
	initLazy: function() {
		var scope = this;

		scope.lazy = Apt.fn.lazy()
			.init($('img[data-src]', scope.conf.delegate));
	},

	/**
	 * Initialize gallery
	 *
	 * @param {$} el
	 * @param {String} [value]
	 * @param {Boolean} [preload=false]
	 */
	initGallery: function(el, value, preload) {
		this.$public.exec(el, function(app, id, $el) {
			if (app.gallery) {
				if (! preload) {
					app.gallery.move(value);
				}

				return;
			}

			var next = app.$get('image_next');

			if (next) {
				new Image().src = $.get('cdnUrl') + next.path;
			}

			app.gallery = Apt.fn.gallery()
				.init({
					app: app,
					id: id,
					delegate: $el,
					value: value,
					preload: preload
				});
		});
	},

	/**
	 * Bind marker events
	 */
	bindPoint: function() {
		var scope = this,
			conf = scope.conf,
			hovered,
			map;

		if ($.supported('touch')) {
			return;
		}

		$.events.on('$property', {
			mouseenter: function(e, el) {
				if (hovered || $.supported('touch')) {
					return;
				}

				if (! map && conf.map) {
					map = conf.map();
				}

				if (map) {
					hovered = true;

					map.hover(
						$(el).data('context').center
					);
				}
			},
			mouseout: function(e, el) {
				if (hovered && ! el.contains(e.relatedTarget)) {
					hovered = null;

					map.hover();
				}
			}
		}, {
			capture: true,
			delegate: conf.delegate,
			namespace: scope.uid
		});
	},

	/**
	 * Bind property specification preview events
	 */
	bindSpecs: function() {
		var scope = this;

		$.events.on('$specs', 'mousedown', function(e, el) {
			scope.$public.exec(el, function(app, id) {
				app.$set('active', app.$get('active') === 'specs' ?
					'gallery' : 'specs'
				);

				if (app.$get('specs')) {
					return;
				}

				app.$set('specs', {
					loading: true
				});

				LS.api.get('listings/' + id + '/specs', {
					proxy: true,
					success: function(data) {
						app.$set('specs', data);
					}
				});
			});
		}, {
			delegate: scope.conf.delegate,
			namespace: scope.uid
		});
	},

	/**
	 * Bind pinpoint panning or map preview events
	 */
	bindMarker: function() {
		var scope = this,
			conf = scope.conf,
			mapped,
			map;

		$.events.on('$pinpoint', 'mousedown', function(e, el) {
			scope.$public.exec(el, function(app, id) {
				var center = app.$get('context.center');

				if (! $.app[conf.app].$get('standalone')) {
					scope.highlightMarker(id, center);

					return;
				}

				if (conf.map) {
					map = conf.map(center);
				}

				var target = app.$get('active') === 'map' ?
					'gallery' : 'map';

				app.$set('active', target);

				if (mapped && mapped !== app) {
					mapped.map.$destroy();

					mapped.$set('active', 'gallery');

					app.map = mapped = null;
				}

				if (map || target === 'gallery') {
					return;
				}

				app.$set('map.loading', true);

				LS.util.load('maps', function() {
					app.map = Apt.fn.maps().init($$('propertyMap', '[data-id="' + id + '"]')[0], {
						center: center,
						single: true,
						preview: true,
						settings: {
							center: center,
							zoom: 14.4
						}
					});

					mapped = app;
				});
			});
		}, {
			delegate: conf.delegate,
			namespace: scope.uid
		});
	},

	/**
	 * Highlight pin on map
	 *
	 * @param {Number} id
	 * @param {Array} center
	 */
	highlightMarker: function(id, center) {
		var scope = this,
			conf = scope.conf,
			map = conf.map ?
				conf.map() : null,
			properties = LS.filters.parse().section === 'property';

		if (properties && $.screen.size() < 3) {
			Apt.properties.showMap(true);
		}

		if (! map && conf.createMap) {
			conf.createMap({
				center: center,
				zoom: 14.4,
				ready: function() {
					scope.highlightMarker(id, center);
				}
			});

			return;
		}

		var synced = conf.sync && conf.sync.check(true);

		if (! properties) {
			LS.util.setScroll(map.$private.$parent);
		}

		Apt.properties.$private.clearMap(map);

		map.highlight(id);

		if (! LS.filters.isProperty() || ! synced || (
			synced && ! map.map.queryRenderedFeatures({
				layers: ['data'],
				filter: ['==', 'id', id],
				validate: false
			}).length
		)) {
			map.center(center, {
				animate: true
			});

			map.ready(function() {
				scope.moveend = function() {
					map.highlight();
				};

				map.bind('map', 'dragend', scope.moveend, true);
			});
		}
	}
});