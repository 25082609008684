Apt.fn.extend('properties', {
	/**
	 * Display mobile map view
	 *
	 * @param {Boolean} [skip=false]
	 */
	showMap: function(skip) {
		var priv = this.$private;

		$$('propertiesLeft')[0].classList.add('-is-active');

		LS.util.crop();

		document.body.classList.add('-is-mapped');

		if (priv.map) {
			priv.map.resize();
		} else if (! priv.map && ! skip) {
			priv.loadMap();
		}
	},

	/**
	 * Display mobile list view
	 */
	showList: function() {
		$$('propertiesLeft').removeClass('-is-active');

		LS.util.uncrop();

		document.body.classList.remove('-is-mapped');
	}
}, {
	/**
	 * Initialize responsive breakpoint logic
	 *
	 * @param {Function} [fn]
	 */
	initMobile: function(fn) {
		var scope = this,
			left = $$('propertiesLeft')[0];

		$.screen.map([
			{
				max: 3,
				once: true,
				callback: function() {
					scope.initOptions();
				}
			},
			{
				max: 3,
				init: false,
				callback: function() {
					$(window).off('resize.properties');

					left.removeAttribute('style');
				}
			},
			{
				max: 4,
				callback: function() {
					scope.initSticky();
				}
			},
			{
				max: 4,
				init: false,
				callback: function() {
					$$('propertiesFilters').prependTo('$propertiesRight');
				}
			},
			{
				min: 4,
				callback: function() {
					$(window).on('resize', function() {
						left.style.height = (window.innerHeight - 70) + 'px';
					}, {
						init: true,
						namespace: 'properties'
					});
				}
			},
			{
				min: 4,
				once: true,
				callback: function() {
					if (fn) {
						fn();
					}
				}
			},
			{
				min: 5,
				callback: function() {
					$$('propertiesFilters').appendTo('$propertiesFiltersDesktop');
				}
			}
		], {
			namespace: 'properties'
		});
	},

	/**
	 * Initialize stickiness
	 */
	initSticky: function() {
		var filters = $$('propertiesFilters')[0],
			hiddenClass = '-is-hidden',
			prev = 0,
			lower = 0,
			upper = 0,
			sticky = true,
			top;

		if (! filters) {
			return;
		}

		$(window).on('scroll', function() {
			top = window.pageYOffset;

			if (! top && LS.state.cropped) {
				return;
			}

			if (! top || top < upper - 100) {
				if (! sticky) {
					sticky = true;

					filters.classList.remove(hiddenClass);
				}
			} else if (sticky && top > lower + 100) {
				sticky = false;

				filters.classList.add(hiddenClass);
			}

			if (top > prev) {
				upper = top;
			} else {
				lower = top;
			}

			prev = top;
		}, {
			init: true,
			namespace: 'properties'
		});
	},

	/**
	 * Initialize mobile options
	 */
	initOptions: function() {
		var scope = this,
			pub = scope.$public,
			$results = $$('results'),
			options = $$('propertiesOptions')[0],
			hiddenClass = '-is-hidden';

		scope.activeView = 'list';

		$$('propertiesToggle').on('mousedown', function(e, el) {
			scope.activeView = el.dataset.view;

			scope.activeView === 'map' ?
				pub.showMap() :
				pub.showList();
		}, {
			namespace: 'properties'
		});

		$.scroll.map([
			{
				target: $results,
				callback: function() {
					options.classList.remove(hiddenClass);
				}
			},
			{
				target: $results,
				threshold: -1,
				callback: function() {
					options.classList.add(hiddenClass);
				}
			}
		], {
			namespace: 'properties'
		});
	}
});