Apt.fn.make('article', {
	/**
	 * Initialize module
	 */
	init: function() {
		var scope = this;

		if ($$('chart').length) {
			LS.util.load('chart', function() {
				scope.charts = Apt.chart.build();
			});
		}

		if ($$('code').length) {
			LS.util.load('code', function() {
				Apt.code.init();
			});
		}

		scope.meta = Apt.fn.meta()
			.init();
	},

	/**
	 * Unload module
	 */
	unload: function() {
		var scope = this;

		if (Apt.code) {
			Apt.code.unload();
		}

		if (scope.charts) {
			scope.charts.forEach(function(chart) {
				chart.$destroy();
			});

			scope.charts = null;
		}

		if (scope.meta) {
			scope.meta.$destroy();
		}
	}
});