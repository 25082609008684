LS.store = {
	/**
	 * Init storage
	 */
	init: function() {
		try {
			var scope = this;

			scope.sync();

			window.onstorage = function(e) {
				if (e.key === 'store') {
					scope.sync();
				}
			};
		} catch (e) {
			LS.store.data = {};
		}
	},

	/**
	 * Sync storage
	 */
	sync: function() {
		LS.store.data = JSON.parse(localStorage.getItem('store'));
	},

	/**
	 * Set storage key
	 *
	 * @param {String} key
	 * @param {*} value
	 * @param {Number} [ttl] - duration in minutes
	 */
	set: function(key, value, ttl) {
		var store = LS.store.get();

		store[key] = {
			value: value
		};

		if (ttl) {
			store[key].expires = Date.now() + (ttl * 60 * 1000);
		}

		LS.store.data = store;

		try {
			localStorage.setItem('store', JSON.stringify(store));
		} catch (e) {
			//
		}
	},

	/**
	 * Get stored data
	 *
	 * @param {String} [key]
	 * @param {*} [fallback]
	 * @returns {Object}
	 */
	get: function(key, fallback) {
		var store = LS.store.data || {};

		if (! key) {
			return store;
		}

		if (store[key]) {
			var data = store[key];

			if (! data.expires || Date.now() < data.expires) {
				return store[key].value;
			}

			LS.store.drop(key);
		}

		return fallback || null;
	},

	/**
	 * Delete stored data
	 *
	 * @param {String} key
	 */
	drop: function(key) {
		var store = LS.store.get();

		delete store[key];

		LS.store.data = store;

		try {
			localStorage.setItem('store', JSON.stringify(store));
		} catch (e) {
			//
		}
	}
};