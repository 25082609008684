Apt.fn.extend('filters', {}, {
	/**
	 * Bind more input events
	 */
	bindMore: function() {
		var scope = this,
			app = $.app.filters,
			options = {
				cancel: true,
				namespace: 'filters'
			};

		$$('filterKeywords').on('blur', function(e, el) {
			app.$set('keywords', el.value.trim());

			scope.mergeData();
		}, options);

		$$('filterDom').on('input', function() {
			this.blur();

			scope.mergeData();
		}, options);

		$$('filterStructure').on('change', function(e, el) {
			if (el.value === '1') {
				$$('range', '$filterSpace').focus();
			}

			scope.mergeData();
		}, options);

		$$('filterToggle').on('change', function(e, el) {
			app.$set(el.name, el.checked ? el.value : '');

			scope.mergeData();
		}, options);

		$$('filterMap').on('change', function(e, el) {
			var hide = el.checked;

			$('.properties').toggleClass('-is-filled', hide);

			if (! hide) {
				Apt.properties.$private.map.resize();
			}
		}, options);
	},

	/**
	 * Merge advanced criteria count data
	 *
	 * @param {Object} data
	 * @returns {Object}
	 */
	countMore: function(data) {
		data.more = 0;

		this.more.forEach(function(key) {
			if (data[key]) {
				data.more++;
			}
		});

		return data;
	}
});