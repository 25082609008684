Apt.fn.extend('properties', {
	/**
	 * Display a property tooltip
	 *
	 * @param {Object} map
	 * @param {Array} features
	 */
	showTooltip: function(map, features) {
		var scope = this,
			$tooltip = $('<div class="preview-tooltip"/>'),
			id = LS.util.pluck(features, 'id');

		if (id.length > 1) {
			$tooltip[0].classList.add('-multiple');

			scope.fetch({
				id: id,
				limit: 12
			}, function(data) {
				scope.buildTooltip(map, features, $tooltip, data);
			});

			return;
		}

		if (! Apt.panel.opened()) {
			map.highlight();
		}

		LS.api.get('listings/' + id[0] + '/preview', {
			proxy: true,
			success: function(data) {
				scope.transform(data, 0);

				scope.buildTooltip(map, features, $tooltip, {
					results: [
						data
					]
				});
			}
		});
	},

	/**
	 * Build tooltip
	 *
	 * @param {Object} map
	 * @param {Array} features
	 * @param {$} $tooltip
	 * @param {Object} data
	 */
	buildTooltip: function(map, features, $tooltip, data) {
		var name = 'tooltip-' + LS.util.uid(),
			app = $.app.make(name, {
				view: 'properties.tooltip',
				target: $tooltip,
				model: data
			}),
			popup = map.showTooltip({
				id: features[0].id,
				center: features[0].geometry.coordinates,
				content: $tooltip[0],
				class: ($.screen.size() < 3 || window.innerHeight <= 480) && ! map.enlarged() ?
					'-preview-mobile' : null,
				onHide: function() {
					$.events.reset('tooltip');

					events.$destroy();
					app.$destroy();

					popup = null;
				}
			}),
			events;

		if (! popup) {
			return;
		}

		data.results.forEach(function(result) {
			result.images[0].lazy = false;

			LS.analytics.track({
				action: 'preview',
				target: 'listing',
				id: result.id,
				category: 'entry',
				segment: 'tooltip'
			}, {
				key: result.context.key,
				source: result.context.source,
				send: false
			});
		});

		LS.analytics.send();

		var $listings = $$('tooltipListings');

		$$('tooltipLink').on('mousedown', function(e, el) {
			var size = $.screen.size(),
				offset = 125;

			if (size > 4) {
				offset = 307;
			} else if (size > 1) {
				offset = 280;
			}

			$listings.scrollTop(
				offset * $(el).index(), true
			);
		}, {
			namespace: 'tooltip'
		});

		events = Apt.fn['properties.events']()
			.init({
				app: name,
				delegate: $tooltip
			});
	},

	/**
	 * Display cluster information
	 *
	 * @param {Object} map
	 * @param {Array} features
	 */
	showCluster: function(map, features) {
		var price = 0,
			size = 0,
			priceCount = 0,
			formats = {
				auction: 0,
				lease: 0,
				sale: 0
			},
			format,
			missing,
			prop;

		features.forEach(function(el, i) {
			prop = el.properties;

			if (prop.price) {
				priceCount++;
				price += parseFloat(prop.price);
			}

			if (prop.size) {
				size += prop.size;
			} else {
				missing = true;
			}

			formats[prop.format] = formats[prop.format] + 1;

			if (! i) {
				format = prop.format;
			} else if (prop.format !== format) {
				format = null;
			}
		});

		var message = [];

		if (price && format) {
			message.push(
				'$' + LS.util.shortFormat(price / priceCount) + ' avg ' + format + ' price'
			);
		}

		if (size) {
			message.push(
				LS.util.shortFormat(size, 1) + (missing ? '+' : '') + ' total acres'
			);
		}

		if (! format) {
			Object.keys(formats).forEach(function(format) {
				var count = formats[format];

				if (count > 0) {
					message.push(count + ' ' + LS.util.plural(format, count));
				}
			});
		}

		map.$private.model.$set('message', message);
	}
}, {
	/**
	 * Load map instance
	 *
	 * @param {Object} [options]
	 */
	loadMap: function(options) {
		var priv = this.$private;

		if (priv.map) {
			return;
		}

		LS.util.load('maps', function() {
			if (priv.customMap) {
				priv.customMap(options);

				return;
			}

			priv.initMap(options);

			priv.sync = Apt.fn['properties.sync']().init({
				index: priv.index,
				map: priv.map
			});
		});
	},

	/**
	 * Create new map instance
	 *
	 * @param {Object} options
	 */
	initMap: function(options) {
		var scope = this,
			pub = scope.$public,
			bounds = LS.filters.get('bounds');

		options = options || {};

		if (! bounds && ! Apt.maps.parsePosition() && ! $.context('location')) {
			var loc = LS.location.get();

			if (loc) {
				options.center = LS.location.center();

				options.zoom = loc.slug ?
					9 : 6;
			} else {
				var center = scope.index.app.$get('results.0.context.center');

				if (center) {
					options.center = center;
					options.zoom = 9;
				}
			}
		}

		scope.map = Apt.fn.maps().init('$propertiesMap', $.extend({
			bounds: bounds,
			position: true,
			onClick: function() {
				pub.showTooltip.apply(pub, arguments);
			},
			onClusterEnter: function() {
				pub.showCluster.apply(pub, arguments);
			},
			onDraw: function(coordinates) {
				var position = scope.map.getPosition(),
					filters = {
						bounds: '',
						center: position.center,
						geometry: '',
						limit: '',
						location: '',
						zoom: position.zoom
					},
					geometry = [];

				if (! coordinates) {
					scope.index.filter(filters);

					return;
				}

				coordinates.forEach(function(point) {
					point.forEach(function(value) {
						geometry.push(
							LS.util.round(value, 6)
						);
					});
				});

				filters.geometry = geometry;

				scope.index.filter(filters);

				if ($.screen.size() < 4) {
					pub.showList();
				}
			}
		}, options));

		if (Apt.panel.opened()) {
			scope.map.pausePosition();
		}

		$$('mapDraw').on('mousedown', function() {
			if (scope.sync.check(true)) {
				scope.sync.disable();
			}
		}, {
			namespace: 'properties'
		});
	},

	/**
	 * Reset map state
	 *
	 * @param {Object} map
	 * @param {Boolean} [reset=false]
	 */
	clearMap: function(map, reset) {
		var scope = this;

		if (scope.moveend) {
			map.map.off('dragend', scope.moveend);

			scope.moveend = null;
		}

		if (reset) {
			map.highlight();
		}
	}
});