LS.support = {
	/**
	 * Initialize browser feature detection
	 */
	check: function() {
		$.supported('touch', {
			callback: function() {
				document.documentElement.classList.remove('-c');
				document.documentElement.classList.add('-t');
			}
		});
	}
};