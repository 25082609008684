Apt.fn.extend('index', {
	/**
	 * Update index sorting
	 *
	 * @param {String} value
	 */
	sort: function(value) {
		var scope = this,
			filters = scope.conf.filters;

		if (
			filters.sort === value &&
			filters.page === 1
		) {
			return;
		}

		scope.app.$pause();

		scope.app.$set('sort', scope.conf.sorting[value]);

		scope.filter({
			page: 1,
			sort: value
		});
	}
}, {
	/**
	 * Initialize application sorting
	 *
	 * @param {Object} conf
	 */
	initSorting: function(conf) {
		if (! conf.sorting) {
			return;
		}

		var value = conf.filters.sort || Object.keys(conf.sorting)[0];

		this.sorted = conf.filters.sort = value;

		conf.model.sort = conf.sorting[value];
		conf.model.sorting = conf.sorting;
	},

	/**
	 * Initialize app sorting controls
	 */
	bindSorting: function() {
		var pub = this.$public,
			activeClass = '-is-active',
			open = false;

		$.events.on({
			'$sortingControl': {
				mousedown: function(e, el) {
					open = ! open;

					el.classList.toggle(activeClass);

					$(document.body).on('mousedown', function(e) {
						if (! open || e.target === el) {
							return;
						}

						open = ! open;

						el.classList.remove(activeClass);
					}, {
						namespace: pub.uid
					});
				}
			},
			'$sortingOption': {
				mousedown: function(e, el) {
					pub.sort(el.dataset.value);
				}
			}
		}, {
			delegate: pub.conf.$target,
			namespace: pub.uid
		});
	}
});