(function(L) {
	'use strict';

	var intersect = 'IntersectionObserver' in L._win,
		observers = [],
		id = 0,

		/**
		 * Bind individual rule
		 *
		 * @private
		 * @param {Function} conf.callback
		 * @param {($|HTMLElement|String)} [conf.context=window] - parent container to bind event
		 * @param {Number} [conf.debounce=100] - debounce timing in milliseconds
		 * @param {Boolean} [conf=false] - whole element in view
		 * @param {String} [conf.namespace] - namespace the event
		 * @param {Boolean} [conf.once=false] - only execute the callback once
		 * @param {Object} [conf.scope] - callback scope
		 * @param {($|HTMLElement|String)} conf.target - element to evaluate
		 * @param {Boolean} [conf.watch=true] - check event on scroll
		 */
		_addRule = function(conf) {
			L.$each(conf.target, function(el) {
				var rule = L.$copy(conf),
					observer,
					wait;

				// Attach unique identifier
				rule.i = id++;
				rule.margin = rule.margin || 0;
				rule.threshold = rule.threshold || 0;

				// Capture base element
				rule.target = el;

				// Only setup watching when enabled
				if (rule.watch !== false) {
					var context = rule.context ? L.$(rule.context)[0] : (
						intersect ? null : L._win
					);

					observers.forEach(function(obj) {
						if (
							obj.context === context &&
							obj.margin === rule.margin &&
							obj.threshold === rule.threshold
						) {
							observer = obj;
						}
					});

					// Only attach event once
					if (! observer) {
						observer = {
							context: context,
							rules: [],
							margin: rule.margin,
							threshold: rule.threshold
						};

						if (intersect) {
							observer.fn = new IntersectionObserver(function(items) {
								items.forEach(function(item) {
									_run(observer, false, null, item);
								});
							}, {
								root: context,
								rootMargin: rule.margin + 'px',
								threshold: Math.max(rule.threshold, 0)
							});
						} else {
							observer.fn = function() {
								if (! wait) {
									wait = true;
									_run(observer, false);

									setTimeout(function() {
										wait = false;
									}, rule.debounce || 100);
								}
							};

							['resize', 'scroll'].forEach(function(event) {
								context.addEventListener(event, observer.fn, false);
							});
						}

						observers.push(observer);
					}

					observer.rules.push(rule);

					if (intersect) {
						observer.fn.observe(el);
					}
				}

				if (rule.watch === false || ! intersect) {
					_run(observer, true);
				}
			});
		},

		/**
		 * Check mapped rules for matching conditions
		 *
		 * @private
		 * @param {Object} [observer] - observation instance
		 * @param {Boolean} [init=false] - initial page load
		 * @param {(Boolean|Array)} [rules] - scroll rules
		 * @param {Object} [item] - intersection item
		 */
		_run = function(observer, init, rules, item) {
			rules = rules || observer.rules;

			var i = rules.length,
				rule,
				el,
				visible;

			while (i--) {
				rule = rules[i];
				el = item ? item.target : rule.target;

				if (item) {
					if (el === rule.target && (
						(rule.threshold !== -1 && item.isIntersecting &&
							item.intersectionRatio >= rule.threshold
						) || (rule.threshold === -1 && ! item.isIntersecting)
					)) {
						_exec(rule, el, observer, init);
					}
				} else {
					visible = rule.threshold !== -1;

					if (_check(rule.target, rule.threshold, rule.margin)) {
						if (rule.visible !== visible) {
							rule.visible = visible;
							_exec(rule, el, observer, init);
						}
					} else {
						rule.visible = ! visible;
					}
				}
			}
		},

		/**
		 * Execute matched rules
		 *
		 * @private
		 * @param {Object} rule
		 * @param {Object} el
		 * @param {Object} [observer] - observation instance
		 * @param {Boolean} [init=false] - initial page load
		 */
		_exec = function(rule, el, observer, init) {
			var data = {
				target: el,
				init: init
			};

			rule.visible = rule.threshold !== -1;

			L.$exec(rule.callback, {
				args: rule.args ? [data].concat(rule.args) : data,
				scope: rule.scope
			});

			// Disable future execution if once
			if (rule.once) {
				_remove(observer, 'i', rule.i, init !== true);
			}
		},

		/**
		 * Determine if element is currently visible
		 *
		 * @private
		 * @param {HTMLElement} el - element to evaluate
		 * @param {Number} [threshold=0] - ratio of element in view
		 * @param {Number} [margin=0] - vertical intersection bound offset in pixels
		 * @returns {Boolean}
		 */
		_check = function(el, threshold, margin) {
			if (! L._html.contains(el) || ! el.offsetWidth) {
				return false;
			}

			// Get position relative to the viewport
			var rect = el.getBoundingClientRect();

			threshold = threshold || 0;
			margin = margin || 0;

			if (threshold === -1) {
				return rect.left + rect.width < 0 ||
					rect.top + rect.height < 0 ||
					rect.left > L._win.innerWidth ||
					rect.top > L._win.innerHeight;
			}

			return rect.top + (rect.height * (1 - threshold)) - margin > -1 &&
				rect.left + (rect.width * (1 - threshold)) > -1 &&
				rect.top + (rect.height * threshold) - margin <= (L._win.innerHeight || L._html.clientHeight) &&
				rect.left + (rect.width * threshold) <= (L._win.innerWidth || L._html.clientWidth);
		},

		/**
		 * Remove scroll event
		 *
		 * @private
		 * @param {Object} observer
		 * @param {String} key
		 * @param {String} value
		 * @param {Boolean} [unbind=false]
		 * @returns {Boolean}
		 */
		_remove = function(observer, key, value, unbind) {
			observer.rules = observer.rules.filter(function(event) {
				return event[key] !== value;
			});

			if (! unbind || observer.rules.length) {
				return true;
			}

			if (intersect) {
				observer.fn.disconnect();
			} else {
				['resize', 'scroll'].forEach(function(event) {
					observer.context.removeEventListener(event, observer.fn);
				});
			}

			return false;
		};

	L.scroll = {
		/**
		 * Determine if elements are currently visible
		 *
		 * @param {($|HTMLElement|String)} sel - elements to evaluate
		 * @param {Number} [threshold=0] - ratio of element in view
		 * @param {Number} [margin=0] - vertical intersection bound offset in pixels
		 * @returns {Boolean}
		 */
		visible: function(sel, threshold, margin) {
			var visible = false;

			L.$each(sel, function(el) {
				if (visible) {
					return;
				}

				visible = _check(el, threshold, margin);
			});

			return visible;
		},

		/**
		 * Map conditional events to element scroll display
		 *
		 * @param {(Array|Object)} rules - visibility rules
		 * @param {Array} [rules.args] - callback arguments
		 * @param {Function} rules.callback
		 * @param {($|HTMLElement|String)} [rules.context=window] - parent container to bind event
		 * @param {Number} [rules.debounce=100] - fallback debounce timing in milliseconds
		 * @param {Number} [rules.margin=0] - vertical intersection bound offset in pixels
		 * @param {Number} [rules.threshold=0] - ratio of element in view
		 * @param {String} [rules.namespace] - namespace the event
		 * @param {Boolean} [rules.once=false] - only execute the callback once
		 * @param {Object} [rules.scope] - callback scope
		 * @param {($|HTMLElement|String)} rules.target - element to evaluate
		 * @param {Boolean} [rules.watch=true] - check event on scroll
		 * @param {Object} [options] - common settings merged into each rule
		 */
		map: function(rules, options) {
			var sets = L.$toArray(rules),
				i = sets.length;

			while (i--) {
				_addRule(
					L.$extend(sets[i], options)
				);
			}
		},

		/**
		 * Trigger intersection evaluation
		 */
		trigger: function() {
			observers.forEach(function(observer) {
				_run(observer, false);
			});
		},

		/**
		 * Remove events from bound rules
		 *
		 * @param {String} [namespace] - remove scroll events in this namespace
		 */
		reset: function(namespace) {
			observers = observers.filter(function(observer) {
				return _remove(observer, 'namespace', namespace, true);
			});
		}
	};
})(Apt);