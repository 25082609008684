Apt.fn.make('accordion', {
	/**
	 * Initialize module
	 *
	 * @param {Function} [fn]
	 */
	init: function(fn) {
		var scope = this;

		scope.fn = fn;
		scope.uid = LS.util.uid();

		$.events.on('$heading', 'mousedown', function(e, el) {
			scope.toggle(el.parentNode, el.getAttribute('aria-expanded') !== 'true');
		}, {
			delegate: '$accordion',
			namespace: scope.uid
		});

		return scope;
	},

	/**
	 * Toggle section
	 *
	 * @param {$} section
	 * @param {Boolean} expand
	 */
	toggle: function(section, expand) {
		var scope = this,
			$section = $(section),
			el = $section.find('$heading')[0];

		el.setAttribute('aria-expanded', expand);

		$('#' + el.getAttribute('aria-controls')).toggle(expand);

		if (expand && scope.fn) {
			scope.fn($section.data('type'));
		}
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		$.events.reset(this.uid);
	}
});