LS.location = {
	/**
	 * Get request location
	 *
	 * @param {Boolean} [international=false]
	 * @returns {Object}
	 */
	get: function(international) {
		var loc = $.context('request.location');

		return loc && (
			international ||
			loc.country === 'US'
		) ?
			loc : null;
	},

	/**
	 * Get request center
	 *
	 * @param {Boolean} [international=false]
	 * @returns {Array}
	 */
	center: function(international) {
		var loc = this.get(international);

		return loc ? [
			loc.longitude,
			loc.latitude
		] : null;
	},

	/**
	 * Fetch location data
	 *
	 * @param {String} slug
	 * @param {Function} fn
	 */
	entry: function(slug, fn) {
		LS.api.get('locations/' + slug, {
			proxy: true,
			flash: false,
			success: fn
		});
	},

	/**
	 * Format location heading
	 *
	 * @param {Object} value
	 * @param {Boolean} [suffix=false]
	 * @returns {*}
	 */
	heading: function(value, suffix) {
		if (! value) {
			return '';
		}

		var heading = value.name + (
			value.label ?
				' ' + value.label : ''
		);

		if (suffix) {
			heading += value.suffix ?
				', ' + value.suffix : '';
		}

		return heading;
	}
};