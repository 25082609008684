$.view.addHelper({
	/**
	 * Encode string for URI usage
	 *
	 * @returns {String}
	 */
	encode: function() {
		return encodeURIComponent(this.val);
	},

	/**
	 * Replace newlines with paragraphs
	 *
	 * @returns {String}
	 */
	nl2br: function() {
		return this.val ?
			'<p>' + this.val.replace(/\n+/g, '</p><p>') + '</p>' :
			'';
	},

	/**
	 * Render paragraphs and lists
	 *
	 * @returns {String}
	 */
	markdown: function() {
		return LS.util.markdown(this.val);
	},

	/**
	 * Conditionally output as plural
	 *
	 * @param {String} label
	 * @returns {String}
	 */
	plural: function(label) {
		return LS.util.plural(label, this.val);
	}
});