(function(L) {
	'use strict';

	var xhr = [],

		/**
		 * Execute the request success callback
		 *
		 * @private
		 * @param {XMLHttpRequest} x
		 * @param {Object} conf
		 */
		_success = function(x, conf) {
			var resp = ! conf.responseType || conf.responseType === 'text' ?
					x.responseText :
					x.response,
				exec = {
					args: conf.args.slice(0),
					scope: conf.scope
				};

			// Parse JSON response if specified
			if (conf.json) {
				try {
					resp = JSON.parse(resp);
				} catch (e) {
					resp = {};
				}
			}

			exec.args.unshift(resp);

			// Execute success callback if specified
			L.$exec(conf.success, exec);
		},

		/**
		 * Process the load change event
		 *
		 * @private
		 * @param {XMLHttpRequest} x
		 * @param {Object} conf
		 */
		_load = function(x, conf) {
			var status = x.status,
				exec = {
					args: conf.args,
					scope: conf.scope
				};

			xhr = xhr.filter(function(obj) {
				return obj.xhr !== x;
			});

			if (! status) {
				return;
			}

			if (status >= 200 && status < 400) {
				if (conf.success) {
					_success(x, conf);
				}
			} else if (conf.error && x.getAllResponseHeaders()) {
				L.$exec(conf.error, exec);
			}

			if (conf.complete) {
				L.$exec(conf.complete, exec);
			}
		};

	L.fetch = {
		/**
		 * Make request based on specified options
		 *
		 * @param {Object} options
		 * @param {(Array|Function|String)} [options.abort] - callback on request abortion
		 * @param {Array} [options.args] - callback arguments appended after default values
		 * @param {Boolean} [options.async=true] - toggle asynchronous state
		 * @param {(Array|Function|String)} [options.complete] - callback on request completion
		 * @param {Boolean} [options.cache=true] - disable automatic cache-busting query string
		 * @param {Object} [options.data] - object to serialize and pass along with request
		 * @param {(Array|Function|String)} [options.error] - callback if request fails
		 * @param {Object} [options.headers] - request headers
		 * @param {Boolean} [options.json=false] - evaluate the response as JSON and return object
		 * @param {String} [options.method=get] - request verb
		 * @param {String} [options.namespace] - XHR queue namespace
		 * @param {Boolean} [options.processData=true] - post data in the body
		 * @param {String} [options.responseType] - set the type of the response
		 * @param {String} [options.root=''] - prepended request path
		 * @param {Object} [options.scope] - callback scope
		 * @param {(Array|Function|String)} [options.send] - executed before Ajax call
		 * @param {(Array|Function|String)} [options.success] - callback if request succeeds
		 * @param {String} [options.type] - form, html, json, or xml
		 * @param {String} options.url - endpoint to request
		 * @returns {(void|XMLHttpRequest)}
		 */
		request: function(options) {
			var conf = L.$extend({
				args: [],
				async: true,
				data: {},
				headers: {},
				method: 'get',
				root: ''
			}, options);

			if (conf.cache === false) {
				conf.data.dt = Date.now();
			}

			// Prefix root path to url
			if (conf.root) {
				conf.url = conf.root.replace(/\/$/, '') + '/' +
					conf.url.replace(/^\//, '');
			}

			var x = new XMLHttpRequest();

			// Prepend XHR object and configuration
			conf.args.unshift(x, conf);

			if (conf.send) {
				L.$exec(conf.send, {
					args: conf.args,
					scope: conf.scope
				});
			}

			x.onload = function() {
				_load(x, conf);
			};

			var contentTypeHeader = 'Content-Type',
				method = conf.method.toUpperCase(),
				str = typeof conf.data === 'string',
				send = null,
				headers = [];

			if (! str && ! conf.type) {
				conf.type = 'json';
			}

			// Format data based on specified verb
			if (method === 'GET') {
				conf.url = this._getUrl(conf);
			} else {
				send = str || conf.processData === false ?
					conf.data :
					conf.type === 'json' ?
						JSON.stringify(conf.data) :
						L.$serialize(conf.data);
			}

			x.open(method, conf.url, conf.async);

			xhr.push({
				namespace: conf.namespace,
				abort: conf.abort,
				xhr: x
			});

			// Add content type header
			if (conf.type === 'json') {
				headers[contentTypeHeader] = 'application/json';
			} else if (method === 'POST' || conf.type === 'form') {
				headers[contentTypeHeader] =
					'application/x-www-form-urlencoded';
			}

			// Append character set to content type header
			headers[contentTypeHeader] += '; charset=UTF-8';

			// Accept JSON header
			if (conf.json) {
				headers.Accept = 'application/json, text/javascript, */*; q=0.01';
			}

			// Extend configured headers into defaults
			headers = L.$extend(headers, conf.headers);

			// Set request headers
			for (var key in headers) {
				var val = headers[key];

				if (val !== false) {
					x.setRequestHeader(key, val);
				}
			}

			// Set response type
			if (conf.responseType) {
				x.responseType = conf.responseType;
			}

			x.onerror = function() {
				var exec = {
					args: conf.args,
					scope: conf.scope
				};

				if (conf.error) {
					L.$exec(conf.error, exec);
				}

				if (conf.complete) {
					L.$exec(conf.complete, exec);
				}
			};

			x.send(send);

			return x;
		},

		/**
		 * Abort XHR from pending requests
		 *
		 * @param {String} [namespace] - abort requests in this namespace
		 */
		abort: function(namespace) {
			var diff;

			xhr = xhr.filter(function(obj) {
				diff = obj.namespace !== namespace;

				if (! diff) {
					obj.xhr.abort();

					L.$exec(obj.abort);
				}

				return diff;
			});
		},

		/**
		 * Generate final URL
		 *
		 * @private
		 * @param {Object} conf
		 * @returns {String}
		 */
		_getUrl: function(conf) {
			var url = conf.url.replace(/[?&]$/, '');

			if (conf.data && Object.keys(conf.data).length) {
				url += (url.indexOf('?') < 0 ? '?' : '&') +
					L.$serialize(conf.data);
			}

			if (! /^((https?:)?\/)/i.test(url)) {
				url = '/' + url;
			}

			return url;
		}
	};
})(Apt);