(function(L, U) {
	'use strict';

	var groups = {},
		loaded = {},
		root = '',
		groupId = 1,

		_load = {
			/**
			 * Request JavaScript
			 *
			 * @private
			 * @param {String} path
			 * @param {Object} conf
			 * @param {String} conf.group
			 * @param {Boolean} [conf.async=false]
			 * @param {Object} [conf.attributes]
			 */
			js: function(path, conf) {
				var js = L._doc.createElement('script');

				js.async = conf.async === true;

				_process(js, conf, 'src');

				js.src = path;

				L._doc.head.appendChild(js);
			},

			/**
			 * Request stylesheet
			 *
			 * @private
			 * @param {String} path
			 * @param {Object} conf
			 * @param {String} conf.group
			 * @param {Object} [conf.attributes]
			 */
			css: function(path, conf) {
				var link = L._doc.createElement('link');

				link.rel = 'stylesheet';
				link.href = path;

				_process(link, conf, 'href');

				L._doc.head.appendChild(link);
			},

			/**
			 * Request image
			 *
			 * @private
			 * @param {String} path
			 * @param {Object} conf
			 * @param {Boolean} [conf.async=false]
			 * @param {String} conf.group
			 */
			img: function(path, conf) {
				var img = new Image();

				_process(img, conf);

				if (conf.async === false) {
					img.decoding = 'sync';
				}

				img.src = path;
			}
		},

		/**
		 * Process asset load
		 *
		 * @param {HTMLElement} el
		 * @param {Object} conf
		 * @param {String} [prop]
		 */
		_process = function(el, conf, prop) {
			var load = function() {
					if (prop) {
						loaded[el[prop]] = el;
					}

					unbind();

					_done(conf.group);
				},
				fail = function() {
					unbind();

					_fail(conf.group);
				},
				unbind = function() {
					el.removeEventListener('load', load);
					el.removeEventListener('error', fail);
				};

			L._extend(el, conf.attributes);

			el.addEventListener('load', load, false);
			el.addEventListener('error', fail, false);
		},

		/**
		 * Decrement remaining asset count
		 *
		 * @private
		 * @param {String} group
		 */
		_done = function(group) {
			groups[group][0]--;

			L.assets.ready(group, {}, false);
		},

		/**
		 * Increment failed asset count
		 *
		 * @private
		 * @param {String} group
		 */
		_fail = function(group) {
			groups[group][2]++;

			_done(group);
		};

	L.assets = {
		/**
		 * Get current asset root or set with specified value
		 *
		 * @param {String} [value]
		 * @returns {String} root
		 */
		root: function(value) {
			if (value) {
				root = value;
			}

			return root;
		},

		/**
		 * Load specified assets with set options
		 *
		 * @param {Object} options
		 * @param {Boolean} [options.async=false]
		 * @param {Object} [options.attributes]
		 * @param {Boolean} [options.cache=false]
		 * @param {(Array|String)} [options.css]
		 * @param {(Array|Function|String)} [options.error]
		 * @param {(Array|String)} [options.files]
		 * @param {String} [options.group]
		 * @param {(Array|String)} [options.img]
		 * @param {(Array|String)} [options.js]
		 * @param {String} [options.root]
		 * @param {(Array|Function|String)} [options.success]
		 */
		load: function(options) {
			var files = L.$toArray(options.files),
				js = L.$toArray(options.js),
				css = L.$toArray(options.css),
				img = L.$toArray(options.img),
				root = options.root !== U ?
					options.root : this.root(),
				assets = [],
				i = 0,
				type;

			// Create group name if not specified
			if (! options.group) {
				options.group = 'g' + groupId++;
			}

			// Determine file type
			for (; i < files.length; i++) {
				var ext = files[i].split('.').pop()
					.split(/[#?]/)[0];

				type = ext === 'js' || ext === 'css' ?
					ext : /(gif|jpe?g|png|svg|webp)$/i.test(ext) ?
						'img' : '';

				if (type) {
					assets[files[i]] = type;
				}
			}

			for (i = 0; i < js.length; i++) {
				assets[js[i]] = 'js';
			}

			for (i = 0; i < css.length; i++) {
				assets[css[i]] = 'css';
			}

			for (i = 0; i < img.length; i++) {
				assets[img[i]] = 'img';
			}

			// Set file array length to check against
			groups[options.group] = [
				Object.keys(assets).length,
				options,
				0
			];

			// Request each specified file
			for (var file in assets) {
				type = assets[file];

				if (! _load[type]) {
					continue;
				}

				var noCache = options.cache === false,
					a = L._doc.createElement('a');

				a.href = (root && /^(https?:)?\/\//i.test(file) ? '' : root) +
					file;

				file = a.href;

				if (! loaded[file] || noCache) {
					if (noCache) {
						file += (file.indexOf('?') < 0 ? '?' : '&') + Date.now();
					}

					_load[type](file, options);
				} else {
					_done(options.group);
				}
			}
		},

		/**
		 * Remove one or more files from the DOM
		 *
		 * @param {(Array|String)} files
		 * @param {String} [root='']
		 */
		remove: function(files, root) {
			files = L.$toArray(files);
			root = root || '';

			var a = L._doc.createElement('a'),
				i = 0;

			for (; i < files.length; i++) {
				var src = root + files[i];

				a.href = src;
				src = a.href;

				var el = loaded[src];

				if (el !== U) {
					el.parentNode.removeChild(el);
					el = null;
					delete loaded[src];
				}
			}
		},

		/**
		 * Execute callback when specified references are ready
		 *
		 * @param {String} group
		 * @param {Object} [options]
		 * @param {Array} [options.args]
		 * @param {(Array|Function|String)} [options.error]
		 * @param {Object} [options.scope]
		 * @param {(Array|Function|String)} [options.success]
		 * @param {Boolean} [poll=false]
		 * @returns {Boolean} ready
		 */
		ready: function(group, options, poll) {
			var set = groups[group],
				complete = set && ! set[0];

			if (options === U) {
				return complete;
			}

			if (complete) {
				var conf = L.$extend(set[1], options),
					hasErrors = set[2];

				options = {
					args: conf.args,
					scope: conf.scope
				};

				if (conf.error && hasErrors) {
					L.$exec(conf.error, options);
				} else if (conf.success && ! hasErrors) {
					L.$exec(conf.success, options);
				}

				groups[group] = null;
			} else if (poll) {
				setTimeout(L.assets.ready, 20, group, options, true);
			}
		}
	};

	/**
	 * Cache existing CSS and JavaScript assets
	 */
	L.$each('link[rel="stylesheet"], script[src]', function(el) {
		loaded[el.src || el.href] = el;
	});
})(Apt, undefined);