$.view.addHelper('paginate', function() {
	var total = this.val;

	if (total < 2) {
		return false;
	}

	var base = this.root.base,
		size = this.root.page_size,
		current = this.root.current_page,
		start = ((current - 1) * size) + 1,
		min = Math.max(1, Math.min(current + (total - current) - 3, current - 1)),
		max = Math.min(total, Math.max(current - (current - 1) + 3, current + 2)),
		i = min,
		resp = {
			pages: [],
			total: LS.util.formatNumber(this.root.total),
			start: LS.util.formatNumber(start),
			end: LS.util.formatNumber(Math.min(this.root.total, start + size - 1))
		};

	while (i <= max) {
		var page = {
			num: i,
			formatted: LS.util.formatNumber(i),
			path: base + (i > 1 ? '/p' + i : '')
		};

		if (i === current) {
			page.current = true;
		}

		resp.pages.push(page);

		i++;
	}

	if (min > 1) {
		if (min > 2) {
			resp.leading = true;
		}

		resp.first = {
			num: 1,
			formatted: 1,
			path: base
		};
	}

	if (total > max) {
		if (total > max + 1) {
			resp.trailing = true;
		}

		if (total * size <= 960) {
			resp.last = {
				num: total,
				formatted: LS.util.formatNumber(total),
				path: base + '/p' + total
			};
		}
	}

	if (current > 1) {
		resp.prev = {
			num: current - 1,
			formatted: LS.util.formatNumber(current - 1),
			path: base + (current > 2 ? '/p' + (current - 1) : '')
		};
	}

	if (current < total) {
		resp.next = {
			num: current + 1,
			formatted: LS.util.formatNumber(current + 1),
			path: base + '/p' + (current + 1)
		};
	}

	return resp;
});