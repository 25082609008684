Apt.fn.make('gallery', {
	/**
	 * Initialize module
	 *
	 * @param {Object} options
	 * @returns {Object}
	 */
	init: function(options) {
		var scope = this,
			priv = scope.$private,
			preload = options.preload;

		scope.conf = $.extend({
			type: 'image',
			context: {}
		}, options);

		scope.uid = LS.util.uid();

		priv.total = options.app.$get('total_' + scope.conf.type + 's');

		if (priv.total > 1) {
			scope.resume();

			if (preload || options.value) {
				priv.fetch(options.value || 'next', preload);
			}
		}

		$('img', options.delegate)
			.first()
			.on('error', function() {
				priv.log();
			});

		return scope;
	},

	/**
	 * Set gallery position
	 *
	 * @param {Number|String} value
	 */
	move: function(value) {
		this.$private.fetch(value);
	},

	/**
	 * Pause events
	 */
	pause: function() {
		var uid = this.uid;

		$.fetch.abort(uid);
		$.events.reset(uid);
	},

	/**
	 * Resume events
	 */
	resume: function() {
		var scope = this,
			conf = scope.conf;

		if (conf.bind) {
			$.events.on('$next, $prev', 'mousedown', function(e, el) {
				if (e.button === 2) {
					return;
				}

				scope.move(el.dataset.action || 'prev');
			}, {
				delegate: conf.delegate,
				namespace: scope.uid
			});

			if (conf.large) {
				$(window).on('keydown', function(e) {
					var key = e.keyCode;

					if (
						key === 37 ||
						key === 39
					) {
						scope.move(
							key === 39 ?
								'next' : 'prev'
						);
					}
				}, {
					namespace: scope.uid
				});
			}
		}
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		var scope = this;

		scope.pause();

		scope.conf.app = null;
	}
}, {
	/**
	 * Process gallery request
	 *
	 * @param {Number|String} value
	 * @param {Boolean} [preload=false]
	 */
	fetch: function(value, preload) {
		var scope = this,
			pub = scope.$public,
			conf = pub.conf;

		if (! conf.app) {
			return;
		}

		if (conf.type === 'video') {
			if (preload) {
				return;
			}

			scope.set(value);

			return;
		}

		if (scope.fetched && ! preload) {
			scope.load(value);

			return;
		}

		if (conf.large) {
			scope.fetched = true;

			scope.process(conf, conf.data);
			scope.load(value, preload);

			return;
		}

		if (preload) {
			scope.loading = true;
		} else {
			conf.app.$set('loading', true);

			if (scope.loading) {
				scope.loading = 'load';

				return;
			}

			scope.loading = true;
		}

		LS.api.get('listings/' + conf.id + '/thumbnails', {
			namespace: pub.uid,
			proxy: true,
			success: function(data) {
				if (! conf.app) {
					return;
				}

				scope.fetched = true;

				conf.app.$pause();

				scope.process(conf, data);

				conf.app.$resume();

				if (! preload || scope.loading === 'load') {
					scope.load(value);
				}
			},
			error: function() {
				if (conf.app && ! preload) {
					conf.app.$drop('loading');
				}
			},
			complete: function() {
				delete scope.loading;
			}
		});
	},

	/**
	 * Load gallery images
	 *
	 * @param {Number|String} value
	 * @param {Boolean|Number} [preload=false]
	 */
	load: function(value, preload) {
		var scope = this,
			pub = scope.$public,
			conf = pub.conf,
			images = conf.app.$get('images'),
			numeric = ! preload &&
				typeof value === 'number',
			index,
			targets;

		if (preload) {
			if (preload === true) {
				if (value === 'next') {
					index = 1;
				} else {
					index = scope.index(value);

					index = index ?
						index - 1 :
						scope.total - 1;
				}
			} else {
				index = preload + (value === 'next' ? 4 : -4);
			}

			if (! images[index]) {
				return;
			}
		} else if (numeric) {
			index = value - 1;
		} else {
			index = scope.index(value) - 1;
		}

		try {
			if (images[index].fetched) {
				if (! preload) {
					scope.set(value);

					if (index % 4) {
						scope.load(value, index);
					}
				}

				return;
			}
		} catch (e) {
			scope.log(e.message);

			return;
		}

		if (numeric) {
			targets = [
				images[index].path
			];

			images[index].fetched = true;
		} else {
			var range = index + (value === 'next' ? 4 : -4),
				min = Math.min(index, range),
				max = Math.max(index + 1, range);

			targets = LS.util.pluck(
				images.slice(min, max),
				conf.large && $.screen.size() === 1 ?
					'small' : 'path'
			);

			if (value === 'prev') {
				targets.reverse();
			}

			images.forEach(function(image, i) {
				image.position = i + 1;

				if (i >= min && i < max) {
					image.fetched = true;
				}
			});
		}

		if (! targets.length) {
			return;
		}

		if (! preload && ! scope.loading) {
			conf.app.$set('loading', true);
		}

		$.assets.load({
			img: index === 1 ?
				targets.slice(0, 1) :
				targets,
			success: function() {
				if (! conf.app) {
					return;
				}

				conf.app.$set('images', images);

				if (index === 1 && targets.length > 1) {
					$.assets.load({
						img: targets.slice(1)
					});
				}

				if (! preload) {
					conf.app.$drop('loading');

					scope.set(value);

					if (! numeric) {
						scope.load(value, index);
					}
				}
			}
		});
	},

	/**
	 * Process assets
	 *
	 * @param {Object} conf
	 * @param {Object} data
	 */
	process: function(conf, data) {
		var scope = this;

		if (scope.processed) {
			return;
		}

		conf.app.$set('images', this.transform(data));

		scope.processed = true;
	},

	/**
	 * Transform response
	 *
	 * @param {Object} data
	 * @returns {Array}
	 */
	transform: function(data) {
		var conf = this.$public.conf,
			large = conf.large;

		try {
			return data.images.map(function(image, i) {
				var ratio = image.width / image.height,
					obj = {
						id: image.id,
						position: i + 1,
						path: $.get('cdnUrl') + '/listings/' + data.hash + '/' +
							(large ? 'large' : 'small') + '/' + image.key + '.jpg',
						fetched: i === 0,
						cover: ratio > 1 && ratio < 2
					};

				if (large) {
					obj.small = obj.path.replace('/large/', '/small/');
					obj.caption = image.caption;
				} else if (! i) {
					obj.cover = true;
				}

				return obj;
			});
		} catch (e) {
			this.log(e.message);
		}
	},

	/**
	 * Calculate index
	 *
	 * @param {String} value
	 * @returns {Number}
	 */
	index: function(value) {
		var scope = this,
			conf = scope.$public.conf,
			index = conf.app.$get('current_' + conf.type);

		if (value === 'next') {
			index++;

			return index > scope.total ?
				1 : index;
		}

		index--;

		return index < 1 ?
			scope.total : index;
	},

	/**
	 * Set position
	 *
	 * @param {Number|String} [value]
	 */
	set: function(value) {
		var scope = this,
			conf = scope.$public.conf,
			position = typeof value === 'number' ?
				value : scope.index(value);

		$.exec(conf.move, {
			args: [position, scope.total]
		});

		conf.app.$set('current_' + conf.type, position);
	},

	/**
	 * Log error
	 *
	 * @param {String} [cause]
	 */
	log: function(cause) {
		var id = this.$public.conf.id;

		LS.error.report('GalleryError', cause, {
			listing_id: id
		});
	}
});