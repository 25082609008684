$.view.addHelper({
	/**
	 * Check if value exists
	 *
	 * @returns {Boolean}
	 */
	contains: function() {
		var value = this.val;

		return Array.prototype.slice.call(arguments)
			.some(function(arg) {
				return value[arg] || null;
			});
	},

	/**
	 * Check for property value
	 *
	 * @param {String} prop
	 * @param {*} value
	 * @returns {Number}
	 */
	where: function(prop, value) {
		return this.val && this.val.filter(function(el) {
			return el[prop] === value;
		});
	},

	/**
	 * Return the number of items in the current collection or makes comparison
	 *
	 * @param {Number} [value]
	 * @returns {*}
	 */
	length: function(value) {
		var length = $.toArray(this.val).length;

		if (value) {
			return length === value;
		}

		return length;
	},

	/**
	 * Determine if the current value exists in a provided array
	 *
	 * @param {Array} array
	 * @param {String} [key]
	 * @returns {Boolean}
	 */
	in: function(array, key) {
		return $.toArray(array)
			.indexOf(key ? this.data[key] : this.val) > -1;
	},

	/**
	 * Retrieve value at specific array index
	 *
	 * @param {(Number|String)} index
	 * @param {*} [fallback]
	 * @returns {*}
	 */
	index: function(index, fallback) {
		return this.val[index] || fallback;
	},

	/**
	 * Join values
	 *
	 * @param {String} [separator=', ']
	 * @returns {String}
	 */
	join: function(separator) {
		return Array.isArray(this.val) ?
			this.val.join(separator || ', ') : this.val;
	}
});