Apt.fn.make('filters.ranges', {
	/**
	 * Initialize module
	 *
	 * @param {Object} options
	 * @returns {Object}
	 */
	init: function(options) {
		var scope = this,
			priv = scope.$private;

		priv.conf = $.extend({
			namespace: LS.util.uid()
		}, options);

		priv.bind();

		return scope;
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		$.events.reset(this.$private.conf.namespace);
	}
}, {
	/**
	 * @private
	 */
	_construct: function() {
		this.bounds = Apt.filters.$private.bounds;
	},

	/**
	 * Bind filter range events
	 */
	bind: function() {
		var scope = this,
			conf = scope.conf,
			cancel = true;

		scope.bindOption();

		$$('range', conf.context).on({
			focus: function(e, el) {
				var type = el.dataset.type;

				if (! type) {
					return;
				}

				var bound = el.dataset.bound,
					range = $.app[conf.app].$get(type),
					min = LS.util.parseNumber(range.min),
					max = LS.util.parseNumber(range.max),
					options = scope.bounds[type][bound];

				if (bound === 'min') {
					if (max) {
						options = scope.getMinOptions(
							(max - (max / 4)) / 4,
							scope.getScale(max)
						);
					} else if (min) {
						options = scope.getSelfOptions(
							min,
							(min - (min / 4)) / 4,
							scope.getScale(min)
						);
					}
				} else {
					var scale;

					if (min) {
						scale = scope.getScale(min);
						options = scope.getMaxOptions(min, (scale * 10) / 5, scale);
					} else if (max) {
						scale = scope.getScale(max);
						options = scope.getSelfOptions(max, (scale * 10) / 5, scale);
					}
				}

				$.app[conf.app].$merge(el.dataset.type, {
					bound: el.dataset.bound,
					options: options
				});
			},
			blur: function(e, el) {
				if (! el.value) {
					scope.selectValue(el, '');
				}
			},
			keydown: function(e) {
				var key = e.keyCode;
				cancel = true;

				if (key === 40 || key === 38) {
					// Arrow up or down
					cancel = false;

					e.preventDefault();
				} else if (key === 9 || key === 13) {
					// Tab or enter
					if (scope.$activeValue) {
						scope.selectValue(scope.$activeValue[0]);

						e.preventDefault();
					} else if (key === 13) {
						scope.conf.submit();
					}
				} else {
					scope.$activeValue = false;
				}
			},
			keyup: function(e, el) {
				if (! cancel) {
					scope.processRange(el, e.keyCode);
				}
			}
		}, {
			namespace: conf.namespace
		});
	},

	/**
	 * Get minimum filter options
	 *
	 * @param {Number} spread
	 * @param {Number} scale
	 * @returns {Array}
	 */
	getMinOptions: function(spread, scale) {
		var options = [0],
			i = 1;

		for (; i <= 5; i++) {
			options.push(
				Math.round((spread * i) / scale) * scale
			);
		}

		return $.unique(options);
	},

	/**
	 * Get maximum filter options
	 *
	 * @param {Number} val
	 * @param {Number} spread
	 * @param {Number} scale
	 * @returns {Array}
	 */
	getMaxOptions: function(val, spread, scale) {
		var options = [],
			i = 1;

		for (; i <= 6; i++) {
			options.push(
				Math.round((val + (spread * i)) / scale) * scale
			);
		}

		return $.unique(options);
	},

	/**
	 * Get values for current input
	 *
	 * @param {Number} val
	 * @param {Number} spread
	 * @param {Number} scale
	 * @returns {Array}
	 */
	getSelfOptions: function(val, spread, scale) {
		var options = [],
			i = -2;

		for (; i < 4; i++) {
			options.push(
				Math.round((val + (spread * i)) / scale) * scale
			);
		}

		return $.unique(options);
	},

	/**
	 * Calculate range scale
	 *
	 * @param {Number} val
	 * @returns {Number}
	 */
	getScale: function(val) {
		var steps = [
			200000, 50000, 20000, 10000, 5000, 2500, 1000,
			500, 250, 100, 50, 25, 10, 5, 2, 1
		],
			i = 0;

		for (; i < steps.length; i++) {
			var step = steps[i];

			if (val > step * 5) {
				return step;
			}
		}

		return 0.5;
	},

	/**
	 * Bind filter option events
	 */
	bindOption: function() {
		var scope = this,
			conf = scope.conf;

		$.events.on('$filterValue', 'mousedown', function(e, el) {
			scope.selectValue(el);
		}, {
			delegate: conf.context,
			namespace: conf.namespace
		});
	},

	/**
	 * Make range option selection
	 *
	 * @param {HTMLElement} el
	 * @param {String} [val]
	 */
	selectValue: function(el, val) {
		var scope = this,
			type = el.dataset.type,
			bound = el.dataset.bound;

		if (val === '' && ! el.dataset.value) {
			return;
		}

		$.app[scope.conf.app].$set(
			type + '.' + bound, el.dataset.value
		);

		if (bound === 'min') {
			$('[data-type="' + type + '"][data-bound="max"]', scope.conf.context).focus();
		} else if (scope.conf.collapse) {
			$.app[scope.conf.app].$drop('active');
		}

		scope.$activeValue = false;
	},

	/**
	 * Process the keyup event for evaluation
	 *
	 * @param {HTMLElement} el
	 * @param {Number} key
	 */
	processRange: function(el, key) {
		var scope = this,
			activeClass = '-is-active';

		if (scope.$activeValue) {
			scope.$activeValue.removeClass(activeClass);

			scope.$activeValue = key === 40 ?
				scope.$activeValue.next() :
				scope.$activeValue.prev();
		} else {
			scope.$entries = $$('filterValue', $(el).closest('$filterContent'));

			if (scope.$entries.length) {
				scope.$activeValue = key === 40 ?
					scope.$entries.first() :
					scope.$entries.last();
			} else {
				scope.$activeValue = false;
			}
		}

		if (scope.$activeValue.length) {
			scope.$activeValue.addClass(activeClass);
		} else {
			scope.$activeValue = false;
		}
	}
});