(function(L, E) {
	'use strict';

	L.$chain({
		/**
		 * Bind event(s) to selection
		 *
		 * @param {(Object|String)} a - event name or object of events
		 * @param {(Function|Object)} [b] - event callback or options object
		 * @param {(Object|String)} [c] - event options
		 * @param {Array} [c.args] - callback arguments
		 * @param {(HTMLElement|String)} [c.context=document]
		 * @param {(HTMLElement|String)} [c.delegate]
		 * @param {Boolean} [c.once=false] - remove event after first execution
		 * @param {Object} [c.scope]
		 * @returns {$} selection
		 */
		on: function(a, b, c) {
			E.on(this, a, b, c);

			return this;
		},

		/**
		 * Remove event(s) from selection
		 *
		 * @param {(Object|String)} a - event name or object of events
		 * @param {Function} [b] - specific function to remove
		 * @returns {$} selection
		 */
		off: function(a, b) {
			E.off(this, a, b);

			return this;
		},

		/**
		 * Get currently bound events to optional specified element and event|function
		 *
		 * @param {String} [event] - event name to match
		 * @param {Function} [fn] - specific function to match
		 * @returns {Array} matches
		 */
		bound: function(event, fn) {
			return E.bound(this, event, fn);
		},

		/**
		 * Execute bound event for each matching selection
		 *
		 * @param {String} name
		 * @param {*} [args]
		 * @returns {$} selection
		 */
		trigger: function(name, args) {
			E.trigger(this, name, args);

			return this;
		}
	});
})(Apt, Apt.events);