(function(L) {
	'use strict';

	L.$chain({
		/**
		 * Bind element events to History
		 *
		 * @param {(Boolean|Object)} [events]
		 * @param {Object} [a] - settings
		 * @returns {$} selection
		 */
		bind: function(events, a) {
			L.history.bind(events, a || this.toArray(), this);

			return this;
		},

		/**
		 * Unbind element events from History
		 *
		 * @returns {$} selection
		 */
		unbind: function() {
			L.history.unbind(this);

			return this;
		}
	});
})(Apt);