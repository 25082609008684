LS.header = {
	/**
	 * Initialize resize logic
	 */
	init: function() {
		var scope = this;

		$.screen.map([
			{
				max: 4,
				once: true,
				callback: function() {
					scope.$pull = $$('headerPull');

					scope.$pull.on('mousedown', function() {
						scope.visible ?
							scope.hide() :
							scope.show();

						if (scope.bound) {
							return;
						}

						scope.bound = true;

						$(document.body).on('click', function(e) {
							if (! scope.visible) {
								return;
							}

							if (
								e.target.tagName === 'A' ||
								! $(e.target).closest('$header').length
							) {
								scope.hide();
							}
						});
					});
				}
			},
			{
				min: 5,
				init: false,
				callback: function() {
					scope.hide();
				}
			}
		]);
	},

	/**
	 * Open menu
	 */
	show: function() {
		var scope = this,
			pull = scope.$pull[0];

		scope.visible = true;

		document.body.classList.add('-is-opened');

		LS.util.crop();

		pull.setAttribute('aria-expanded', true);
		pull.setAttribute('aria-label', 'Close menu');
	},

	/**
	 * Close menu
	 */
	hide: function() {
		var scope = this,
			pull;

		if (! scope.visible) {
			return;
		}

		pull = scope.$pull[0];

		scope.visible = false;

		document.body.classList.remove('-is-opened');

		LS.util.uncrop();

		pull.setAttribute('aria-expanded', false);
		pull.setAttribute('aria-label', 'Open menu');
	},

	/**
	 * Initialize header location
	 */
	locate: function() {
		var scope = this,
			$form = $$('headerSearch'),
			cropped;

		scope.locator = Apt.fn.locator()
			.init('$headerLocator', {
				value: $.context('location'),
				default: location.pathname === '/' ?
					false : (! LS.filters.isProperty() || LS.filters.isEntry()),
				warm: true,
				address: true,
				fetch: true,
				opened: function() {
					if ($.screen.size() < 3) {
						cropped = true;

						LS.util.crop();

						setTimeout(function() {
							if (cropped && ! $form.visible()) {
								document.documentElement.scrollTop = 0;
							}
						}, 300);
					}
				},
				closed: function() {
					if (cropped) {
						cropped = false;

						LS.util.uncrop();
					}
				},
				changed: function() {
					$form.trigger('submit');
				}
			});

		$form.on('submit', function() {
			var filters = Apt.properties.$private.filters;

			if (filters) {
				filters.submit();

				return;
			}

			scope.search(scope.locator);
		}, {
			cancel: true
		});
	},

	/**
	 * Perform location search
	 *
	 * @param {Object} locator
	 */
	search: function(locator) {
		var value = locator.val(),
			path = '/properties';

		if (value) {
			if (value.type === 'address') {
				path += '/filter/center=' + value.slug + ',zoom=14';
			} else {
				path += '/' + value.slug;
			}
		} else if (locator.text()) {
			LS.util.warn('Select a location result to search');

			return;
		}

		$.history.go(path);
	}
};