Apt.fn.extend('filters', {
	/**
	 * Submit form data
	 */
	submit: function() {
		var scope = this,
			priv = scope.$private,
			data = priv.serialize(),
			diff;

		priv.hideFilters();

		if ($.equals(scope.current, data) || ! LS.form.validate('$filters')) {
			return;
		}

		diff = $.diff(scope.current, data);

		if (! diff.location || diff.location.type === '-') {
			var filters = LS.filters.get();

			$.extend(data, LS.util.sanitize({
				bounds: filters.bounds,
				center: data.center || filters.center,
				geometry: filters.geometry,
				sync: filters.sync,
				zoom: data.zoom || filters.zoom
			}));
		}

		$.history.go({
			path: LS.filters.build('properties', data)
		});
	},

	/**
	 * Reset filters
	 *
	 * @param {(Array|String)} [targets]
	 */
	reset: function(targets) {
		var scope = this,
			sync = Apt.properties.$private.sync;

		if (targets) {
			$.toArray(targets)
				.forEach(function(target) {
					$.app.filters.$drop(target);
				});
		} else {
			var model = $.copy(scope.$private.model),
				sort = LS.filters.get('sort');

			if (sort) {
				model.sort = sort;
			}

			$.app.filters.$set(model);
		}

		if (sync) {
			sync.disable(false);
		}

		scope.submit();
	}
}, {
	/**
	 * Bind button events
	 */
	bindButtons: function() {
		var scope = this,
			reset = {
				price: [
					'price.min',
					'price.max'
				],
				size: [
					'size.min',
					'size.max'
				],
				more: scope.more
			};

		$$('filterApply').on('mousedown', function() {
			scope.hideFilters(true);
		}, {
			namespace: 'filters'
		});

		$.events.on('$filterReset', 'click', function(e, el) {
			scope.$public.reset(reset[el.dataset.target] || null);
		}, {
			delegate: '$filters',
			namespace: 'filters'
		});
	},

	/**
	 * Bind toggle events
	 */
	bindToggle: function() {
		var scope = this;

		$$('filterHeading').on('mousedown', function(e, el) {
			var id = el.dataset.id;

			if ($.app.filters.$get('active') === id) {
				scope.hideFilters(true);

				return;
			}

			var type = el.dataset.type;

			scope.visibleFilter = $(el.parentNode);

			$.app.filters.$set('active', id);

			if ($.screen.size() < 5) {
				LS.util.crop();
			}

			document.body.classList.add('-is-filtered');

			// Set input focus on range selections
			if (type === 'range') {
				var input = $('input', el.nextElementSibling)[0];

				requestAnimationFrame(function() {
					input.value ?
						input.select() : input.focus();
				});
			}

			// Hide active filters on ESC press
			$(document).on('keyup', function(e) {
				if (e.keyCode === 27) {
					scope.hideFilters(true);
				}
			}, {
				cancel: true,
				namespace: 'activeFilter'
			});

			// Hide active filters when clicking outside container
			var inside;

			$(document.body).on({
				mousedown: function(e) {
					inside = $(e.target).closest(scope.visibleFilter).length;
				},
				mouseup: function() {
					if (inside) {
						return;
					}

					setTimeout(function() {
						if (scope.visibleFilter) {
							scope.hideFilters(
								! Apt.panel.opened() &&
								! $.history.pending()
							);
						}
					}, 100);
				}
			}, {
				namespace: 'activeFilter'
			});
		}, {
			namespace: 'filters'
		});
	},

	/**
	 * Bind filter form events
	 */
	bindForm: function() {
		var scope = this;

		$$('filters').on('submit', function() {
			scope.$public.submit();
		}, {
			cancel: true,
			namespace: 'filters'
		});
	}
});