Apt.fn.make('meta', {
	/**
	 * Initialize module
	 *
	 * @param {$} [el]
	 * @returns {Object}
	 */
	init: function(el) {
		var scope = this,
			priv = scope.$private,
			$group = $(el || '$navGroup'),
			$more = $$('navMore', $group);

		priv.$group = $group;
		priv.$more = $more;

		if (window.crawler || ! $group.length) {
			return;
		}

		if ($more.length && $group[0].offsetHeight > 160) {
			$group[0].classList.add('-is-collapsed');
		}

		LS.util.idle(priv.bind, priv);

		return scope;
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		LS.util.reset('meta', this.$private, 'tabs');
	}
}, {
	/**
	 * Bind events
	 */
	bind: function() {
		var scope = this;

		scope.$more.show()
			.on('mousedown', function() {
				scope.$group[0].classList.remove('-is-collapsed');
			}, {
				namespace: 'meta',
				once: true
			});

		scope.tabs = Apt.fn.tab()
			.init('$navTabs');
	}
});