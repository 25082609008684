Apt.fn.make('properties.state', {
	/**
	 * Setup module
	 *
	 * @param {Object} options
	 * @returns {Object}
	 */
	init: function(options) {
		var scope = this,
			priv = scope.$private;

		priv.conf = options;
		priv.uid = LS.util.uid();

		// Merge state when user data is available
		$.observe('user', function(data) {
			if (data && data.account) {
				priv.merge();
			}
		}, {
			init: true,
			namespace: priv.uid,
			recursive: true
		});

		priv.bind();

		return scope;
	},

	/**
	 * Update state
	 */
	update: function() {
		this.$private.merge();
	},

	/**
	 * Add state
	 *
	 * @param {String} type
	 * @param {Number} id
	 * @param {Object} context
	 * @param {Object} [options]
	 * @param {Function} [options.success]
	 */
	add: function(type, id, context, options) {
		options.success = $.toArray(options.success || []);

		options.success.push(function(data, xhr, req) {
			if (type === 'saves') {
				$.set('user.account.saves.' + id, (req.data ? (req.data.collection || []) : []).map(Number));

				LS.analytics.track({
					action: 'click',
					target: 'listing',
					id: id,
					category: 'save'
				}, {
					key: context.key,
					source: context.source
				});
			} else {
				$.push('user.account.hidden', id);
			}
		});

		if (type === 'saves') {
			var collections = LS.user.get('collections');

			if (collections && collections.length) {
				LS.util.load('collect', function() {
					Apt.collect.init(id, options.success);
				});

				return;
			}
		}

		this.$private.request(type, 'put', id, options);
	},

	/**
	 * Remove state
	 *
	 * @param {String} type
	 * @param {Number} id
	 * @param {Object} [context]
	 * @param {Object} [options]
	 * @param {Function} [options.success]
	 */
	remove: function(type, id, context, options) {
		options.success = $.toArray(options.success || []);

		options.success.push(function(data, xhr, req) {
			if (type === 'saves') {
				if (req.data && req.data.collection) {
					$.set('user.account.saves.' + id, req.data.collection.map(Number));
				} else {
					$.drop('user.account.saves.' + id);
				}
			} else {
				$.drop('user.account.hidden', id);
			}
		});

		options.error = null;

		if (type === 'saves') {
			var collections = LS.user.get('saves.' + id) || [];

			if (collections.length) {
				LS.util.load('collect', function() {
					Apt.collect.init(id, options.success, collections);
				});

				return;
			}
		}

		this.$private.request(type, 'delete', id, options);
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		LS.util.reset(this.$private.uid, null, null, true);
	}
}, {
	/**
	 * Apply state
	 */
	merge: function() {
		var conf = this.conf,
			app = $.app[conf.app],
			types = {
				saves: 'save',
				hidden: 'hide'
			},
			data,
			results;

		if (! app) {
			return;
		}

		data = LS.user.get();
		results = app.$get('results');

		if (! data || ! results) {
			return;
		}

		Object.keys(types).forEach(function(type) {
			if (! data[type]) {
				return;
			}

			Object.keys(results).forEach(function(index) {
				var id = results[index].id,
					exists = type === 'saves' ?
						data[type][id] : data[type].indexOf(id) > -1;

				if (exists) {
					conf.events.exec($('[data-id="' + id + '"]', conf.delegate), function(app) {
						app.$set(types[type], true);
					});
				} else {
					var app = conf.events.get(id);

					if (app && app.$get([types[type]])) {
						app.$set(types[type], false);
					}
				}
			});
		});
	},

	/**
	 * Bind events
	 */
	bind: function() {
		var scope = this;

		$.events.on('$save, $hide', 'click', function(e, el) {
			LS.user.guard(function() {
				var $preview = $(el).closest('$property'),
					id = $preview.data('id'),
					type = el.classList.contains('$save') ?
						'saves' : 'hidden',
					data = LS.user.get(type),
					action = Boolean(data && (type === 'saves' ?
						data[id] : data.indexOf(id) > -1
					)) ? 'remove' : 'add';

				scope.$public[action](type, id, $preview.data('context'), {
					success: function() {
						scope.conf.events.exec($preview, function(app) {
							app.$set(type === 'saves' ? 'save' : 'hide', action === 'add');
						});
					}
				});
			});
		}, {
			delegate: scope.conf.delegate,
			namespace: scope.uid
		});
	},

	/**
	 * Make state request to API
	 *
	 * @param {String} type
	 * @param {String} method
	 * @param {Number} id
	 * @param {Object} [options]
	 * @param {Function} [options.error]
	 * @param {Function} [options.success]
	 */
	request: function(type, method, id, options) {
		options = $.extend({
			auth: true,
			method: method
		}, options);

		options.namespace = this.uid;

		$.fetch.abort(this.uid);

		LS.api.request('me/' + type + '/' + id, options);
	}
});