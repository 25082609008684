var Apt;

(function(L, U) {
	'use strict';

	var web = typeof window !== 'undefined',
		scope = web ? // eslint-disable-line
			window : global,
		L = (function() { // eslint-disable-line
			var D = web ? document : {},
				store = {
					$: {}
				},
				observe = {
					$: {}
				},
				features = {},
				exts = {},
				env,
				range,

				/**
				 * Determine data storage root, key, and value
				 *
				 * @private
				 * @param {Object} obj
				 * @param {(Number|String)} key
				 * @param {Boolean} [create=false]
				 * @returns {Array} data
				 */
				_storage = function(obj, key, create) {
					var data = obj,
						output,
						criteria;

					if (typeof key === 'object') {
						if (Array.isArray(key)) {
							criteria = key[1];
							key = key[0];
						} else {
							criteria = key;
							key = U;
						}

						if (criteria) {
							var match;
							data = data.$;

							Object.keys(criteria).forEach(function(rec) {
								var segs = rec.toString().split('.'),
									prop = segs.splice(-1)[0],
									i = 0;

								segs.forEach(function(key) {
									data = data.hasOwnProperty(key) ?
										data[key] :
										(create ? data[key] = {} : []);
								});

								for (var val in data) {
									var el = data[val];

									if (el[prop] === criteria[rec]) {
										match = true;

										if (key === U) {
											key = i;
										} else {
											data = el;
										}

										break;
									}

									i++;
								}
							});

							if (! match) {
								data = U;
							}
						}
					}

					var num = typeof key === 'number';

					if (num || typeof key === 'string') {
						var segs = key.toString().split('.');
						key = segs.pop();

						if (! criteria) {
							data = data.$;
						}

						segs.forEach(function(key) {
							data = data.hasOwnProperty(key) ?
								data[key] :
								(create ? data[key] = {} : []);
						});
					} else {
						key = '$';
					}

					if (num && Array.isArray(data)) {
						var arr = data.slice(key);

						if (arr.length) {
							output = arr[0];
						}
					} else {
						key = key.toString();

						if (key === '*') {
							output = data;
						} else if (data && data.hasOwnProperty(key)) {
							output = data[key];
						}
					}

					return [data, key, output];
				},

				/**
				 * Set global variable
				 *
				 * @private
				 */
				_set = function(obj, obs, key, val, options) {
					options = options || {};

					var stored = _storage(obj, val == U ? U : key, true),
						seg = stored[1],
						data = seg === '$' ?
							_val(key, val) :
							_val(val, options);

					if (stored[0] === U) {
						return U;
					}

					stored[0][seg] = data;

					if (options.trigger !== false) {
						_trigger(obj, obs, key, _copy(stored[2]), data, 'set');
					}

					return data;
				},

				/**
				 * Get global variable
				 *
				 * @private
				 */
				_get = function(obj, obs, key, fallback, set, options) {
					if (key === '*') {
						key = null;
					}

					var resp = _storage(obj, key)[2];

					if (resp != U) { // eslint-disable-line
						return resp;
					}

					if (fallback !== U) {
						return set ?
							_set(obj, obs, key, fallback, options) :
							_val(fallback, options);
					}

					return null;
				},

				/**
				 * Check if storage criteria is set
				 *
				 * @private
				 */
				_has = function(obj, key, val) {
					var resp = _storage(obj, key)[2];

					if (resp === U) {
						return false;
					}

					if (val !== U) {
						if (L.$isObject(resp)) {
							return resp.hasOwnProperty(val);
						}

						if (Array.isArray(resp)) {
							return resp.indexOf(val) > -1;
						}

						return resp === val;
					}

					return true;
				},

				/**
				 * Push or concatenate values into global array
				 *
				 * @private
				 */
				_add = function(type, obj, obs, key, val, prepend) {
					var stored = _storage(obj, val == U ? U : key, true),
						root = stored[0],
						seg = stored[1],
						orig = _copy(stored[2]);

					if (seg === '$') {
						prepend = val;
						val = key;
					}

					if (! Array.isArray(orig)) {
						root[seg] = [];
					}

					if (type === 1) {
						root[seg] = prepend ?
							L.$toArray(val).concat(root[seg]) :
							root[seg].concat(val);
					} else {
						prepend ?
							root[seg].unshift(val) :
							root[seg].push(val);
					}

					_trigger(obj, obs, key, orig, root[seg],
						type === 1 ? 'concat' : 'push');

					return root[seg];
				},

				/**
				 * Extend object into into global storage
				 *
				 * @private
				 */
				_merge = function(obj, obs, key, val) {
					var und = val === U,
						delta = und ? key : val,
						orig = und ? obj.$ : _get(obj, obs, key, Array.isArray(delta) ? [] : {}),
						merged = L.$extend(true, Array.isArray(orig) ? [] : {}, orig, delta);

					und ? key = merged : val = merged;

					return _set(obj, obs, key, val);
				},

				/**
				 * Remove key or value from global array
				 *
				 * @private
				 */
				_drop = function(obj, obs, key, val) {
					var stored = _storage(obj, key),
						root = stored[0],
						seg = stored[1],
						orig = _copy(stored[2]);

					if (orig !== U) {
						if (val !== U) {
							if (Array.isArray(orig)) {
								var i = orig.indexOf(val);

								if (i > -1) {
									root[seg].splice(i, 1);
								}
							} else {
								orig === val ?
									delete root[seg] :
									delete root[seg][val];
							}
						} else {
							Array.isArray(root) ?
								root.splice(seg, 1) :
								delete root[seg];
						}
					}

					_trigger(obj, obs, key, orig, root[seg], 'drop');

					return val !== U ? root[seg] : root;
				},

				/**
				 * Attach callback to data storage change
				 *
				 * @private
				 */
				_observe = function(store, obs, key, fn, options) {
					options = options || {};
					options.fn = fn;

					obs.$[key] = obs.$[key] || [];
					obs.$[key].push(options);

					if (options.init) {
						var val = _get(store, false, key);

						if (val) {
							fn(_get(store, false, key), {
								init: true
							});
						}
					}
				},

				/**
				 * Remove callback from data storage change
				 */
				_unobserve = function(obs, key, options) {
					if (options && options.namespace) {
						(key === '*' ? Object.keys(obs.$) : [key]).forEach(function(key) {
							var arr = obs.$[key];

							if (arr && arr.length) {
								obs.$[key] = arr.filter(function(el) {
									return el.namespace !== options.namespace;
								});
							}
						});
					} else {
						key === U ?
							obs.$ = {} :
							delete obs.$[key];
					}
				},

				/**
				 * Fire callback from data storage change
				 *
				 * @private
				 */
				_trigger = function(obj, obs, key, orig, upd, type) {
					if (! Object.keys(obs.$).length || (
						type === 'trigger' && _equals(upd, orig)
					)) {
						return;
					}

					if (typeof key !== 'string') {
						key = '';
					}

					var arr = [],
						opts = key.split('.').map(function(seg) {
							arr.push(seg);

							return arr.join('.');
						});

					for (var val in obs.$) {
						if (opts.indexOf(val) === -1 && val !== '*') {
							continue;
						}

						var wildcard = val === '*',
							data = wildcard ?
								obj.$ : upd;

						obs.$[val].forEach(function(el) {
							if (
								(val !== key && ! wildcard && ! el.recursive) ||
								(el.value && _equals(el.value, data))
							) {
								return;
							}

							var args = [
								el.recursive ?
									_get(obj, false, val) :
									data,
								type
							];

							if (el.diff) {
								args.push(_diff(orig, data));
							}

							L.$exec(el.fn, {
								args: args.concat(el.args),
								scope: el.scope
							});

							if (el.once) {
								_unobserve(obs, wildcard ? '*' : key, el);
							}
						});
					}
				},

				/**
				 * Clone value to a new instance
				 *
				 * @private
				 * @param {*} val
				 * @returns {*}
				 */
				_copy = function(val) {
					if (L.$isObject(val)) {
						return _extend({}, val, true);
					}

					if (Array.isArray(val)) {
						return val.slice(0);
					}

					return val;
				},

				/**
				 * Extend target object with source object(s)
				 *
				 * @private
				 * @param {Object} target
				 * @param {Object} object
				 * @param {Boolean} [deep=false]
				 * @param {Array} [_set=[]]
				 * @returns {Object}
				 */
				_extend = function(target, object, deep, _set) {
					if (! object) {
						return target;
					}

					_set = _set || [];

					if (! deep && Object.assign) {
						return Object.assign(target, object);
					}

					for (var key in object) {
						var src = object[key],
							type = L.$type(src);

						if (deep && type === 'object') {
							var len = _set.length,
								i = 0,
								val;

							for (; i < len; i++) {
								if (_set[i] === src) {
									val = src;

									break;
								}
							}

							if (val) {
								target[key] = val;
							} else {
								_set.push(src);
								target[key] = _extend(target[key] || {}, src, deep, _set);
							}
						} else if (src !== U) {
							target[key] = type === 'array' ?
								src.slice(0) : src;
						}
					}

					return target;
				},

				/**
				 * Compare two values for equality
				 *
				 * @private
				 * @param {*} a
				 * @param {*} b
				 * @returns {Boolean}
				 */
				_equals = function(a, b) {
					if (a === b) {
						return true;
					}

					var aType = L.$type(a);

					if (aType !== L.$type(b)) {
						return false;
					}

					if (aType === 'array') {
						return _arrEquals(a, b);
					}

					if (aType === 'object') {
						return _objEquals(a, b);
					}

					if (aType === 'date') {
						return +a == +b; // eslint-disable-line
					}

					return false;
				},

				/**
				 * Compare two arrays for equality
				 *
				 * @private
				 * @param {Array} a
				 * @param {Array} b
				 * @returns {Boolean}
				 */
				_arrEquals = function(a, b) {
					return a.length === b.length &&
						a.every(function(el, i) {
							return _equals(el, b[i]);
						});
				},

				/**
				 * Compare two objects for equality
				 *
				 * @private
				 * @param {Object} a
				 * @param {Object} b
				 * @returns {Boolean}
				 */
				_objEquals = function(a, b) {
					var aKeys = Object.keys(a);

					return _arrEquals(aKeys.sort(), Object.keys(b).sort()) &&
						aKeys.every(function(i) {
							return _equals(a[i], b[i]);
						});
				},

				/**
				 * Generate a delta from two objects
				 *
				 * @private
				 * @param {Object} a - original object
				 * @param {Object} b - updated object
				 * @returns {Object}
				 */
				_diff = function(a, b) {
					var aObj = L.$isObject(a),
						bObj = L.$isObject(b);

					if (! (aObj || bObj)) {
						var type = 'u';

						if (_equals(a, b)) {
							type = '-';
						} else if (a === U) {
							type = 'c';
						} else if (b === U) {
							type = 'd';
						}

						return {
							after: b,
							before: a,
							type: type
						};
					}

					var diff = {};

					if (aObj) {
						Object.keys(a).forEach(function(key) {
							diff[key] = _diff(
								a[key],
								b && bObj ? b[key] : U
							);
						});
					}

					if (bObj) {
						Object.keys(b).forEach(function(key) {
							if (! diff[key]) {
								diff[key] = _diff(U, b[key]);
							}
						});
					}

					return diff;
				},

				/**
				 * Get value from function or directly
				 *
				 * @private
				 * @param {*} val
				 * @param {Object} [options]
				 * @returns {*}
				 */
				_val = function(val, options) {
					return L._canExec(val) ?
						L.$exec(val, options) :
						val;
				},

				/**
				 * Serialize object
				 *
				 * @param {Object} obj
				 * @param {String} [prefix]
				 * @returns {String}
				 */
				_serialize = function(obj, prefix) {
					var arr = [],
						encode = encodeURIComponent;

					Object.keys(obj || {}).forEach(function(key) {
						var param = prefix ?
								prefix + '[' + (Array.isArray(obj) ? '' : encode(key)) + ']' :
								encode(key),
							val = obj[key];

						arr.push(
							val !== null && typeof val === 'object' ?
								_serialize(val, param) :
								param + '=' + encode(val)
						);
					});

					return arr.join('&')
						.replace(/%20/g, '+');
				},

				_supported = {
					/**
					 * Detect support for passive event listeners
					 */
					passiveEvents: function() {
						try {
							var opts = Object.defineProperty({}, 'passive', {
								get: function() {
									features.passiveEvents = true;
								}
							});

							L._win.addEventListener('_s', null, opts);
							L._win.removeEventListener('_s', null, opts);
						} catch (e) {
							features.passiveEvents = false;
						}
					},

					/**
					 * Detect touch support
					 *
					 * @param {Object} options
					 */
					touch: function(options) {
						var cb = function() {
							L._html.removeEventListener('touchstart', cb);

							features.touch = true;

							if (options && options.callback) {
								options.callback();
							}
						};

						L._html.addEventListener('touchstart', cb, L.$supported('passiveEvents') ? {
							passive: true
						} : false);
					}
				};

			return {
				_$: '$',
				_$$: '$$',
				_body: D.body,
				_doc: D,
				_html: D.documentElement,
				_loc: scope.location,
				_win: scope,

				fn: {
					/**
					 * Create a namespaced controller
					 *
					 * @param {String} name
					 * @param {Object} pub - public methods and properties
					 * @param {Object} [priv] - private methods and properties
					 * @param {Object} [options] - configuration options
					 */
					make: function(name, pub, priv, options) {
						options = options || {};

						var args = options.args || {},
							instance = options.instance !== false;

						// Create new controller instance
						L.fn[name] = L._make(name, pub, priv);
						exts[name] = [];

						if (instance) {
							args.name = name;
							L[name] = new L.fn[name](args);
						}
					},

					/**
					 * Extend controller with additional methods and properties
					 *
					 * @param {(Object|String)} a - controller name or core methods
					 * @param {Object} [b] - public methods and properties
					 * @param {Object} [c] - private methods and properties
					 * @param {Object} [d] - options
					 */
					extend: function(a, b, c, d) {
						if (L.$isObject(a)) {
							// Merge into the global object
							_extend(L, a);
						} else if (L.hasOwnProperty(a)) {
							// Merge the objects else create the controller
							if (c) {
								b.$private = c;
							}

							exts[a].push(b);

							_extend(L[a], b, true);
						} else {
							this.make(a, b, c, d);
						}
					}
				},

				/**
				 * Get matches to specified selector or return parsed HTML
				 *
				 * @param {($|HTMLElement|String)} selector
				 * @param {($|HTMLElement|String)} [context=document]
				 * @returns {Array} elements
				 */
				$: function(selector, context) {
					var el = null,
						ref = [];

					if (typeof selector !== 'string') {
						el = selector;
					} else {
						if (selector === 'window') {
							return [scope];
						}

						if (selector === 'document') {
							return [D];
						}

						// Return nothing if context doesn't exist
						context = context !== U ?
							L.$(context)[0] : D;

						if (! context) {
							return ref;
						}

						// Check for references
						if (selector.indexOf('$') > -1) {
							var split = selector.split(',')
								.filter(function(sel) {
									sel = sel.trim();

									if (sel[0] === '$') {
										ref = ref.concat(L.$('.$' + sel.slice(1), context));

										return false;
									}

									return true;
								});

							if (! split.length) {
								return ref;
							}

							selector = split.join(',');
						}

						if (/^[#.]?[\\$\w-]+$/.test(selector)) {
							var pre = selector[0];

							if (pre === '#') {
								el = D.getElementById(selector.slice(1));
							} else if (pre === '.') {
								el = context.getElementsByClassName(selector.slice(1));
							} else {
								el = context.getElementsByTagName(selector);
							}
						} else {
							try {
								el = context.querySelectorAll(selector);
							} catch (e) {
								el = L.$parseHTML(selector).childNodes;

								if (
									el.length === 1 &&
									el[0].nodeType !== 1
								) {
									el = U;
								}
							}
						}
					}

					if (! el) {
						el = ref;
					} else if (
						el.nodeType !== U ||
						el === scope
					) {
						el = [el];
					} else {
						el = [].slice.call(el);
					}

					// Join references if available
					return ref.length ?
						el.concat(ref) : el;
				},

				/**
				 * Create document fragment from an HTML string
				 *
				 * @param {String} html
				 * @returns {DocumentFragment} element
				 */
				$parseHTML: function(html) {
					if (! range) {
						range = D.createRange();

						range.selectNode(L._body);
					}

					return range.createContextualFragment(html.trim());
				},

				/**
				 * Set global variable
				 *
				 * @param {String} key
				 * @param {*} val
				 * @param {Object} [options] - applicable if value is a callback
				 * @param {Array} [options.args]
				 * @param {Object} [options.scope]
				 * @param {Boolean} [options.trigger=true]
				 * @returns {*} value
				 */
				$set: function(key, val, options) {
					return _set(store, observe, key, val, options);
				},

				/**
				 * Get global variable
				 *
				 * @param {String} key
				 * @param {*} [fallback]
				 * @param {Boolean} [set=false]
				 * @param {Object} [options] - available for fallback functions
				 * @param {Array} [options.args]
				 * @param {Object} [options.scope]
				 * @returns {*} value
				 */
				$get: function(key, fallback, set, options) {
					return _get(store, observe, key, fallback, set, options);
				},

				/**
				 * Push value into global array
				 *
				 * @param {String} key
				 * @param {*} val
				 * @param {Boolean} [prepend=false]
				 * @returns {Array|Object} value
				 */
				$push: function(key, val, prepend) {
					return _add(2, store, observe, key, val, prepend);
				},

				/**
				 * Concatenate values into global storage
				 *
				 * @param {String} key
				 * @param {*} val
				 * @param {Boolean} [prepend=false]
				 * @returns {Array|Object} value
				 */
				$concat: function(key, val, prepend) {
					return _add(1, store, observe, key, val, prepend);
				},

				/**
				 * Extend object into global storage
				 *
				 * @param {String} key
				 * @param {Object} obj
				 * @returns {Object} value
				 */
				$merge: function(key, obj) {
					return _merge(store, observe, key, obj);
				},

				/**
				 * Check if storage criteria is set
				 *
				 * @param {String} key
				 * @param {*} [val]
				 * @returns {Boolean}
				 */
				$has: function(key, val) {
					return _has(store, key, val);
				},

				/**
				 * Remove key or value from global array
				 *
				 * @param {String} key
				 * @param {*} [val]
				 * @returns {Array|Object} value
				 */
				$drop: function(key, val) {
					return _drop(store, observe, key, val);
				},

				/**
				 * Attach callback to data storage change
				 *
				 * @param {String} key
				 * @param {Function} fn
				 * @param {Object} [options]
				 * @param {Boolean} [options.diff=false]
				 * @param {Boolean} [options.init=false]
				 * @param {String} [options.namespace]
				 * @param {Boolean} [options.once=false]
				 * @param {Boolean} [options.recursive=false]
				 * @param {*} [options.value]
				 */
				$observe: function(key, fn, options) {
					_observe(store, observe, key, fn, options);
				},

				/**
				 * Remove callback from data storage change
				 *
				 * @param {String} [key]
				 * @param {Object} [options]
				 * @param {String} [options.namespace]
				 */
				$unobserve: function(key, options) {
					_unobserve(observe, key, options);
				},

				/**
				 * Execute matching observed callbacks
				 *
				 * @param {String} key
				 */
				$trigger: function(key) {
					var val = _get(store, false, key);

					_trigger(store, observe, key, val, val, 'trigger');
				},

				/**
				 * Execute function for each matching selection
				 *
				 * @param {($|Array|HTMLElement|String)} target
				 * @param {Function} fn
				 * @param {Object} [options]
				 * @param {Array} [options.args]
				 * @param {($|HTMLElement|String)} [options.context=document]
				 * @param {Boolean} [options.reverse=false]
				 * @param {Boolean} [options.unique=false]
				 * @param {Object} [options.scope]
				 */
				$each: function(target, fn, options) {
					if (target) {
						var conf = _extend({
								args: []
							}, options),
							els = L._selArray(target, conf),
							i = 0;

						if (conf.unique) {
							els = L.$unique(els);
						}

						if (conf.reverse && ! els._$) {
							els = els.reverse();
						}

						for (; i < els.length; i++) {
							var el = els[i],
								val = L.$exec(fn, {
									args: [el, i].concat(conf.args),
									scope: conf.scope || el
								});

							if (val === false) {
								return;
							}
						}
					}
				},

				/**
				 * Get current environment or set current environment against specified object
				 *
				 * @param {Object} [rules]
				 * @param {String} [fallback=local]
				 * @returns {String} environment
				 */
				$env: function(rules, fallback) {
					var fb = fallback || 'local';

					if (rules) {
						var host = L._loc.hostname;

						for (var rule in rules) {
							var val = rules[rule];

							if (val === host || _val(val, {
								args: host
							}) === true) {
								env = rule;

								break;
							}
						}

						if (! env) {
							env = fb;
						}
					}

					return env || fb;
				},

				/**
				 * Execute specified function or controller method
				 *
				 * @param {(Array|Function)} fn
				 * @param {Object} [options]
				 * @param {Array} [options.args]
				 * @param {Object} [options.scope]
				 * @returns {*} [response]
				 */
				$exec: function(fn, options) {
					var fns = L.$toArray(fn),
						len = fns.length,
						i = 0;

					for (; i < len; i++) {
						var conf = _extend({
							args: []
						}, options || {});
						fn = fns[i];

						if (typeof fn === 'string') {
							var segs = fn.split(':');

							if (L[segs[0]]) {
								fn = L[segs[0]][
									segs.length > 1 ?
										segs[1] :
										'init'
								];

								if (! conf.scope) {
									conf.scope = L[segs[0]];
								}
							}
						}

						if (typeof fn === 'function') {
							var response = fn.apply(
								conf.scope,
								L.$toArray(conf.args)
							);

							if (len === 1) {
								return response;
							}
						}
					}
				},

				/**
				 * Clone value to a new instance
				 *
				 * @private
				 * @param {*} val
				 * @returns {*}
				 */
				$copy: function(val) {
					return _copy(val);
				},

				/**
				 * Extend target object with source object(s)
				 *
				 * @param {(Boolean|Object)} deep - extend nested properties else target object
				 * @param {Object} [obj] - target object
				 * @param {...Object} [obj] - merged objects
				 * @returns {Object}
				 */
				$extend: function(deep) {
					var bool = typeof deep === 'boolean',
						args = [].slice.call(arguments).slice(bool ? 1 : 0),
						target = args[0] || {};

					deep = bool ?
						deep : false;

					args.slice(1).forEach(function(source) {
						target = _extend(target, source, deep);
					});

					return target;
				},

				/**
				 * Generate a delta from two objects
				 *
				 * @param {Object} a
				 * @param {Object} b
				 * @returns {Object}
				 */
				$diff: function(a, b) {
					return _diff(a, b);
				},

				/**
				 * Compare two values for strict equality
				 *
				 * @param {*} a
				 * @param {*} b
				 * @returns {Boolean}
				 */
				$equals: function(a, b) {
					return _equals(a, b);
				},

				/**
				 * Determine if value is an object
				 *
				 * @param {*} obj
				 * @returns {Boolean}
				 */
				$isObject: function(obj) {
					return obj && typeof obj === 'object' && ! Array.isArray(obj);
				},

				/**
				 * Translate items in an array or selection to new array
				 *
				 * @param {($|Array|HTMLElement|String)} target - array or selector
				 * @param {Function} fn
				 * @param {Object} [options]
				 * @param {Array} [options.args]
				 * @param {Object} [options.scope]
				 * @returns {Array}
				 */
				$map: function(target, fn, options) {
					if (! Array.isArray(target)) {
						target = L._selArray(target, options);
					}

					var conf = _extend({
							args: []
						}, options),
						res = [],
						i = 0;

					for (; i < target.length; i++) {
						var el = target[i],
							val = L.$exec(fn, {
								args: [el, i].concat(conf.args),
								scope: conf.scope || el
							});

						if (val !== false) {
							res.push(val);
						}
					}

					return res;
				},

				/**
				 * Serialize object
				 *
				 * @param {Object} obj
				 * @returns {String} value
				 */
				$serialize: function(obj) {
					return _serialize(obj);
				},

				/**
				 * Convert serialized string back into an object
				 *
				 * @param {String} str
				 * @returns {Object} value
				 */
				$unserialize: function(str) {
					var obj = {};

					decodeURIComponent(str)
						.replace(/^\?/, '')
						.split('&')
						.forEach(function(el) {
							var split = el.split('='),
								key = split[0],
								val = (split[1] || '').replace(/\+/g, ' ') || '';

							if (! key) {
								return;
							}

							if (obj[key]) {
								obj[key] = L.$toArray(obj[key]);
								obj[key].push(val);
							} else {
								obj[key] = val;
							}
						});

					return obj;
				},

				/**
				 * Add metadata variables to datastore
				 *
				 * @param {(HTMLElement|String)} [context=document]
				 */
				$setVar: function(context) {
					L.$each('[data-set]', function(el) {
						var key = el.getAttribute('data-set'),
							val = L._castString(el.getAttribute('data-value'));

						key.slice(-2) === '[]' ?
							_add(2, store, observe, key.slice(0, -2), val) :
							_set(store, observe, key, val);
					}, {
						context: context
					});
				},

				/**
				 * Cast value to array if it isn't one
				 *
				 * @param {*} val
				 * @returns {Array} value
				 */
				$toArray: function(val) {
					return val !== U ?
						(Array.isArray(val) ? val : [val]) : [];
				},

				/**
				 * Determine the JavaScript type of object
				 *
				 * @param {*} obj
				 * @returns {String}
				 */
				$type: function(obj) {
					return obj === U ?
						'undefined' : Object.prototype.toString.call(obj)
							.replace(/^\[object (.+)]$/, '$1')
							.toLowerCase();
				},

				/**
				 * Create new array with only unique values from source array
				 *
				 * @param {Array} array
				 * @returns {Array} unique values
				 */
				$unique: function(array) {
					return array.reverse()
						.filter(function(el, i, arr) {
							return arr.indexOf(el, i + 1) < 0;
						})
						.reverse();
				},

				/**
				 * Detect or register feature support
				 *
				 * @param {String} key
				 * @param {Function|Object} [b]
				 * @param {Function} [b.callback] - function for delayed detection
				 * @param {Object} [c]
				 * @returns {(Boolean|void)}
				 */
				$supported: function(key, b, c) {
					var val = features[key];

					if (val !== U) {
						return val;
					}

					if (typeof b === 'function') {
						_supported[key] = b;

						if (c && c.init) {
							c.set = function(val) {
								features[key] = val();
							};

							b(c);
						}

						return;
					}

					var fn = _supported[key];

					if (! fn) {
						return U;
					}

					val = fn(b);

					if (typeof val === 'boolean') {
						features[key] = val;
					}

					return val;
				},

				/**
				 * Fallback for non-existent chaining
				 */
				$chain: function() {}, // eslint-disable-line

				/**
				 * Determine if value can be executed as a function
				 *
				 * @protected
				 * @param {*} fn
				 * @returns {Boolean} is executable
				 */
				_canExec: function(fn) {
					if (typeof fn === 'string' && fn.indexOf(':') > -1) {
						var parts = fn.split(':'),
							controller = L[parts[0]];

						fn = controller ?
							controller[parts[1]] : 0;
					}

					return typeof fn === 'function';
				},

				/**
				 * Cast string to most applicable data type
				 *
				 * @protected
				 * @param {*} val
				 * @returns {*}
				 */
				_castString: function(val) {
					if (typeof val === 'string') {
						try {
							if (val === 'true' || val === 'false') {
								return val === 'true';
							}

							if (val === 'null') {
								return null;
							}

							if (parseInt(val).toString() === val) {
								return parseInt(val);
							}

							if (/^(?:{[\w\W]*}|\[[\w\W]*])$/.test(val)) {
								return JSON.parse(val);
							}
						} catch (e) {
							//
						}
					}

					return val;
				},

				/**
				 * Extend object storage with object or key -> val
				 *
				 * @protected
				 * @param {Object} obj
				 * @param {(Object|String)} a
				 * @param {*} b
				 */
				_extend: function(obj, a, b) {
					var val = a;

					if (typeof a === 'string') {
						val = [];
						val[a] = b;
					}

					_extend(obj, val);
				},

				/**
				 * Convert selection to array
				 *
				 * @protected
				 * @param {($|HTMLElement|String)} selector
				 * @param {Object} [options]
				 * @param {(HTMLElement|String)} [options.context=document]
				 * @returns {($|Array)} nodes
				 */
				_selArray: function(selector, options) {
					if (selector && selector._$) {
						return selector;
					}

					var el = typeof selector === 'string' ?
						L.$(selector, (options || {}).context) :
						selector;

					return L.$toArray(el);
				},

				/**
				 * Return a new controller method
				 *
				 * @protected
				 * @param {String} name
				 * @param {Object} pub
				 * @param {Object} [priv]
				 * @param {(Boolean|Object)} base
				 * @param {Object} [model]
				 * @returns {Function}
				 */
				_make: function(name, pub, priv, base, model) {
					return function(options) {
						options = options || {};

						var Controller = pub || {},
							Private = priv || {},
							id = options.name ?
								options.name : null;

						// Ensure the current controller is not being extended
						if (name !== '_tmp') {
							var store = {
									$: model || {}
								},
								observe = {
									$: {}
								},
								core = {
									/**
									 * Get value from controller storage
									 *
									 * @returns {*}
									 */
									$get: function(key, fallback, set, options) {
										return _get(store, observe, key, fallback, set, options);
									},

									/**
									 * Set value in controller storage
									 *
									 * @returns {*}
									 */
									$set: function(key, val, options) {
										return _set(store, observe, key, val, options);
									},

									/**
									 * Check if storage criteria is set
									 *
									 * @returns {Boolean}
									 */
									$has: function(key, val) {
										return _has(store, key, val);
									},

									/**
									 * Push value into controller storage
									 *
									 * @returns {Array}
									 */
									$push: function(key, val, prepend) {
										return _add(2, store, observe, key, val, prepend);
									},

									/**
									 * Concatenate values into controller storage
									 *
									 * @returns {Array}
									 */
									$concat: function(key, val, prepend) {
										return _add(1, store, observe, key, val, prepend);
									},

									/**
									 * Extend object into controller storage
									 *
									 * @returns {Array}
									 */
									$merge: function(key, obj) {
										return _merge(store, observe, key, obj);
									},

									/**
									 * Remove value from controller storage
									 *
									 * @returns {Array}
									 */
									$drop: function(key, val) {
										return _drop(store, observe, key, val);
									},

									/**
									 * Attach callback to data storage change
									 */
									$observe: function(key, fn, options) {
										_observe(store, observe, key, fn, options);
									},

									/**
									 * Remove callback from data storage change
									 */
									$unobserve: function(key, options) {
										_unobserve(observe, key, options);
									},

									/**
									 * Execute matching observed callbacks
									 */
									$trigger: function(key) {
										var val = _get(store, false, key);

										_trigger(store, observe, key, val, val, 'trigger');
									},

									/**
									 * Destroy current controller
									 */
									$destroy: function() {
										if (this.$private._destruct) {
											this.$private._destruct();
										}

										if (this._destruct) {
											this._destruct();
										}

										this.$public = this.$private = {};

										Controller = null;

										// If namespaced delete root property
										if (id) {
											delete L[id];
										}
									}
								};

							// Extend public and private objects with core methods
							Controller = _extend(Controller, core);
							Private = _extend(Private, core);
						}

						// Clone controller objects for instance support
						Controller = _copy(Controller);
						Private = _copy(Private);

						// Interface $public and $private
						Controller.$public = Private.$public = Controller;
						Controller.$private = Private.$private = Private;

						// Apply controller extensions
						if (exts[name]) {
							exts[name].forEach(function(ext) {
								_extend(Controller, _copy(ext), true);
							});
						}

						if (base) {
							// Extend controller methods into base
							Controller = _extend(base, Controller, true);
							Controller.$private.$public = Controller;
						}

						if (Controller.$private._construct) {
							Controller.$private._construct(options);
						}

						if (Controller._construct) {
							Controller._construct(options);
						}

						return Controller;
					};
				}
			};
		})();

	// Set data variables and bind elements
	if (web) {
		L.$setVar();
	}

	Apt = L;

	if (typeof module === 'object' && module.exports) {
		module.exports = L;
	}
})(Apt, undefined);