Apt.fn.extend('index', {}, {
	/**
	 * Calculate History scroll value
	 *
	 * @param {Object} conf
	 * @param {Object} results
	 * @returns {(Boolean|Number)}
	 */
	scrollTop: function(conf, results) {
		var top = conf.scrollTop,
			offset = conf.scrollOffset;

		if (top === false) {
			return top;
		}

		if (typeof top === 'function') {
			top = top(results);
		}

		if (top === false) {
			return false;
		}

		if (typeof top === 'number') {
			return top;
		}

		if (typeof offset === 'function') {
			offset = offset();
		}

		return LS.util.getScroll(
			typeof top === 'string' ?
				top : conf.$target,
			offset
		);
	},

	/**
	 * Calculate History scroll target
	 *
	 * @param {Object} conf
	 * @returns {$}
	 */
	scrollTarget: function(conf) {
		if (conf.scrollTarget) {
			if (typeof conf.scrollTarget === 'function') {
				return conf.scrollTarget();
			}

			return conf.scrollTarget;
		}

		return LS.util.scrollTarget();
	}
});