(function(L, D, E, H, U) {
	'use strict';

	var entries = [],
		order = [],
		index = 0,
		pending = null,
		root = '',
		settings = {},
		top,
		uri,

		/**
		 * Listen for browser navigation
		 *
		 * @private
		 */
		_listen = function() {
			try {
				H.scrollRestoration = 'manual';
			} catch (e) {
				//
			}

			L._win.addEventListener('popstate', function(e) {
				try {
					if (! e.state) {
						return;
					}

					var full = L.routes.parse().full,
						current = entries[full];

					if (! current) {
						L._loc.href = full;

						return;
					}

					if (uri.full === full) {
						return;
					}

					var pop = e.state.index < index,
						obj = L.$extend(entries[full] ? L.$copy(current) : {
							request: {
								root: ''
							}
						}, {
							browser: true,
							index: index + (pop ? -1 : 1),
							path: full,
							pop: pop,
							push: ! pop
						});

					obj.partials = entries[order[index].full].partials;

					// Restore previous scroll position
					if (e.state.top) {
						obj.scrollTop = e.state.top;
					}

					L.history.go(obj);
				} catch (e) {
					L._loc.href = full;
				}
			});
		},

		/**
		 * Process the history state of the request
		 *
		 * @private
		 * @param {Object} conf
		 * @param {Object} options
		 */
		_process = function(conf, options) {
			var dir = conf.push ? 1 : -1,
				obj = {
					args: [
						{
							dir: dir,
							path: conf.path,
							prev: uri.path,
							conf: conf
						}
					]
				};

			// Evaluate routes against updated path
			if (conf.run) {
				L.routes.run({
					event: 'pop',
					path: uri.path
				});
			}

			if (conf.action) {
				L.events.trigger(L._win, 'pop', {
					conf: conf,
					path: uri.path
				});
			}

			if (conf.run) {
				L.routes.run({
					path: conf.path
				});
			}

			if (conf.action) {
				L.events.trigger(L._win, 'push', {
					conf: conf,
					path: conf.path
				});
			}

			if (conf.push || conf.pop) {
				pending = null;

				uri = conf.parsed;

				index += dir;
			}

			if (conf.pushstate) {
				L.$exec([
					settings.pushstate,
					options.pushstate
				], obj);
			}

			if (conf.popstate) {
				L.$exec([
					settings.popstate,
					options.popstate
				], obj);
			}

			L.$exec([
				settings.end,
				options.end
			], obj);
		},

		/**
		 * Return current path
		 *
		 * @private
		 * @param {Object} [loc]
		 * @returns {String}
		 */
		_path = function(loc) {
			loc = loc || L._loc;
			return loc.pathname + loc.search + loc.hash;
		},

		/**
		 * Set current History state
		 *
		 * @param {(Object|String)} value
		 */
		_set = function(value) {
			uri = L.routes.set(value);

			try {
				H.replaceState({
					index: index,
					top: top
				}, '', uri.raw);
			} catch (e) {
				//
			}
		};

	L.history = {
		/**
		 * Set the initial state and popstate event, and bind global actions
		 *
		 * @param {Object} [options]
		 * @param {($|boolean|HTMLElement|String)} [options.bind]
		 * @param {Boolean} [options.scrollEarly=false]
		 * @param {String} [options.partials='title,main']
		 * @param {Boolean} [options.processErrors=false]
		 * @param {Boolean} [options.push=true]
		 * @param {Object} [options.request]
		 * @param {Boolean} [options.run=true]
		 * @param {Boolean} [options.useResponseURL=true]
		 */
		init: function(options) {
			var scope = this;

			if (scope.request) {
				return;
			}

			settings = L.$extend({
				partials: 'title, main',
				push: true,
				request: {},
				run: true,
				scrollTarget: L.$scrollElement(),
				scrollTop: 0
			}, options);

			root = settings.request.root || '';

			scope.request = settings.request;

			delete settings.request;

			// Set current state
			uri = L.routes.uri();
			order = [uri];

			entries[uri.full] = settings;

			_set(uri);
			_listen();

			scope.bind();
		},

		/**
		 * Determine if path is valid for history navigation
		 *
		 * @private
		 * @param {HTMLElement} el
		 * @returns {Boolean}
		 */
		validate: function(el) {
			return ! (
				! el.href ||
				el.target === '_blank' ||
				! /https?:/.test(el.href) ||
				el.hasAttribute('download') ||
				el.hasAttribute('data-static') ||
				(el.hostname && el.hostname !== L._loc.hostname) ||
				(el.hash && el.pathname === uri.path)
			);
		},

		/**
		 * Bind element events to History
		 */
		bind: function() {
			var scope = this;

			E.on('a', 'click', function(e, el) {
				if (e.metaKey) {
					return;
				}

				// Ensure the path exists and is local
				if (! scope.validate(el)) {
					return;
				}

				scope.go({
					path: _path(el)
				});

				e.preventDefault();
				e.stopPropagation();
			}, {
				delegate: L._body,
				namespace: 'history'
			});
		},

		/**
		 * Get History data at optional offset
		 *
		 * @param {(Boolean|Number)} [offset]
		 * @returns {Object}
		 */
		get: function(offset) {
			if (offset === U) {
				return order;
			}

			if (offset === true) {
				return pending ?
					pending.parsed : null;
			}

			return order[index + offset];
		},

		/**
		 * Get pending history event
		 *
		 * @returns {Object}
		 */
		pending: function() {
			return pending;
		},

		/**
		 * Add history entry
		 *
		 * @param {Object} conf
		 */
		push: function(conf) {
			var request = conf.request,
				method = request.method;

			if (typeof conf.push === 'string') {
				conf.path = conf.push;
			} else if (! method || method === 'get') {
				conf.path = D._getUrl(request);
			}

			if (conf.push || conf.pop) {
				if (conf.browser) {
					_set(conf.parsed);
				} else {
					if (! conf.bypass) {
						entries[conf.path] = conf;
					}

					H.pushState({
						index: index + (conf.push ? 1 : -1),
						top: 0
					}, '', conf.path);
				}

				// Update current path
				L.routes.set(conf.path);
				L.routes.set({
					history: true
				});

				order[index + (conf.push ? 1 : -1)] = conf.parsed;

				// Update document title
				if (conf.title) {
					L._doc.title = conf.title;
				}
			}
		},

		/**
		 * Replace current History state
		 *
		 * @param {(Object|String)} value
		 */
		replace: function(value) {
			var current = uri.full,
				target;

			_set(value);

			target = uri.full;

			entries[target] = entries[current];

			if (current !== target) {
				delete entries[current];
			}

			order[index] = uri;
		},

		/**
		 * Navigate to a new path or within the browser history
		 *
		 * @param {Object} options
		 * @param {String} [options.action='replace']
		 * @param {String} [options.partials='title,main']
		 * @param {String} [options.path=current]
		 * @param {Boolean} [options.push=true]
		 * @param {(Boolean|Object)} [options.request]
		 * @param {Boolean} [options.run=true]
		 * @param {*} [options.scrollTarget=document]
		 * @param {*} [options.scrollTop]
		 * @param {String} [options.title]
		 * @returns {*}
		 */
		go: function(options) {
			var scope = this,
				aborted = false;

			if (! scope.request) {
				scope.init();
			}

			if (typeof options === 'string') {
				options = {
					path: options
				};
			}

			var conf = L.$extend({
					action: true
				}, settings, options),
				request = conf.request || {},
				route = L.routes && conf.run;

			request.root = request.root !== U ?
				request.root : root;

			request.url = request.url !== U ?
				request.url : conf.path;

			if (! conf.force) {
				// Navigate to external URL
				var a = L._doc.createElement('a');

				a.href = request.root + request.url;

				if (! scope.validate(a)) {
					L._loc.href = request.url;

					return null;
				}
			}

			var parsed = L.routes.parse(request.url),
				prev = L.$copy(uri);

			request.url = parsed.raw;
			conf.request = request;

			// Set current scroll position
			if (conf.push || conf.pop) {
				parsed = L.routes.parse(typeof conf.push === 'string' ?
					conf.push : (conf.path || D._getUrl(request))
				);

				parsed.push = conf.push;
				parsed.pop = conf.pop;

				conf.parsed = parsed;

				conf.abort = function() {
					aborted = true;
				};

				pending = conf;
			}

			// Calculate position here to mitigate Chrome performance issue
			top = L.$scrollElement().scrollTop;

			if (top < 140) {
				top = 0;
			}

			if (conf.begin && L.$exec(conf.begin, {
				args: conf
			}) === false) {
				uri = prev;
				pending = null;

				return null;
			}

			// Evaluate preload routes against target path
			if (route) {
				L.routes.run({
					event: 'preload',
					path: request.url
				});
			}

			L.events.trigger(L._win, 'preload', {
				conf: conf,
				path: request.url
			});

			if (aborted) {
				uri = prev;
				pending = null;

				if (conf.abort) {
					L.$exec(conf.abort);
				}

				return;
			}

			if (conf.push || conf.pop) {
				_set(uri);
			}

			var req = scope.request,
				mock = conf.action === false,
				sendEvents = [],
				successEvents = [
					function() {
						scope.push(conf);
					}
				],
				errorEvents = [],
				completeEvents = [];

			// Send PJAX header
			if (! request.json) {
				request.headers = L.$extend({
					'x-pjax': true
				}, request.headers);
			}

			// Process send events
			if (request.send) {
				sendEvents.push(request.send);
			}

			if (req.send) {
				sendEvents.push(req.send);
			}

			request.send = sendEvents;

			var scrollEvent = function(data) {
				var stp = conf.scrollTop;

				if (stp === false) {
					return;
				}

				// Scroll vertically to target
				var stg = conf.scrollTarget,
					top = 0;

				if (typeof stp === 'function') {
					stp = stp(conf, data);
				}

				if (stp === false) {
					return;
				}

				if (conf.parsed && conf.parsed.hash) {
					stp = '#' + conf.parsed.hash;
				}

				if (typeof stp === 'number') {
					top = stp;
				} else {
					var el = L.$first(stp);

					if (el) {
						try {
							top = el.getBoundingClientRect().top + L._win.scrollY;
						} catch (e) {
							top = 0;
						}
					}
				}

				if (typeof stg === 'function') {
					stg = stg(conf, data);
				}

				L.$each(stg, function(el) {
					el.scrollTop = top;
				});
			};

			// Compile success events
			var replaceEvent = function(x) {
				var html = x && x.responseText ?
					x.responseText : x;

				if (conf.replace) {
					var resp = L.$exec(conf.replace, {
						args: [html, conf]
					});

					html = typeof resp === 'string' ?
						resp : html;
				}

				// Evaluate unload routes against current path
				if (route) {
					try {
						L.routes.run({
							event: 'unload',
							path: prev.path
						});

						L.events.trigger(L._win, 'unload', {
							conf: conf,
							path: prev.path
						});
					} catch (e) {
						//
					}
				}

				if (html === false) {
					return;
				}

				if (! mock && conf.partials) {
					html = L.$parseHTML('<i>' + html + '</i>').firstChild;

					conf.partials.split(',').forEach(function(partial) {
						var el = L.$(partial, html)[0],
							target = L.$(partial)[0];

						if (el && target) {
							var parent = target.parentNode;

							if (conf.action === 'append') {
								parent.appendChild(el);
							} else {
								// L.$empty(target);

								parent.replaceChild(el, target);
							}
						}
					});

					L.$setVar();
				}
			};


			if (conf.scrollEarly) {
				successEvents.push(scrollEvent);
			}

			if (! mock) {
				successEvents.push(replaceEvent);
			}

			if (! conf.scrollEarly) {
				successEvents.push(scrollEvent);
			}

			if (request.success) {
				successEvents.push(request.success);
			}

			if (req.success) {
				successEvents.push(req.success);
			}

			request.success = successEvents;

			// Compile error events
			if (request.error) {
				errorEvents.push(request.error);
			}

			if (req.error) {
				errorEvents.push(req.error);
			}

			// Optionally process error events
			if (conf.processErrors) {
				errorEvents.push(successEvents[0], replaceEvent, scrollEvent);
			}

			request.error = errorEvents;

			// Compile complete events
			if (request.complete) {
				completeEvents.push(request.complete);
			}

			if (req.complete) {
				completeEvents.push(req.complete);
			}

			if (! mock) {
				completeEvents.push(function(x) {
					var status = x.status,
						url = x.responseURL;

					if (url) {
						parsed = L.routes.parse(url);
						conf.path = parsed.full;
					}

					if (conf.processErrors || (
						status >= 200 &&
						status < 400
					)) {
						_process(conf, options);
					}
				});
			}

			request.complete = completeEvents;

			// Make Ajax request
			request.args = request.args || [];

			if (mock) {
				L.$exec(sendEvents.concat(
					[successEvents[0]],
					completeEvents
				));

				_process(conf, options);
			} else {
				return D.request(request);
			}

			return null;
		}
	};
})(Apt, Apt.fetch, Apt.events, history, undefined);