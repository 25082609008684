Apt.fn.make('properties.sync', {
	/**
	 * Initialize property syncing module
	 *
	 * @param {Object} options
	 * @returns {Object}
	 */
	init: function(options) {
		var scope = this,
			priv = scope.$private,
			map;

		priv.conf = $.extend({
			controls: true,
			minZoom: 7,
			push: true
		}, options);

		priv.states = {
			sync: 'Search here',
			enable: 'Search as map moves',
			disable: 'Disable map search'
		};

		map = priv.conf.map;

		map.ready(function() {
			priv.bounds = map.getBounds();
			priv.syncable = map.getZoom() >= priv.conf.minZoom;

			if (priv.syncable) {
				$$('syncSearch').show();
			}

			if (scope.check() && priv.syncable) {
				scope.enable();
			} else {
				priv.toggle();
			}

			priv.bindScreen();

			map.debounce('move', function() {
				priv.sync();
			});
		});

		return scope;
	},

	/**
	 * Determine if syncing is enabled
	 *
	 * @param {Boolean} [active=false] - ensure syncing is currently active
	 * @returns {Boolean}
	 */
	check: function(active) {
		if ($.screen.size() < 6) {
			return false;
		}

		return (active ? this.$private.syncable : true) &&
			LS.filters.get('sync') === '1';
	},

	/**
	 * Enable map syncing
	 */
	enable: function() {
		var priv = this.$private;

		if (priv.active) {
			return;
		}

		priv.active = true;

		priv.toggle(true);

		$$('syncSearch').text(priv.states.disable);
	},

	/**
	 * Disable map syncing
	 *
	 * @param {Boolean} [reset=true]
	 */
	disable: function(reset) {
		var priv = this.$private;

		priv.active = false;

		priv.reset(reset !== false);
	},

	/**
	 * Pause map syncing
	 */
	pause: function() {
		this.$private.paused = true;
	},

	/**
	 * Resume map syncing
	 */
	resume: function() {
		this.$private.paused = false;
	},

	/**
	 * Get the current sync bounds
	 *
	 * @returns {*}
	 */
	getBounds: function() {
		return this.$private.bounds;
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		this.pause();

		LS.util.reset('sync');
	}
}, {
	/**
	 * Bind sync screen mapping
	 */
	bindScreen: function() {
		var scope = this,
			pub = scope.$public;

		$.screen.map([
			{
				max: 3,
				init: false,
				callback: function() {
					if (! scope.active) {
						return;
					}

					scope.reset(true);

					pub.pause();
				}
			},
			{
				min: 5,
				callback: function() {
					scope.bindButton();

					if (! scope.active) {
						return;
					}

					pub.resume();
				}
			}
		], {
			namespace: 'sync'
		});
	},

	/**
	 * Bind button events
	 */
	bindButton: function() {
		var scope = this,
			$sync = $$('syncSearch'),
			states = scope.states;

		$sync.on('mousedown', function() {
			if (scope.active) {
				$sync.text(states.sync);

				scope.$public.disable();

				return;
			}

			LS.analytics.interact('properties', 'sync');

			if ($sync.text() === states.enable) {
				$sync.text(states.disable);

				scope.$public.enable();

				return;
			}

			$sync.text(states.enable);

			scope.bounds = scope.conf.map.getBounds();

			scope.conf.map.update();

			scope.refresh();
		}, {
			namespace: 'sync'
		});
	},

	/**
	 * Toggle sync button state
	 *
	 * @param {Boolean} [check=false]
	 */
	toggle: function(check) {
		var scope = this,
			fn = function() {
				if (! scope.active) {
					$$('syncSearch').text(scope.states.sync);
				}
			};

		if ((scope.conf.map.getZoom() >= scope.conf.minZoom) && ! Apt.panel.opened()) {
			scope.conf.map.resumePosition();

			fn();

			return;
		}

		check = check === true;

		if (check) {
			scope.conf.map.pausePosition();

			return;
		}

		fn();
	},

	/**
	 * Sync visible map listings
	 */
	sync: function() {
		var scope = this,
			zoom;

		zoom = scope.conf.map.getZoom();

		$$('syncSearch').toggle(zoom >= scope.conf.minZoom);

		if (scope.paused) {
			return;
		}

		if (zoom > scope.conf.minZoom && ! scope.syncable) {
			scope.toggle(scope.active);
		}

		if (zoom < scope.conf.minZoom) {
			var reset = false;

			if (scope.active) {
				reset = scope.syncable;
				scope.syncable = false;

				scope.toggle(true);
			}

			if (reset) {
				scope.reset(true);
			}

			return;
		}

		scope.syncable = true;

		scope.active ?
			scope.refresh(true) :
			scope.reset(false);
	},

	/**
	 * Updated listings based on bounds
	 *
	 * @param {Boolean} [sync]
	 */
	refresh: function(sync) {
		var scope = this,
			filters = LS.filters.get(),
			options = {};

		if (sync) {
			var bounds = scope.conf.map.getBounds();

			if ($.equals(scope.bounds, bounds)) {
				options.action = false;
			}

			scope.bounds = bounds;

			if (scope.conf.push) {
				filters.sync = 1;
			}
		} else {
			delete filters.sync;
		}

		if (scope.conf.push) {
			filters.bounds = scope.bounds;
		}

		filters.page = 1;

		scope.update(filters, options);
	},

	/**
	 * Update index data
	 *
	 * @param {Object} filters
	 * @param {Object} [options]
	 */
	update: function(filters, options) {
		var conf = this.conf;

		if (! conf.index || Apt.panel.opened()) {
			return;
		}

		conf.map.removeDraw();

		var position = conf.map.getPosition();

		filters.center = position.center;
		filters.zoom = position.zoom;

		delete filters.geometry;
		delete filters.location;

		$.history.replace(
			LS.filters.build(LS.filters.root(), filters)
		);

		conf.index.update($.extend({
			push: false,
			scrollTop: 0
		}, options), filters);
	},

	/**
	 * Reset syncing to default state
	 *
	 * @param {Boolean} [refresh=false]
	 */
	reset: function(refresh) {
		var scope = this;

		scope.bounds = false;

		scope.toggle();

		if (refresh) {
			var filters = LS.filters.get();

			delete filters.bounds;
			delete filters.sync;

			scope.update(filters);
		}
	}
});