$.view.addHelper({
	/**
	 * Stringify the current JSON output
	 *
	 * @returns {String}
	 */
	dump: function() {
		return JSON.stringify(this.val);
	},

	/**
	 * Format a range of filter values
	 *
	 * @param {String} prefix
	 * @param {String} suffix
	 * @param {String} fallback
	 * @returns {String}
	 */
	range: function(prefix, suffix, fallback) {
		return LS.util.formatRange(
			this.val, prefix, suffix, fallback
		);
	},

	/**
	 * Output value if two values match
	 *
	 * @param {*} a
	 * @param {*} b
	 * @param {*} value
	 * @returns {*}
	 */
	eq: function(a, b, value) {
		if (a === b) {
			return value;
		}
	},

	/**
	 * Display checkmark or fallback based on truthiness
	 *
	 * @param {String} [fallback='—']
	 * @returns {String}
	 */
	check: function(fallback) {
		return this.val ?
			'<span class="check">Yes</span>' :
			(fallback || '—');
	},

	/**
	 * Display unique ID
	 *
	 * @returns {String}
	 */
	uid: function() {
		return LS.util.uid();
	},

	/**
	 * Display fallback template with custom message
	 *
	 * @param {String} [message]
	 * @param {Object} [reset]
	 * @returns {String}
	 */
	fallback: function(message, reset) {
		return $.view.render('_empty', {
			message: message,
			reset: reset
		});
	},

	/**
	 * Get format action
	 *
	 * @param {String} key
	 * @returns {String}
	 */
	action: function(key) {
		return LS.formats[this.val + 's'][key];
	}
});