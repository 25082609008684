(function(L, U) {
	'use strict';

	var $ = L._win[L._$];

	L.$chain({
		/**
		 * Append additional selection to Apt object
		 *
		 * @param {($|HTMLElement|String)} source
		 * @returns {$}
		 */
		add: function(source) {
			var orig = [],
				i = 0;

			for (; i < this.length; i++) {
				orig.push(this[i]);
			}

			return $(L.$unique(orig.concat(L.$(source))));
		},

		/**
		 * Add classes to each matching selection
		 *
		 * @param {(Array|String)} value
		 * @returns {$}
		 */
		addClass: function(value) {
			L.$addClass(this, value);

			return this;
		},

		/**
		 * Insert selection or markup after each matching selection
		 *
		 * @param {($|HTMLElement|String)} source
		 * @param {Boolean} [remove=false]
		 * @returns {$}
		 */
		after: function(source, remove) {
			L.$after(this, source, remove);

			return this;
		},

		/**
		 * Append selection or markup after each matching selection
		 *
		 * @param {($|HTMLElement|String)} source
		 * @returns {$}
		 */
		append: function(source) {
			L.$append(this, source);

			return this;
		},

		/**
		 * Append selection or markup to each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @returns {$}
		 */
		appendTo: function(target) {
			L.$append(target, this);

			return this;
		},

		/**
		 * Get attribute of first matching selection or set attribute
		 * of each matching selection
		 *
		 * @param a
		 * @param b
		 * @returns {($|String)}
		 */
		attr: function(a, b) {
			var r = L.$attr(this, a, b);

			return b !== U || L.$isObject(a) ?
				this : r;
		},

		/**
		 * Insert selection or markup before each matching selection
		 *
		 * @param {($|HTMLElement|String)} source
		 * @param {Boolean} [remove=false]
		 * @returns {$}
		 */
		before: function(source, remove) {
			L.$before(this, source, remove);

			return this;
		},

		/**
		 * Get unique direct children of each matching selection
		 *
		 * @param {String} [filter]
		 * @returns {$}
		 */
		children: function(filter) {
			return $(L.$children(this, filter));
		},

		/**
		 * Clone each matching selection
		 *
		 * @returns {$}
		 */
		clone: function() {
			return $(L.$clone(this));
		},

		/**
		 * Get unique closest ancestors of each matching selection
		 *
		 * @param {String} filter
		 * @param {Object} [context]
		 * @returns {$}
		 */
		closest: function(filter, context) {
			return $(L.$closest(this, filter, context));
		},

		/**
		 * Determine if any matching parent selection contains descendant selection
		 *
		 * @param descendant
		 * @returns {Boolean}
		 */
		contains: function(descendant) {
			return L.$contains(this, descendant);
		},

		/**
		 * Get CSS value of first matching selection or set value
		 * of each matching selection
		 *
		 * @param {(Object|String)} a
		 * @param {String} [b]
		 * @returns {($|String)}
		 */
		css: function(a, b) {
			var r = L.$css(this, a, b);

			return b !== U || L.$isObject(a) ?
				this : r;
		},

		/**
		 * Get data of first matching selection or set data
		 * of each matching selection
		 *
		 * @param a
		 * @param [b]
		 * @returns {($|String)}
		 */
		data: function(a, b) {
			var r = L.$data(this, a, b);

			return b !== U || L.$isObject(a) ?
				this : r;
		},

		/**
		 * Remove child nodes from each matching selection
		 *
		 * @returns {$}
		 */
		empty: function() {
			L.$empty(this);

			return this;
		},

		/**
		 * Get indexed node of matching selection
		 *
		 * @param {Number} index
		 * @param {($|HTMLElement|String)} [context=document]
		 * @returns {$}
		 */
		eq: function(index, context) {
			return $(L.$eq(this, index, context));
		},

		/**
		 * Return a filtered subset of elements from a matching selection
		 *
		 * @param {String} filter
		 * @param {Object} [options]
		 * @returns {$}
		 */
		filter: function(filter, options) {
			return $(L.$filter(this, filter, options));
		},

		/**
		 * Get unique filtered descendants from each matching selection
		 *
		 * @param {String} filter
		 * @returns {$}
		 */
		find: function(filter) {
			return $(L.$find(this, filter));
		},

		/**
		 * Get the first element of a matching selection
		 *
		 * @returns {$}
		 */
		first: function() {
			return this.eq(0);
		},

		/**
		 * Place focus on input element
		 *
		 * @param {Boolean} [end=false]
		 * @returns {$}
		 */
		focus: function(end) {
			L.$focus(this, end);

			return this;
		},

		/**
		 * Remove focus from an element
		 *
		 * @returns {$}
		 */
		blur: function() {
			L.$blur(this);

			return this;
		},

		/**
		 * Return node from Apt object at specific index
		 *
		 * @param {Number} index
		 * @returns {HTMLElement}
		 */
		get: function(index) {
			return L.$eq(this, index);
		},

		/**
		 * Determine if the matching selection has a class
		 *
		 * @param {String} className
		 * @returns {Boolean}
		 */
		hasClass: function(className) {
			return L.$hasClass(this, className);
		},

		/**
		 * Get or set the height of each matching selection
		 *
		 * @param {(Number|String)} value
		 * @returns {($|Number)}
		 */
		height: function(value) {
			var r = L.$height(this, value);

			return value === U || value === true ?
				r : this;
		},

		/**
		 * Hide each matching selection
		 *
		 * @returns {$}
		 */
		hide: function() {
			L.$hide(this);

			return this;
		},

		/**
		 * Get inner HTML of first selection or set each matching selection's HTML
		 *
		 * @param {String} value
		 * @returns {($|String)}
		 */
		html: function(value) {
			var r = L.$html(this, value);

			return value !== U ?
				this : r;
		},

		/**
		 * Get the zero-based index of a matching selection relative
		 * to it's siblings
		 *
		 * @returns {Number}
		 */
		index: function() {
			return L.$index(this);
		},

		/**
		 * Insert each matching source selection element after
		 * each matching target selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @returns {$}
		 */
		insertAfter: function(target) {
			L.$insertAfter(this, target);

			return this;
		},

		/**
		 * Insert each matching source selection element before
		 * each matching target selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @returns {$}
		 */
		insertBefore: function(target) {
			L.$insertBefore(this, target);

			return this;
		},

		/**
		 * Determine if at least one matching selection matches
		 * a specified criteria
		 *
		 * @param filter
		 * @returns {Boolean}
		 */
		is: function(filter) {
			return L.$is(this, filter);
		},

		/**
		 * Get the last element of a matching selection
		 *
		 * @param {($|HTMLElement|String)} [context=document]
		 * @returns {$}
		 */
		last: function(context) {
			return $(L.$last(this, context));
		},

		/**
		 * Get the unique next sibling of each matching selection
		 *
		 * @param filter
		 * @param {Object} [options]
		 * @returns {$}
		 */
		next: function(filter, options) {
			return $(L.$next(this, filter, options));
		},

		/**
		 * Get the offset position of a matching selection relative to the document
		 *
		 * @param {Number} value
		 * @returns {Number}
		 */
		offset: function(value) {
			return L.$offset(this, value);
		},

		/**
		 * Get unique parent from each matching selection
		 *
		 * @param {String} filter
		 * @returns {$}
		 */
		parent: function(filter) {
			return $(L.$parent(this, filter));
		},

		/**
		 * Prepend selection or markup before each matching selection
		 *
		 * @param {($|HTMLElement|String)} source
		 * @returns {$}
		 */
		prepend: function(source) {
			L.$prepend(this, source);

			return this;
		},

		/**
		 * Prepend selection or markup to each matching selection
		 *
		 * @param {($|HTMLElement|String)} target
		 * @returns {$}
		 */
		prependTo: function(target) {
			L.$prepend(target, this.reverse());

			return this;
		},

		/**
		 * Get the unique previous sibling of each matching selection
		 *
		 * @param {String} [filter]
		 * @param {Object} [options]
		 * @returns {$}
		 */
		prev: function(filter, options) {
			return $(L.$prev(this, filter, options));
		},

		/**
		 * Get property of first matching selection or set property of
		 * each matching selection
		 *
		 * @param a
		 * @param b
		 * @returns {($|String)}
		 */
		prop: function(a, b) {
			var r = L.$prop(this, a, b);

			return b !== U || L.$isObject(a) ?
				this : r;
		},

		/**
		 * Remove each matching selection from the document
		 *
		 * @returns {$}
		 * @param {($|HTMLElement|String)} [context=document]
		 */
		remove: function(context) {
			L.$remove(this, context);

			return this;
		},

		/**
		 * Remove specified attribute of each matching selection
		 *
		 * @param {String} name
		 * @returns {$}
		 */
		removeAttr: function(name) {
			L.$removeAttr(this, name);

			return this;
		},

		/**
		 * Remove classes from each matching selection
		 *
		 * @param {String} value
		 * @returns {$}
		 */
		removeClass: function(value) {
			L.$removeClass(this, value);

			return this;
		},

		/**
		 * Replace each matching selection with selection or markup
		 *
		 * @param {($|HTMLElement|String)} source
		 * @returns {$}
		 */
		replaceWith: function(source) {
			L.$replaceWith(this, source);

			return this;
		},

		/**
		 * Get or set the Y scroll position of each matching selection
		 *
		 * @param value
		 * @param {Boolean} [smooth=false]
		 * @returns {($|Number)}
		 */
		scrollTop: function(value, smooth) {
			var r = L.$scrollTop(this, value, smooth);

			return value === U || value === true ?
				r : this;
		},

		/**
		 * Serialize input values from first matching form selection
		 *
		 * @param {Boolean} json
		 * @param {Boolean} [nest=true]
		 * @returns {String}
		 */
		serialize: function(json, nest) {
			return L.$serializeForm(this, json, nest);
		},

		/**
		 * Show each matching selection
		 *
		 * @returns {$}
		 */
		show: function() {
			L.$show(this);

			return this;
		},

		/**
		 * Get unique siblings of each matching selection
		 *
		 * @param {String} [filter]
		 * @returns {$}
		 */
		siblings: function(filter) {
			return $(L.$siblings(this, filter));
		},

		/**
		 * Get inner text of first selection or set each matching selection's text
		 *
		 * @param {String} value
		 * @returns {($|String)}
		 */
		text: function(value) {
			var r = L.$text(this, value);

			return value !== U ?
				this : r;
		},

		/**
		 * Toggle the display of each matching selection
		 *
		 * @param {Boolean} [state]
		 * @returns {$}
		 */
		toggle: function(state) {
			L.$toggle(this, state);

			return this;
		},

		/**
		 * Toggle adding and removing class(es) from the specified element
		 *
		 * @param {String} value
		 * @param {Boolean} [state]
		 * @returns {$}
		 */
		toggleClass: function(value, state) {
			L.$toggleClass(this, value, state);

			return this;
		},

		/**
		 * Get value of first matching selection or set values of
		 * each matching selection
		 *
		 * @param {String} value
		 * @returns {($|String)}
		 */
		val: function(value) {
			var r = L.$val(this, value);

			return value !== U ?
				this : r;
		},

		/**
		 * Get or set the width of each matching selection
		 *
		 * @param {(Number|String)} value
		 * @returns {($|Number)}
		 */
		width: function(value) {
			var r = L.$width(this, value);

			return value === U || value === true ?
				r : this;
		}
	});
})(Apt, undefined);