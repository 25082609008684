$.view.addHelper({
	/**
	 * Format monetary value
	 *
	 * @returns {String}
	 */
	currency: function() {
		return LS.util.formatCurrency(this.val);
	},

	/**
	 * Format monetary value with abbreviated syntax
	 *
	 * @returns {String}
	 */
	shortCurrency: function() {
		var formatted = LS.util.shortFormat(this.val);

		return formatted ?
			'$' + formatted : this.val;
	},

	/**
	 * Format number with thousands separators
	 *
	 * @param {Number} [decimals=0]
	 * @param {Boolean} [trim=false]
	 * @param {String} [plural]
	 * @returns {String}
	 */
	numberFormat: function(decimals, trim, plural) {
		var formatted = LS.util.formatNumber(this.val, decimals, trim);

		if (plural) {
			formatted += ' ' + LS.util.plural(plural, this.val);
		}

		return formatted;
	},

	/**
	 * Round number to the nearest number
	 *
	 * @param {Number} [decimals=0]
	 * @returns {Number}
	 */
	round: function(decimals) {
		return LS.util.round(this.val, decimals);
	},

	/**
	 * Format number with abbreviated syntax
	 *
	 * @returns {String}
	 */
	shortFormat: function() {
		return LS.util.shortFormat(this.val);
	},

	/**
	 * Format property size for display
	 *
	 * @param {Boolean} [round=false]
	 * @param {Boolean} [abbreviate=false]
	 * @param {String} [suffix]
	 * @returns {String}
	 */
	sizeFormat: function(round, abbreviate, suffix) {
		return LS.util.formatSize(this.val, round, abbreviate, suffix);
	},

	/**
	 * Determine if a value is greater than a given number
	 *
	 * @param {Number} num
	 * @returns {Boolean}
	 */
	gt: function(num) {
		return parseFloat(this.val) > num;
	},

	/**
	 * Determine if a value is less than a given number
	 *
	 * @param {Number} num
	 * @returns {Boolean}
	 */
	lt: function(num) {
		return parseFloat(this.val) < num;
	}
});