Apt.fn.make('report', {
	/**
	 * Initialize module
	 *
	 * @param {Object} options
	 * @returns {Object}
	 */
	init: function(options) {
		var scope = this;

		scope.conf = $.extend({
			namespace: LS.util.uid()
		}, options);

		$.events.on('$report', 'click', function(e, el) {
			if (! options.id) {
				options.id = $(el).closest(options.parent)
					.data('id');
			}

			scope.show(options);
		}, {
			break: true,
			cancel: true,
			delegate: options.delegate,
			namespace: scope.conf.namespace
		});

		return scope;
	},

	/**
	 * Display report modal
	 *
	 * @param {Object} options
	 */
	show: function(options) {
		var scope = this;

		LS.modal.open({
			url: '/report/' + options.target,
			success: function() {
				scope.$private.bind(options.target, options.id);
			},
			onHide: function() {
				$.events.reset(scope.conf + 'form');
			}
		});
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		$.events.reset(this.conf.namespace);
	}
}, {
	/**
	 * Bind report submit event
	 *
	 * @param {String} target
	 * @param {String} id
	 */
	bind: function(target, id) {
		var namespace = this.$public.conf + 'form',
			email = LS.user.get('email');

		LS.api.bind('$reportForm', target + '/' + id + '/report', {
			method: 'put',
			validate: true,
			namespace: namespace,
			success: function() {
				LS.modal.close();
			}
		});

		if (email) {
			$$('reportEmail').val(email);
		}

		$$('reportDescription').resize(namespace);
	}
});