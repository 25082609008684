Apt.fn.make('tab', {
	/**
	 * Initialize module
	 *
	 * @param {$} target
	 * @param {Function} [fn]
	 * @param {Boolean} [once=false]
	 */
	init: function(target, fn, once) {
		var scope = this,
			sel = '.tab__item',
			active = $(sel, target).filter('.-is-active');

		scope.fn = fn;
		scope.uid = LS.util.uid();

		if (once) {
			scope.fired = [];
		}

		// Initialize default selection
		if (active.length) {
			scope.select(active[0], true);
		}

		$.events.on(sel, 'mousedown', function(e, el) {
			scope.select(el);
		}, {
			break: true,
			delegate: target,
			namespace: scope.uid
		});

		return scope;
	},

	/**
	 * Set selection
	 *
	 * @param {$} el
	 * @param {Boolean} [init=false]
	 */
	select: function(el, init) {
		var scope = this,
			controls = el.getAttribute('aria-controls'),
			id = el.dataset.id || controls,
			activeClass = '-is-active';

		$(el).addClass(activeClass)
			.siblings()
			.removeClass(activeClass);

		if (controls) {
			$('#' + controls).show()
				.siblings('[role="tabpanel"]')
				.hide();
		}

		if (id) {
			if (scope.fired) {
				if (scope.fired.indexOf(id) > -1) {
					return;
				}

				scope.fired.push(id);
			}

			if (scope.fn) {
				scope.fn(id, init || false);
			}
		}
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		$.events.reset(this.uid);
	}
});