/* global Sentry */

LS.user = {
	/**
	 * Initialize module
	 */
	init: function() {
		if (window.crawler) {
			return;
		}

		var scope = this;

		if (! scope.location()) {
			var loc = LS.location.get(true);

			if (loc && loc.slug) {
				loc.source = 'request';

				LS.store.set('user_location', loc);
			}
		}

		$.observe('user', function(data) {
			var account = data.account;

			scope.toggle(Boolean(account));

			if (! account || typeof Sentry === 'undefined') {
				return;
			}

			Sentry.configureScope(function(instance) {
				instance.setUser({
					id: account.id,
					email: account.email
				});
			});
		});

		this.set();
	},

	/**
	 * Retrieve the currently authenticated user
	 *
	 * @param {String} [key]
	 * @returns {Object}
	 */
	get: function(key) {
		return $.get('user.account' + (key ? '.' + key : ''));
	},

	/**
	 * Populate user storage
	 *
	 * @param {Object} [data]
	 */
	set: function(data) {
		var scope = this;

		if (data) {
			scope.setToken(data.token, data.ttl, data.refresh_ttl);

			data.account ?
				scope.setUser(data) :
				scope.fetchUser(data);
		} else if (! scope.active() && scope.token()) {
			scope.refreshToken();
		} else if (! scope.get()) {
			scope.fetchUser(data);
		}
	},

	/**
	 * Get user location
	 *
	 * @param {Boolean} [international=false]
	 * @returns {Object}
	 */
	location: function(international) {
		var loc = LS.store.get('user_location');

		return loc && (
			international ||
			loc.country === 'US'
		) ?
			loc : null;
	},

	/**
	 * Get user center
	 *
	 * @returns {Object}
	 */
	center: function() {
		var loc = this.location();

		if (
			! loc ||
			! loc.latitude
		) {
			return null;
		}

		return [
			loc.longitude,
			loc.latitude
		];
	},

	/**
	 * Determine of token is valid
	 *
	 * @returns {Boolean}
	 */
	active: function() {
		return LS.store.get('user_id', null) !== null;
	},

	/**
	 * Ensure authenticated state before action
	 *
	 * @param {Function} fn
	 */
	guard: function(fn) {
		if (this.active()) {
			$.exec(fn);

			return;
		}

		this.renew(fn);
	},

	/**
	 * Process token renewal
	 *
	 * @param {Function} fn
	 */
	renew: function(fn) {
		var scope = this;

		scope.token() ?
			scope.refreshToken(fn) :
			scope.resetToken(fn);
	},

	/**
	 * Get the auth token for the user session
	 *
	 * @param {Boolean} [parse=false]
	 * @returns {String}
	 */
	token: function(parse) {
		var token = LS.store.get('user_token');

		return parse ?
			this.parseToken(token) :
			token;
	},

	/**
	 * Drop the authenticated user state
	 */
	drop: function() {
		$.drop('user.account');

		LS.store.drop('user_token');
		LS.store.drop('user_id');

		this.toggle(false);
	},

	/**
	 * Fetch user data
	 */
	fetchUser: function() {
		var scope = this,
			loc = scope.location(),
			data = {};

		if (! loc) {
			data.locate = 'y';
		}

		LS.api.get('me', {
			auth: true,
			cache: false,
			data: data,
			flash: false,
			guard: false,
			namespace: 'auth',
			success: function(resp) {
				scope.setUser(resp);
			}
		});
	},

	/**
	 * Set user data
	 *
	 * @param {Object} data
	 */
	setUser: function(data) {
		if (data.location) {
			LS.store.set('user_location', data.location);
		} else {
			var loc = this.location();

			if (loc) {
				data.location = loc;
			}
		}

		if (! LS.store.get('user_id')) {
			delete data.account;
		}

		$.set('user', data);
	},

	/**
	 * Refresh the auth token for the user session
	 *
	 * @param {Function} [fn]
	 */
	refreshToken: function(fn) {
		var scope = this;

		LS.api.post('auth/refresh', {
			auth: true,
			namespace: 'auth',
			flash: false,
			success: function(data) {
				LS.user.set(data);

				$.exec(fn);
			},
			error: function() {
				scope.resetToken(fn);
			}
		});
	},

	/**
	 * Force login to refresh token
	 *
	 * @param {Function} [fn]
	 */
	resetToken: function(fn) {
		LS.user.drop();

		Apt.auth.prompt(fn);
	},

	/**
	 * Set authentication references
	 *
	 * @param {String} token
	 * @param {Number} ttl
	 * @param {Number} refresh
	 */
	setToken: function(token, ttl, refresh) {
		LS.store.set('user_token', token, refresh);
		LS.store.set('user_id', this.parseToken(token).sub, ttl);
	},

	/**
	 * Parse JSON web token
	 *
	 * @param {String} [token]
	 * @returns {Object}
	 */
	parseToken: function(token) {
		token = token || LS.user.token();

		if (! token) {
			return null;
		}

		return JSON.parse(
			window.atob(
				token.split('.')[1]
					.replace('-', '+')
					.replace('_', '/')
			)
		);
	},

	/**
	 * Toggle header account display
	 *
	 * @param {Boolean} [show=true]
	 */
	toggle: function(show) {
		var state = show !== false,
			$auth = $$('loggedIn');

		$$('loggedOut').toggle(! state);

		if (state) {
			$auth.html($.view.render('_account'));
		}

		$auth.toggle(state);
	}
};