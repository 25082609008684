Apt.fn.extend('index', {}, {
	/**
	 * Bind pagination link actions
	 */
	paginate: function() {
		var scope = this,
			pub = scope.$public,
			conf = pub.conf;

		$.events.on('[data-num]', 'click', function(e, el) {
			if (e.metaKey) {
				return;
			}

			var num = Number(el.dataset.num),
				filters = {
					page: num
				};

			if (conf.push) {
				pub.filter(filters, el.href);

				return;
			}

			$.extend(conf.filters, filters);

			scope.go({
				path: conf.resource
			}, scope.prep(conf.filters));
		}, {
			cancel: true,
			delegate: conf.$target,
			namespace: pub.uid
		});
	},

	/**
	 * Get title with page number
	 *
	 * @returns {String}
	 */
	getPageTitle: function() {
		var title = LS.filters.title(),
			num = this.$public.conf.filters.page;

		if (num > 1) {
			title += ' - Page ' + num;
		}

		return title + ' - LandSearch';
	}
});