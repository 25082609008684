(function(L) {
	'use strict';

	L.$chain({
		/**
		 * Determine if an element is currently visible
		 *
		 * @param {Number} [threshold=0] - ratio of element in view
		 * @param {Number} [margin=0] - vertical intersection bound offset in pixels
		 * @returns {Boolean}
		 */
		visible: function(threshold, margin) {
			return L.scroll.visible(this, threshold, margin);
		}
	});
})(Apt);