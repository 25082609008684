(function(L, U) {
	'use strict';

	var reg = {
			args: /(['"].*?['"]|[^'",\s]+)(?=\s*,|\s*$)/g,
			ext: /(.[^(]+)(?:\((.*)\))?/,
			pair: /{{#(\S+?)(?:\|(.+?))?}}([\s\S]+?){{\/\1}}/g,
			partial: /{{\s*>(.+?)}}/g,
			short: /{{([^}]+) +\? ([^}]+)}}/g,
			single: /{{(.+?)}}/g,
			str: /^\\?(["'])/,
			tags: /{{\s*(?:([#/])([^#{|\n\s]+)\s*(\|[^{\n]+)?|else)\s*}}/g
		},
		helpers = {
			is: function(a, b) {
				return a === (b !== U ? b : this.val);
			},
			not: function(a, b) {
				return ! helpers.is.call(this, a, b);
			},
			isEmpty: function(val) {
				return val !== U ?
					_isEmpty(val) : this.empty;
			},
			notEmpty: function(val) {
				return ! helpers.isEmpty.call(this, val);
			}
		},
		global = {},
		views = {},

		/**
		 * Determine if value matches empty criteria
		 *
		 * @private
		 * @param {*} val
		 * @returns {Boolean}
		 */
		_isEmpty = function(val) {
			return val === '' || val === false || val == null ||
				(typeof val === 'object' && ! Object.keys(val).length);
		},

		/**
		 * Make recursive partial replacements
		 *
		 * @private
		 * @param {String} temp
		 * @returns {String}
		 */
		_embed = function(temp) {
			temp = temp.replace(reg.partial, function(m, tag) {
				return views[tag.trim()] || '';
			});

			if (reg.partial.test(temp)) {
				temp = _embed(temp);
			}

			return temp;
		},

		/**
		 * Get specific object value
		 *
		 * @private
		 * @param {Object} data
		 * @param {Object} prev
		 * @param {String} key
		 * @param {String} fb
		 * @param {Object} init
		 * @returns {*}
		 */
		_get = function(data, prev, key, fb, init) {
			key = key.trim();

			// Alter context
			if (key.slice(0, 6) === '$root.') {
				key = key.slice(6);
				data = init;
			} else if (key.slice(0, 3) === '../') {
				key = key.slice(3);
				data = prev;
			}

			var segs = key === '.' ?
					['.'] : key.split('.'),
				orig = data,
				len = segs.length - 1,
				i = 0;

			// Loop through object segments
			for (; i <= len; i++) {
				key = segs[i];

				if (data && data.hasOwnProperty(key)) {
					data = data[key];

					// Return value on last segment
					if (i === len) {
						if (! _isEmpty(data)) {
							return data;
						}

						break;
					}
				} else {
					break;
				}
			}

			if (key === '.') {
				return data;
			}

			// Process fallback value
			if (fb && fb !== '') {
				return _string(fb) || _get(orig, prev, fb, '', init);
			}

			return fb || (
				L.$isObject(data) ||
				Array.isArray(data) ?
					data[key] : data
			);
		},

		/**
		 * Process value based on quote enclosure
		 *
		 * @private
		 * @param {String} val
		 * @param {Boolean} [wrap=false]
		 * @returns {String}
		 */
		_string = function(val, wrap) {
			val = val.trim();

			var match = val.match(reg.str);

			if (match) {
				return val.replace(reg.str, '')
					.replace(new RegExp(match[0] + '$'), '');
			}

			if (wrap) {
				return '{{' + val + '}}';
			}
		},

		/**
		 * Parse helper arguments
		 *
		 * @private
		 * @param {String} str
		 * @param {Object} data
		 * @param {Object} prev
		 * @param {Object} init
		 * @returns {*}
		 */
		_parseArgs = function(str, data, prev, init) {
			var args = str !== U ? str.match(reg.args) || [] : [];

			return args.map(function(arg) {
				arg = arg.trim();

				var match = arg.match(reg.str);

				if (match) {
					return arg.replace(reg.str, '')
						.replace(new RegExp(match[0] + '$'), '');
				}

				arg = L._castString(arg);

				return typeof arg === 'string' ?
					_get(data, prev, arg, '', init) :
					arg;
			});
		},

		/**
		 * Parse template string
		 *
		 * @private
		 * @param {String} temp
		 * @param {Object} data
		 * @param {Object} prev
		 * @param {Object} init
		 * @param {Number} index
		 * @returns {String}
		 */
		_parse = function(temp, data, prev, init, index) {
			return _parseSingle(
				_parsePair(temp, data, prev, init, index), data, prev, init, index
			);
		},

		/**
		 * Replace single template tags
		 *
		 * @private
		 * @param {String} temp
		 * @param {Object} data
		 * @param {Object} prev
		 * @param {Object} init
		 * @param {Number} index
		 * @returns {String}
		 */
		_parseSingle = function(temp, data, prev, init, index) {
			return temp.replace(reg.single, function(m, set) {
				var split = set.split('||'),
					fb = split[1],
					segs = split[0].split('|'),
					tag = segs[0].trim(),
					val = _get(data, prev, tag, fb, init);

				// Process helpers
				segs.forEach(function(el, i) {
					var arr = el.match(reg.ext);

					segs[i] = el.trim();

					if (arr) {
						var helper = helpers[arr[1].trim()];

						if (helper && (segs.length > 1 || ! data.hasOwnProperty(tag))) {
							val = helper.apply({
								val: val,
								data: data,
								root: init,
								tag: tag,
								index: index,
								fallback: fb
							}, _parseArgs(arr[2], data, prev, init));
						}
					}
				});

				// Encode output by default
				if (val === U || typeof val === 'object') {
					val = '';
				} else if (typeof val === 'string') {
					// Recursively process injected tags
					if (val.indexOf('{{') > -1) {
						val = _render(val, data);
					}

					// Encode HTML characters
					if (segs.indexOf('raw') < 0) {
						val = val.replace(/&amp;/g, '&')
							.replace(/&/g, '&amp;')
							.replace(/</g, '&lt;')
							.replace(/>/g, '&gt;')
							.replace(/"/g, '&quot;');
					}
				}

				return val;
			});
		},

		/**
		 * Replace template tag pairs
		 *
		 * @private
		 * @param {String} temp
		 * @param {Object} data
		 * @param {Object} prev
		 * @param {Object} init
		 * @param {Number} index
		 * @returns {String}
		 */
		_parsePair = function(temp, data, prev, init, index) {
			return temp.replace(reg.pair, function(m, t, helper, inner) {
				var tag = t.replace(/%\d+/, ''),
					cond = inner.split('{{:' + t + '}}');

				inner = cond[0];

				var val = _get(data, prev, tag, U, init),
					empty = _isEmpty(val),
					help = [],
					each;

				// Parse helpers
				if (helper || empty) {
					var meth = helper ? helper.split('|') : [],
						sec = cond.length > 1 ? cond[1] : '',
						agg = [];

					// Check for root helpers
					if (empty) {
						var arg = meth[0] && meth[0][0] === '(';

						meth.unshift(tag + (arg ? meth[0] : '()'));

						if (arg) {
							meth.splice(1, 1);
						}
					}

					// Capture available aggregates and helpers
					meth.forEach(function(el) {
						var arr = el.match(reg.ext),
							name = arr[1].trim();

						// Check for each helper
						if (name === 'each') {
							each = true;
						} else if (helpers[name]) {
							el = [helpers[name], arr[2]];

							each ?
								help.push(el) :
								agg.push(el);
						}
					});

					// Process aggregates
					if (agg.length) {
						if (! agg.every(function(f) {
							var rv = f[0].apply({
								val: val,
								data: data,
								root: init,
								tag: tag,
								empty: empty,
								index: index
							}, _parseArgs(f[1], val, prev, init));

							if (rv === false) {
								return rv;
							}

							if (rv !== true) {
								val = rv;
								empty = _isEmpty(val);
							}

							return true;
						})) {
							return _parse(sec, data, prev, init, index);
						}
					} else if (empty) {
						return _parse(sec, data, prev, init, index);
					}
				}

				// Process parent context on reserved tag pairs, excluding $root
				if (tag[0] === '$' && tag[1] !== 'r') {
					val = data;
				}

				var isObject = typeof val === 'object';

				if (! each) {
					if (! isObject) {
						val = L.$extend({}, data, {
							'.': val,
							'#': 0,
							'##': 1
						});
					}

					return _parse(inner, val, data, init, index);
				}

				if (! empty) {
					// Loop through objects and arrays
					if (isObject) {
						var isPlainObject = L.$isObject(val),
							keys = Object.keys(val),
							total = keys.length,
							resp = '',
							i = 0;

						for (; i < total; i++) {
							var key = keys[i],
								el = val[key];

							empty = _isEmpty(el);

							var cont = help.every(function(f) {
								var rv = f[0].apply({
									val: el,
									data: data,
									root: init,
									tag: tag,
									empty: empty,
									index: i
								}, _parseArgs(f[1], el, prev, init));

								if (rv === false) {
									return rv;
								}

								if (rv !== true) {
									el = rv;
								}

								return true;
							});

							// Merge default properties
							var item = L.$extend({}, L.$isObject(el) ?
									el :
									(isPlainObject ? val : {}),
								{
									$first: ! i,
									$index0: i,
									$index: i + 1,
									$key: key,
									$last: i + 1 === total,
									'.': el
								}),
								hl = helper.length;

							cont = (hl && cont) || (! hl && ! empty);

							if (cont || sec) {
								resp += _parse(cont ? inner : sec, item, data, init, i);
							}
						}

						return resp;
					}

					return inner;
				}

				return '';
			});
		},

		/**
		 * Render template string
		 *
		 * @private
		 * @param {String} temp
		 * @param {Object} data
		 * @returns {String}
		 */
		_render = function(temp, data) {
			var depth = [],
				tags = {};

			// Make partial replacements
			temp = _embed(temp);

			// Match tag pairs
			temp = temp.replace(reg.short, function(m, cond, val) {
				cond = cond.trim() + '}}';

				var segs = val.split(' : '),
					resp = '{{#' + cond + _string(segs[0], true);

				if (segs.length > 1) {
					resp += '{{else}}' + _string(segs[1], true);
				}

				return resp + '{{/' + cond;
			}).replace(reg.tags, function(m, pre, tag, helper) {
				var resp = '{{';

				if (pre) {
					var segs = tag.split('('),
						root = segs[0],
						exists = tags.hasOwnProperty(tag);

					resp += pre + root + '%';

					if (pre === '#') {
						depth.push(root);

						if (! exists) {
							tags[root] = {
								i: 0,
								o: []
							};
						}

						tags[root].i++;
						tags[root].o.push(tags[root].i);

						if (segs.length > 1) {
							helper = '(' + segs.slice(1).join('(');
						}

						resp += tags[root].i + (helper || '');
					} else if (exists) {
						resp += tags[root].o.pop();

						tags[root].i--;

						depth.pop();
					}
				} else if (depth.length) {
					tag = depth.slice(-1)[0];

					resp += ':' + tag + '%' + tags[tag].i;
				}

				return resp + '}}';
			});

			// Parse template tags
			return _parse(temp, data, {}, data, 0);
		};

	L.app = {
		/**
		 * Application instance storage
		 */
		fn: {},

		/**
		 * Create an application
		 *
		 * @param {String} name
		 * @param {Object} options
		 * @param {Object} options.model
		 * @param {($|HTMLElement|String)} [options.target]
		 * @param {($|HTMLElement|String)} options.view
		 * @returns {Object}
		 */
		make: function(name, options) {
			var conf = L.$extend(true, {
					init: true,
					events: {},
					model: {}
				}, options),
				app = L.app,
				current,
				past,
				parsed,
				views,
				fn;

			// Create a new application controller
			app[name] = new (L._make(name, {}, {}, false, conf.model))();

			views = L.$(conf.target || conf.view).map(function(el) {
				L.$exec(conf.events.init, {
					args: [el, app[name]]
				});

				return [el, conf.target ? conf.view : el.outerHTML];
			});

			fn = function(data) {
				current = JSON.stringify(data);

				if (current === past) {
					return;
				}

				past = current;

				views.forEach(function(view) {
					parsed = L.$parseHTML(
						L.view.render(view[1], data)
					);

					L.view.diff(view[0], parsed, conf.target);

					L.$exec(conf.events.render, {
						args: [view[0], app[name]]
					});
				});
			};

			parsed = null;

			if (conf.init) {
				fn(conf.model);
			}

			L.$extend(app[name], {
				/**
				 * Destroy current application
				 */
				$destroy: function() {
					if (conf._destruct) {
						conf._destruct();
					}

					if (views) {
						views.forEach(function(view) {
							view[0].remove();
						});
					}

					app[name] = views = null;

					delete app[name];
				},

				/**
				 * Pause view updating
				 */
				$pause: function() {
					app[name].$unobserve('*');
				},

				/**
				 * Resume view updating
				 *
				 * @param {Boolean} [update=false]
				 */
				$resume: function(update) {
					app[name].$observe('*', fn);

					if (update) {
						fn(app[name].$get());
					}
				}
			});

			// Initialize app observation
			app[name].$resume();

			return app[name];
		}
	};

	L.view = {
		/**
		 * Parse data into template string
		 *
		 * @param {String} template
		 * @param {Object} [data]
		 * @param {Object} [options]
		 * @returns {String}
		 */
		render: function(template, data, options) {
			options = options || {};

			template = _render(views[template] || template, L.$extend({}, global, data));

			if (options.spaceless !== false) {
				template = template.replace(/>\s+</g, '><');
			}

			return template;
		},

		/**
		 * Add global template variables
		 *
		 * @param {(Object|String)} a - key or variable object
		 * @param {*} [b] - variable value
		 */
		addGlobal: function(a, b) {
			L._extend(global, a, b);
		},

		/**
		 * Add conditional template handler or tag data modifier
		 *
		 * @param {Object|String} name
		 * @param {Function} [fn]
		 */
		addHelper: function(name, fn) {
			L._extend(helpers, name, fn);
		},

		/**
		 * Add views to store for on-demand reference
		 *
		 * @param {Object|String} name
		 * @param {String} [value]
		 */
		addView: function(name, value) {
			L._extend(views, name, value);
		}
	};
})(Apt, undefined);