(function(L) {
	'use strict';

	var bound = [],
		custom = {},
		id = 0,

		/**
		 * Attach specific event logic to element
		 *
		 * @private
		 * @param {(Array|HTMLElement|Object|String)} els
		 * @param {Object} obj
		 * @param {Object} options
		 */
		_bind = function(els, obj, options) {
			// Redefine variables when delegating
			if (options && options.delegate) {
				options.targ = els;
				els = options.delegate;
			}

			// For each element attach events
			L.$each(els, function(el) {
				// Loop through object events
				for (var key in obj) {
					var evts = key.split(' '),
						i = 0;

					for (; i < evts.length; i++) {
						var conf = L.$extend({
								args: [],
								cancel: false,
								once: false,
								scope: el
							}, options),
							fn = obj[key],
							evt = evts[i],
							ev = evt,
							parts = ev.split('.'),
							f = fn;

						evt = parts[0];

						if (parts.length === 1 && conf.namespace) {
							ev += '.' + conf.namespace;
						}

						// Prepend element to callback arguments if necessary
						if (conf.args[1] !== el) {
							conf.args.unshift(0, el);
						}

						(function(el, evt, fn, f, conf) {
							var cb = function(e) {
								var proceed = true,
									targ = conf.targ;

								conf.args[0] = e;

								// Make sure the target matches the selector
								if (targ) {
									var sel = targ._$ ?
										targ.sel : targ;

									// Set delegate reference
									e.delegateTarget = el;

									proceed = L.$toArray(L.$(sel)).some(function(el) {
										return el.contains(e.target) && (targ = el);
									});

									// Ensure element argument is the target
									conf.args[1] = conf.scope = targ;
								}

								if (proceed) {
									if (e) {
										if (conf.cancel) {
											e.preventDefault();
										}

										if (conf.break) {
											e.stopPropagation();
										}
									}

									L.$exec(fn, conf);

									// Unbind after first execution
									if (conf.once) {
										_off(el, evt + (conf.namespace ? '.' + conf.namespace : ''), f);
									}
								}

								if (conf.targ) {
									conf.scope = null;
									conf.args = [];
								}
							};

							if (conf.debounce) {
								cb = L.events.debounce(
									cb, conf.debounce === true ?
										null : conf.debounce
								);
							}

							// Ensure the specified element, event, and function
							// combination hasn't already been bound
							if (evt !== 'init' && (
								conf.duplicate === false ||
								! L.events.bound(el, ev, f, conf.targ).length
							)) {
								// Determine if the event is native or custom
								if ('on' + evt in el || 'on' + evt in L._win || evt === 'transitionend') {
									el.addEventListener(evt, cb, conf.capture || false);
								} else if (custom[evt]) {
									custom[evt][0](el, cb, conf);
								}

								id++;

								bound.push({
									el: el,
									ev: ev,
									evt: evt,
									cb: cb,
									fn: f,
									conf: conf,
									i: id
								});
							}

							if (evt === 'init' || conf.init === true) {
								cb({
									type: 'init'
								});
							}
						})(el, evt, fn, f, conf);
					}
				}
			}, options);
		},

		/**
		 * Detach event(s) from element
		 *
		 * @private
		 * @param {(HTMLElement|String)} [sel]
		 * @param {String} [event]
		 * @param {Function} [fn]
		 */
		_off = function(sel, event, fn) {
			L.events.bound(sel, event, fn).forEach(function(e) {
				if (
					'on' + e.evt in L._doc ||
					e.evt === 'beforeunload' ||
					e.evt === 'transitionend'
				) {
					e.el.removeEventListener(e.evt, e.cb, false);
				} else if (custom[e.evt]) {
					custom[e.evt][1](e.el, e.cb);
				}

				bound = bound.filter(function(obj) {
					return obj.i !== e.i;
				});
			});
		};

	L.events = {
		/**
		 * Bind event function to element
		 *
		 * @param {(HTMLElement|Object|String)} target
		 * @param {(Object|String)} a - event name or object of events
		 * @param {(Function|Object)} [b] - event callback or options object
		 * @param {(Object|String)} [c] - event options
		 * @param {Array} [c.args] - callback arguments
		 * @param {Boolean} [c.break=false] - prevent event propagation
		 * @param {Boolean} [c.cancel=false] - prevent default event behavior
		 * @param {(HTMLElement|String)} [c.context=document]
		 * @param {(HTMLElement|String)} [c.delegate]
		 * @param {Boolean} [c.once=false] - remove event after first execution
		 * @param {Object} [c.scope]
		 */
		on: function(target, a, b, c) {
			var evts = [];

			if (L.$isObject(target) && ! target._$) {
				var keys = Object.keys(target),
					i = 0;

				for (; i < keys.length; i++) {
					var key = keys[i];

					evts = target[key];

					_bind(key, evts, a);
				}
			} else {
				if (typeof a === 'string') {
					evts[a] = b;
				} else {
					evts = a;
					c = b;
				}

				_bind(target, evts, c);
			}
		},

		/**
		 * Remove specified event from specified element
		 *
		 * @param {(Boolean|HTMLElement|String)} [target]
		 * @param {(Object|String)} a - event name or object of events
		 * @param {Function} [b] - specific function to remove
		 */
		off: function(target, a, b) {
			var obj = a;

			if (a) {
				if (typeof a === 'string') {
					obj = [];
					obj[a] = b;
				}

				for (var key in obj) {
					var evts = key.split(' '),
						i = 0;

					for (; i < evts.length; i++) {
						var evt = evts[i],
							fn = obj[evt];

						_off(target, evt, fn);
					}
				}
			} else {
				_off(target);
			}
		},

		/**
		 * Remove namespaced events
		 *
		 * @param {String} [namespace] - remove events in this namespace
		 */
		reset: function(namespace) {
			_off(null, '.' + namespace);
		},

		/**
		 * Get currently bound events to optional specified element and event|function
		 *
		 * @param {(Boolean|HTMLElement|String)} [target]
		 * @param {String} [event] - event name to match
		 * @param {Function} [fn] - specific function to match
		 * @param {$} [delegateTarg] - targets of delegated event
		 * @returns {Array} matches
		 */
		bound: function(target, event, fn, delegateTarg) {
			var segs = (event || '').split('.'),
				matches = [],
				parts,
				one,
				two;

			target = target || [0];

			L.$each(target, function(el) {
				bound.forEach(function(binding) {
					if (el && el !== binding.el) {
						return;
					}

					parts = binding.ev.split('.');

					if (event && (
						segs[0] !== '' &&
						segs[0] !== parts[0]
					) || (
						segs[1] &&
						segs[1] !== parts[1]
					)) {
						return;
					}

					if (fn && String(fn) !== String(binding.fn)) {
						return;
					}

					// If delegated event, check against target element
					one = delegateTarg;
					two = binding.conf.targ;

					if (one && two) {
						if (typeof one === 'object') {
							one = one.sel;
						}

						if (typeof two === 'object') {
							two = two.sel;
						}

						if (one !== two) {
							return;
						}
					}

					matches.push(binding);
				});
			});

			return target ?
				matches : bound;
		},

		/**
		 * Execute bound event for each matching selection
		 *
		 * @param {(HTMLElement|String)} target
		 * @param {String} name
		 * @param {*} [args]
		 */
		trigger: function(target, name, args) {
			var fn = function() {
				//
			};

			this.bound(target, name).forEach(function(e) {
				if (args) {
					e.conf.args = e.conf.args.slice(0, 2)
						.concat(L.$toArray(args));
				}

				e.cb({
					target: e.el,
					preventDefault: fn,
					stopPropagation: fn
				});
			});
		},

		/**
		 * Debounce function execution
		 *
		 * @param {Function} fn
		 * @param {Number} [delay=500]
		 * @param {Boolean} [trigger=false] - execute function immediately
		 * @returns {Function}
		 */
		debounce: function(fn, delay, trigger) {
			var timeout;

			return function(e) {
				clearTimeout(timeout);

				if (trigger) {
					trigger = null;

					fn(e);
				}

				timeout = setTimeout(fn, delay || 500, e);
			};
		},

		/**
		 * Add a custom event
		 *
		 * @param {String} name
		 * @param {Function} on
		 * @param {Function} off
		 */
		addEvent: function(name, on, off) {
			custom[name] = [on, off];
		}
	};
})(Apt);